
import Desktop from "./Desktop"
import Mobile from "./Mobile"
import Landscape from "./Landscape"
import GetScreenType from '../../Helpers/ScreenHelper'
import { observer } from "mobx-react-lite";
import { useStores } from "../../Helpers/MobX/hooks";

const PerformanceReviewHistory = observer(() => {

    const isDesktopOrLaptop = GetScreenType().isDesktop;
    const isPortrait = GetScreenType().isPortrait;
    const { tittleStore } = useStores();
    tittleStore.setTittle("Performance review history");

    return(
        isDesktopOrLaptop?(<Desktop/>):(isPortrait?(<Mobile/>):(<Landscape/>))
)
});

export default PerformanceReviewHistory;
