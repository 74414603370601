import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useState } from "react";
import { TextField } from "@mui/material";
import { styleStore } from "../Helpers/MobX/StyleStore";
import { createUserStore } from "../Helpers/MobX/PageStores.tsx/CreateUserStore";
import { observer } from "mobx-react-lite";

interface IDatePicker {
  css: any;
  name: string;
  disabled?: boolean;
  defaultValue?: any;
  onChange?: any;
  value?: any;
}

const DatePickerStyled = observer(({
  css,
  name,
  disabled = false,
  defaultValue,
  onChange,
  value,
}: IDatePicker) => {
  const [selectedDate, setSelectedDate] = useState(defaultValue);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        inputFormat="MM/DD/YYYY"
        value={value ? value : selectedDate}
        disabled={disabled}
        onChange={onChange}
        renderInput={(props) => (
          <TextField
            name={name}
            {...props}
            sx={{
              ...css,
              WebkitTextFillColor: styleStore.getTextColorPrimary(),
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: styleStore.getTextColorPrimary(),
              },
              svg: { color: styleStore.getDatePicker() },
              input: {
                color: styleStore.getDatePickerInput(),
              },
              "& label.Mui-focused": {
                color: "blue",
              },
              "& input": {
                textTransform: "uppercase",
                opacity: 1,
              },
              "& .MuiOutlinedInput-root": {
                bgcolor: styleStore.getInputBackgroundPrimary(),
                height: "40px",
                borderRadius: "15px",
                "& fieldset": {
                  border: styleStore.getInputBorder(),
                  borderRadius: "15px",
                },
                "&:hover fieldset": {
                  border: styleStore.getInputBorder(),
                  borderRadius: "15px",
                },
                "&.Mui-focused fieldset": {
                  border: styleStore.getInputBorder(),
                  borderRadius: "15px",
                },
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
});

export default DatePickerStyled;
