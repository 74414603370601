import Desktop from "./Desktop";
import GetScreenType from "../../../Helpers/ScreenHelper";

type MyComponentProps = React.PropsWithChildren<{}>;
const Sidebar = ({ children }: MyComponentProps) => {
  const isDesktop = GetScreenType().isDesktop;
  const isLaptop = GetScreenType().isLaptop;
  const isDesktopOrLaptop = isDesktop || isLaptop;
  //let open = sidebarStore.open;
  return (
    <>
      <Desktop />
      {children}
    </>
  );
};

export default Sidebar;
