import { Box, FormControl, FormControlLabel, IconButton, Radio, RadioGroup, Tooltip, Typography} from "@mui/material";
import P from "../../../Assets/Fonts/P";
import BoxStyled from "../../../Components/BoxStyled";
import { useState } from "react";
import Link from "../../../Assets/Fonts/Link";
import { useStores } from "../../../Helpers/MobX/hooks";
import { observer } from "mobx-react-lite";
import AutocompleteStyled from "../../../Components/AutoComplete/AutocompleteStyled";
import PrimaryButton from "../../../Components/Button/PrimaryButton";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import InputFieldOutlined from "../../../Components/Textbox/InputFieldOutlined";
import SecondaryButton from "../../../Components/Button/SecondaryButton";
import LinkButton from "../../../Components/Button/LinkButton";
import TerciaryButton from "../../../Components/Button/TerciaryButton";
import LineTextbox from "../../../Components/Textbox/LineTextbox";
import { ReactComponent as DeleteDocIcon } from '../../../Assets/img/Icons/DeleteBlue.svg';
import { ReactComponent as DragIcon } from '../../../Assets/img/Icons/9dot.svg';
import Subsection from "./Subsection";
import SubsectionHolder from "./SubsectionHolder";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";


const useStyles = makeStyles(theme =>
  createStyles({
    smallRadioButton: {
      "& svg": {
        width: "10px",
        height: "10px"
      }
    }
  })
);
interface ISection{
  provided:any;
  removeSection:any;
}
const Section = observer(({provided, removeSection} : ISection) => {

    const { userStore } = useStores();
    const { styleStore } = useStores();
    const [subsections, setSubsections] = useState<Array<number>>([]);
    const [questions, setQuestions] = useState<Array<number>>([1]);
    const[additionalInfo, setAdditionalInfo] = useState<string>("");
    const classes = useStyles();
    const grid = 8;
    

    return(
        <BoxStyled css={{ width: "1608px", marginLeft:'14px', paddingLeft:'24px', paddingTop:'10px', marginTop:'24px', marginRight:'8px', backgroundColor:styleStore.getCardBackgroundColor() }}>
            
            <Box sx={{  display:'flex', flexDirection:'column', justifyContent:"left"}}>
            
              <Box sx={{display:'flex', flexDirection:'row', }}>
            <Typography id="modal-modal-title" textAlign='start' sx={{color:styleStore.getNameTitleColor(), fontSize:'24px', fontWeight:'bold'}}>
            Section title
          </Typography>
          <Box sx={{ml:'auto', mr:'24px'}}>
          <IconButton sx={{paddingTop:0, paddingRight:0,  ml:'16px'}}
            onClick={removeSection}
              >
                <DeleteDocIcon style={{fill:styleStore.getNameTitleColor()}}/>
            </IconButton>
            <IconButton sx={{paddingTop:0, paddingRight:0,  ml:'16px'}}
            onClick={() => {}}
            {...provided.dragHandleProps}
              >
                <DragIcon style={{fill:styleStore.getNameTitleColor()}}/>
            </IconButton>
            </Box>
            </Box>
          <Typography id="modal-modal-description" textAlign='start' sx={{ mt: '28px', mb:'24px', color:styleStore.getQcerrisTextSecondary(), fontSize:'14px', width:'1094px' }}>
          Description Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
          </Typography>
            </Box>
        </BoxStyled>
    )

})

export default Section;