import { Box, Card, CardContent, IconButton, Typography } from "@mui/material";
import React from "react";
import { AvatarIcon } from "../Avatar/AvatarIcon";
import { useStores } from "../../Helpers/MobX/hooks";
import { observer } from "mobx-react-lite";
import SmallPill from "../SmallPill";
import { ReactComponent as DeleteDocIcon } from '../../Assets/img/Icons/DeleteDocIcon.svg';
import { ReactComponent as CheckmarkBlue } from '../../Assets/img/Icons/CheckmarkBlue.svg';
import { ReactComponent as XBlue } from '../../Assets/img/Icons/XBlue.svg';

const CardStyled: React.FC<{
  users: any;
  onClick: any;
  status:string;
  pillText:string;
  openModal:any;
  openCancelModal:any;
}> = observer(({ users, css, onClick, status, pillText, openModal, openCancelModal }: any) => {
  const { styleStore } = useStores();
  console.log(status)
  return (
    <Card
      variant="outlined"
      
      sx={{
        width: "274px !important",
        height: "140px",
        backgroundColor: styleStore.getCardBackgroundColor(),
        border: 0,
        ":hover": {
          boxShadow: "0 0 12px 3px rgba(0, 0, 0, 0.10)",
          cursor: "pointer",
        },
        borderRadius: "15px",
        ...css,
      }}
    >
      <Box display="flex" sx={{ flexDirection: "row" }}>
        <Box
          display="flex"
          sx={{ ml: "12px", mt: "16px", height: "64px", width: "64px" }}
          onClick={onClick}
        >
          <AvatarIcon
            src={"data:image/jpeg;base64," + users?.avatar_64}
            firstName={users?.user?.first_name}
            lastName={users?.user?.last_name}
            style={{
              height: "64px",
              width: "64px",
              fontSize: "33px",
            }}
          />
        </Box>
        <CardContent sx={{mr:0}}>
          <Typography
            align="left"
            fontSize={16}
            fontWeight={500}
            sx={{ color: styleStore.getNameTitleColor() }}
            onClick={onClick}
          >
            Tanja T Khodabakhshi
          </Typography>
          <Typography
            align="left"
            fontSize={14}
            fontWeight={400}
            sx={{ color: styleStore.getTextColorSecondary() }}
            onClick={onClick}
          >
            R: Anja Obradovic
          </Typography>
          <Typography
            align="left"
            fontSize={14}
            fontWeight={400}
            sx={{ color: styleStore.getTextColorSecondary() }}
            onClick={onClick}
          >
            RD: Sep 8th 22
          </Typography>
          <Typography
            gutterBottom
            align="left"
            fontSize={14}
            fontWeight={400}
            sx={{ color: styleStore.getTextColorSecondary() }}
            onClick={onClick}
          >
            ED: Dec 10th 22
          </Typography>
          <Box display='flex' flexDirection={'row'}>
          <SmallPill label={pillText} pillType={status} />
          {
            pillText === 'New' ? (
            <Box sx={{mt:'4px', ml:'10px'}}>
              <IconButton sx={{paddingTop:0, paddingRight:0}}>
                <CheckmarkBlue />
              </IconButton>
              <IconButton sx={{paddingTop:0, paddingRight:0, ml:'10px'}} onClick={openCancelModal}>
                <XBlue />
              </IconButton>
            </Box>
            ):(<></>)
          }
          <Box sx={{ml:'auto', mt:'3px'}}>
            <IconButton sx={{paddingTop:0, paddingRight:0}}
            onClick={openModal}
              >
                <DeleteDocIcon />
            </IconButton>
            </Box>
        </Box>
        </CardContent>
        
      </Box>
    </Card>
  );
});

export default CardStyled;
