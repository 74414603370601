import React, { ChangeEvent, useEffect, useState, MouseEvent } from "react";
import { Apis } from "../../../Helpers/ApiHelper/ApiFactory";
import { observer } from "mobx-react-lite";
import Desktop from "./Desktop";
import { useStores } from "../../../Helpers/MobX/hooks";
import Loader from "../../../Components/Loader/Loader";

const OurTeam = observer(() => {
  const { tittleStore } = useStores();
  tittleStore.setTittle("Our Team");
  const [loading, setLoading] = useState<boolean>(true);
  const [users, setUsers] = useState<any>();
  const [filterValues, setFilterValues] = useState<any>();
  const [pagination, setPagination] = useState({
    count: null,
    next: null,
    page: 1,
    page_size: 30,
    previous: null,
  });
  const [urlParams, setUrlParams] = useState({
    page: pagination.page,
    page_size: pagination.page_size,
    search: null,
    client: null,
    team: null,
    position: null,
    seniority: null,
    role: null,
    coach: false,
    active: null,
  });

  const loadItems = () => {
    Apis.getUsers({
      params: urlParams,
    }).then((res: any) => {
      setUsers(res.data);
      setPagination(res.meta);
      setLoading(false);
    });

    Apis.getFilterValues().then((res: any) => {
      setFilterValues(res);
      setLoading(false);
    });
  };

  useEffect(() => {
    loadItems();
  }, [urlParams]);

  const Functions = {
    handleChangeRowsPerPage: function (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) {
      setUrlParams({
        ...urlParams,
        page: 1,
        page_size: parseInt(event.target.value, 10),
      });
    },
    handleChangePage: function (
      event: MouseEvent<HTMLButtonElement> | null,
      newPage: number
    ) {
      setUrlParams({ ...urlParams, page: newPage });
    },
    handleSearch: function (
      event: MouseEvent<HTMLButtonElement> | null,
      newPage: number
    ) {
      setUrlParams({ ...urlParams, search: null });
    },
    handleSearchCancel: function () {
      setUrlParams({ ...urlParams, search: null });
    },
    handleClearFilters: function () {
      // setUrlParams({
      //   ...urlParams,
      //   page: 1,
      //   page_size: 30,
      //   search: null,
      //   client: null,
      //   team: null,
      //   position: null,
      //   seniority: null,
      //   role: null,
      //   coach: false,
      //   active: null,
      // });
      window.location.reload();
    },
    handleCoach: function () {
      setUrlParams({
        ...urlParams,
        coach: !urlParams.coach
      })
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <Desktop
      users={users}
      pagination={pagination}
      Functions={Functions}
      urlParams={urlParams}
      setUrlParams={setUrlParams}
      filterValues={filterValues}
    />
  );
});

export default OurTeam;
