import PrimaryButton from "../../Components/Button/PrimaryButton";
import SecondaryButton from "../../Components/Button/SecondaryButton";
import { styleStore } from "../../Helpers/MobX/StyleStore";
import TabsStyled from "../../Components/TabsStyled";
import BoxStyled from "../../Components/BoxStyled";
import EmployeeForm from "../../Components/EmployeeForm";
import Link from "../../Assets/Fonts/Link";
import { observer } from "mobx-react-lite";
import { Box, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, styled, tableCellClasses } from "@mui/material";
import P from "../../Assets/Fonts/P";
import { useState } from "react";
import { userStore } from "../../Helpers/MobX/UserStore";
import { Apis } from "../../Helpers/ApiHelper/ApiFactory";
import { GetToken } from "../../Helpers/CookieHelper";
import { Circle, NoiseAwareSharp } from "@mui/icons-material";
import AutoCompleteStyled1 from "../../Components/AutocompleteStyledSecondary";
import Documents from "../../Components/Documents";
import { StylesProvider } from "@material-ui/core";
import PerformanceReviewEmployeeTable from "./components/PerformanceReviewEmployeeTable";
import PerformanceReviewEntry from "../../Pages/PerformanceReviewHistory/components/PerformanceReviewEntry";

const Desktop = observer((params: any) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [tableOpen, setTableOpen] = useState<boolean>(false);
  const [employee, setEmployee] = useState<any>(params?.user);
  const handleTabChange = (e: any, tabIndex: number) => {
    setCurrentTabIndex(tabIndex);
  };
  let currentUser = userStore.getCurrentUser();

  const StyledTableCell = observer(styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: styleStore.getCardBackgroundColor(),
      color: styleStore.getTextColorSecondary(),
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
    [`&.${tableCellClasses.root}`]: {
      borderColor: styleStore.light_mode? "#E0E0E0": '#7C7C7C',
    },
  })));

  const StyledCenteredTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: styleStore.getCardBackgroundColor(),
      color: styleStore.getTextColorSecondary(),
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },

  }));
  
  return (
    <Box
      sx={{
        overflowY: "hidden",
        position: "fixed",
        top: "80px",
        bottom: 0,
        ml: "4px",
      }}
    >
      <Box
        sx={{
          position: "fixed",
          backgroundColor: styleStore.getBackgroundPrimary(),
          zIndex: 3000,
        }}
      >
        <Box display={"flex"} sx={{ mb: "24px" }}>
          <BoxStyled css={{ width: "400px", marginLeft: "24px" }}>
            <Box display={"flex"} flexDirection={"row"} sx={{}}>
              <Box sx={{ padding: "24px" }}>
                <>
                  {employee?.avatar ? (
                    <img
                      src={employee?.avatar}
                      alt="avatar"
                      style={{
                        width: "96px",
                        height: "96px",
                        borderRadius: "64px",
                      }}
                    />
                  ) : (
                    <img src={styleStore.getBlankProfile()} alt="avatar" />
                  )}
                </>
              </Box>
              <Box display={"flex"} flexDirection={"column"}>
                <Box>
                  <Link
                    text={"Name:"}
                    css={{
                      pt: "24px",
                      color: styleStore.getTextColorSecondary(),
                    }}
                  />
                  {`${employee?.user?.first_name} ${employee?.user?.last_name}`
                        .length < 29
                        ?(
                          <P
                            text={
                              `${employee?.user?.first_name} ${employee?.user?.last_name}`
                                .length < 29
                                ? `${employee?.user?.first_name} ${employee?.user?.last_name}`
                                : `${employee?.user?.first_name} ${employee?.user?.last_name}`.substring(
                                    0,
                                    26
                                  ) + "..."
                            }
                            css={{
                              pt: "8px",
                              color: styleStore.getSecondaryButtonTextColor(),
                            }}
                          />
                          ):(
                            <Tooltip title={`${employee?.user?.first_name} ${employee?.user?.last_name}`}
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  bgcolor: styleStore.getInputBackgroundPrimary(),
                                  color: styleStore.getTextColorPrimary()
                                }
                              }
                            }}
                             >
                              <Box>
                              <P
                                text={
                                  `${employee?.user?.first_name} ${employee?.user?.last_name}`
                                    .length < 29
                                    ? `${employee?.user?.first_name} ${employee?.user?.last_name}`
                                    : `${employee?.user?.first_name} ${employee?.user?.last_name}`.substring(
                                        0,
                                        26
                                      ) + "..."
                                }
                                css={{
                                  pt: "8px",
                                  color: styleStore.getSecondaryButtonTextColor(),
                                }}
                            />
                            </Box>
                        </Tooltip>
                          )}
                  
                </Box>
                <Box>
                  <Link
                    text={"Position:"}
                    css={{
                      pt: "16px",
                      color: styleStore.getTextColorSecondary(),
                    }}
                  />
                  <P
                    text={
                      employee?.position?.name.length < 29
                        ? employee?.position?.name
                        : employee?.position?.name.substring(0, 26) + "..."
                    }
                    css={{
                      pt: "8px",
                      pb: "28px",
                      color: styleStore.getSecondaryButtonTextColor(),
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </BoxStyled>

          <BoxStyled css={{ width: "400px", marginLeft: "24px" }}>
            <Link
              text={"Email:"}
              css={{
                pt: "24px",
                pl: "24px",
                color: styleStore.getTextColorSecondary(),
              }}
            />

            <P
              text={
                employee?.user.email.length < 29
                  ? employee?.user.email
                  : employee?.user.email.substring(0, 25) + "..."
              }
              css={{
                pt: "8px",
                pl: "24px",
                color: styleStore.getSecondaryButtonTextColor(),
                width: "250px",
              }}
            />
            <Link
              text={"Phone number:"}
              css={{
                pt: "16px",
                pl: "24px",
                color: styleStore.getTextColorSecondary(),
              }}
            />
            <P
              text={employee?.phone_number}
              css={{
                pt: "8px",
                pl: "24px",
                pb: "28px",
                color: styleStore.getSecondaryButtonTextColor(),
              }}
            />
          </BoxStyled>
          <BoxStyled css={{ width: "375px", marginLeft: "24px" }}>
            <Link
              text={"Client:"}
              css={{
                pt: "24px",
                pl: "24px",
                color: styleStore.getTextColorSecondary(),
              }}
            />
            <P
              text={
                employee?.client?.name
                  ? employee?.client?.name.length < 23
                    ? employee?.client?.name
                    : employee?.client?.name.substring(0, 20) + "..."
                  : "None"
              }
              css={{
                pt: "8px",
                pl: "24px",
                color: styleStore.getSecondaryButtonTextColor(),
              }}
            />
            <Link
              text={"Team:"}
              css={{
                pt: "16px",
                pl: "24px",
                color: styleStore.getTextColorSecondary(),
              }}
            />
            <P
              text={
                employee?.team[0]?.name
                  ? employee?.team[0]?.name.length < 23
                    ? employee?.team[0]?.name
                    : employee?.team[0]?.name.substring(0, 20) + "..."
                  : "None"
              }
              css={{
                pt: "8px",
                pl: "24px",
                pb: "28px",
                color: styleStore.getSecondaryButtonTextColor(),
              }}
            />
          </BoxStyled>
          <BoxStyled css={{ width: "375px", marginLeft: "24px" }}>
            <Link
              text={"Status:"}
              css={{
                pt: "24px",
                pl: "24px",
                color: styleStore.getTextColorSecondary(),
              }}
            />
            {disabled ? (
              <Box
                display={"flex"}
                justifyContent={"flex-start"}
                sx={{ mb: "24px" }}
              >
                <Circle
                  sx={{
                    ml: "24px",
                    mt: "5px",
                    mb: "24px",
                    color: employee.user.is_active ? "#70C773" : "#EB6C78",
                    width: "14px",
                  }}
                />
                <P
                  text={employee.user.is_active ? "Active" : "Inactive"}
                  css={{
                    pt: "8px",
                    pl: "8px",
                    color: styleStore.getSecondaryButtonTextColor(),
                  }}
                />
              </Box>
            ) : (
              <AutoCompleteStyled1
                css={{ width: "160px", ml: "16px" }}
                options={["Active", "Inactive"]}
                name={"active"}
                defaultValue={employee.user.is_active ? "Active" : "Inactive"}
                onChange={(e: any, value: any) => {
                  employee.user.is_active = value === "Active" ? true : false;
                }}
              />
            )}
          </BoxStyled>
        </Box>
        <Box
          display={"flex"}
          sx={{
            width: "100vw",
            pb: "24px",
            pl: "24px",
            backgroundColor: styleStore.getBackgroundPrimary(),
          }}
        >
          <TabsStyled value={currentTabIndex} onChange={handleTabChange}>
            <Tab label="Current performance review" value={0} />
            <Tab label="Performance review history" value={1} />
          </TabsStyled>
        </Box>
      </Box>
      <Box
        sx={{
          mt: "250px",
          height: "calc(100% - 260px)",
          ml:'24px'
        }}
      >
        {currentTabIndex === 0 && (
           <Box sx={{width:'1655px', display:'flex', justifyContent:'start', flexDirection:'column'}}>
              {tableOpen? (<PerformanceReviewEmployeeTable/>) : ('')}
              <Box sx={{width:'1655px', display:'flex', justifyContent:'start', mt:'24px'}}>
              <SecondaryButton   text="+"
              css={{marginRight:'24px', marginLeft:'24px', paddingLeft:'8px', paddingRight:'8px', width:'40px', minWidth:'30px', fontSize:'24px', fontWeight:'bold'}}
                onClick={() => {
                  setTableOpen(true);
                }}/>
                <SecondaryButton   text="ADD ACTION PLAN"
                css={{marginRight:'24px'}}
                onClick={() => {
                  
                }}/>
                <SecondaryButton   text="ADD FEEDBACK"
                onClick={() => {
                  
                }}
              />
              </Box>
           </Box>
        )}
        {currentTabIndex === 1 && (
          <TableContainer sx ={{ mt:'26px',  width: '1622px', borderRadius:'15px 15px 0 0'}}>
          <Table sx={{ width: '1622px', }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{pl:'40px'}}>Requested by</StyledTableCell>
                <StyledTableCell >
                </StyledTableCell>
                <StyledTableCell >
                </StyledTableCell>
                <StyledTableCell >Name of PR</StyledTableCell>
                <StyledTableCell >
                </StyledTableCell>
                <StyledTableCell >
                </StyledTableCell>
                <StyledTableCell >Requested date</StyledTableCell>
                <StyledTableCell >
                </StyledTableCell>
                <StyledTableCell >
                </StyledTableCell>
                <StyledTableCell >Preview</StyledTableCell>
                <StyledTableCell >
                </StyledTableCell>
                <StyledTableCell >
                </StyledTableCell>
                <StyledTableCell >Download</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
             <PerformanceReviewEntry name='Anja Obradovic'  dateOfRequest='12/31/2023' requester='' color = {styleStore.light_mode? "#ebccd3" : '#7b3d41'}/>
             <PerformanceReviewEntry name='Anja Obradovic' dateOfRequest='12/31/2023' requester='' color = {styleStore.light_mode? "#ebccd3" : '#7b3d41'}/>
             <PerformanceReviewEntry name='Anja Obradovic'  dateOfRequest='12/31/2023' requester='' color = ''/>
             <PerformanceReviewEntry name='Anja Obradovic'dateOfRequest='12/31/2023' requester='' color = {styleStore.light_mode? "#ebccd3" : '#7b3d41'}/>
            </TableBody>
          </Table>
        </TableContainer>
        )}
      </Box>
      
    </Box>
  );
});

export default Desktop;
