import axios from "axios";
import { Api_Helper } from "./ApiHelper";
import { SetToken, DecodeToken } from "../CookieHelper";
import { createUserStore } from "../MobX/PageStores.tsx/CreateUserStore";

export class Apis {
  public getUser(): Promise<string> {
    const promise = axios.get("/users/");
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
  }

  static postLogin(email: string, password: string) {
    const api_helper = new Api_Helper();
    let data: object = {
      email: email,
      password: password,
    };
    let data_promise = api_helper
      .post("/auth/login/", false, data, false)
      .then(function (data: any) {
        SetToken(data["jwt"]);
        return data;
      });
    return data_promise;
  }

  static getCurrentUser(token: any) {
    let decoded_token: any = DecodeToken(token);
    const api_helper = new Api_Helper();
    let data_promise = api_helper
      .get(`/users/profiles/${decoded_token.user_id}/`, true, token)
      .then(function (data: any) {
        return data;
      });
    return data_promise;
  }
  static getProfile(id: string) {
    const api_helper = new Api_Helper();
    let data_promise = api_helper
      .get(`/users/profiles/${id}/`, true)
      .then(function (data: any) {
        return data;
      });
    return data_promise;
  }

  static getUsers(config?: any) {
    const api_helper = new Api_Helper();
    let data_promise = api_helper
      .get(`/users/user-list/`, true, undefined, config)
      .then(function (data: any) {
        return data;
      });
    return data_promise;
  }
  static getFilterValues() {
    const api_helper = new Api_Helper();
    let data_promise = api_helper
      .get(`/users/selection-filters-values/`, true)
      .then(function (data: any) {
        return data;
      });
    return data_promise;
  }

  static getNotifications(token: any) {
    const api_helper = new Api_Helper();
    let data_promise: any = api_helper
      .get(`/notification/api/all_list/`, true, token)
      .then(function (data: any) {
        return data;
      })
      .catch((data) => {
        if (data.response.status === 401) {
          window.location.href = window.location.origin + "/logout";
        }
      });
    return data_promise;
  }

  static setAllNotificationsRead() {
    const api_helper = new Api_Helper();
    let data_promise = api_helper
      .patch(`/notification/mark-all-as-read/`, true)
      .then(function (data: any) {
        return data;
      });
    return data_promise;
  }

  static setNotificationRead(id: any) {
    const api_helper = new Api_Helper();
    let data_promise = api_helper
      .patch(`/notification/mark-as-read/${id}/`, true)
      .then(function (data: any) {
        return data;
      });
    return data_promise;
  }

  static logout() {
    const api_helper = new Api_Helper();
    api_helper.post(`/auth/logout/`, false);
  }

  static getPosition() {
    const api_helper = new Api_Helper();
    let data_promise = api_helper
      .get(`/users/positions/`, true)
      .then(function (data: any) {
        return data;
      });
    return data_promise;
  }
  static getSeniority() {
    const api_helper = new Api_Helper();
    let data_promise = api_helper
      .get(`/users/seniorities/`, true)
      .then(function (data: any) {
        return data;
      });
    return data_promise;
  }
  static getClients() {
    const api_helper = new Api_Helper();
    let data_promise = api_helper
      .get(`/users/clients/`, true)
      .then(function (data: any) {
        return data;
      });
    return data_promise;
  }
  static getTeams() {
    const api_helper = new Api_Helper();
    let data_promise = api_helper
      .get(`/users/teams/`, true)
      .then(function (data: any) {
        return data;
      });
    return data_promise;
  }
  static getTeamsPerClient() {
    const api_helper = new Api_Helper();
    let data_promise = api_helper
      .get(`/users/teams-per-client/`, true)
      .then(function (data: any) {
        return data;
      });
    return data_promise;
  }
  static setUser(data: any) {
    const api_helper = new Api_Helper();
    let data_promise = api_helper
      .post(`/users/profiles/flat`, true, data, false)
      .then(function (data: any) {
        return data;
      });
    return data_promise;
  }
  static updateUser(data: any, token: any) {
    let decoded_token: any = DecodeToken(token);
    const api_helper = new Api_Helper();
    if (createUserStore.getFormData().avatar !== undefined)
      data.avatar = createUserStore.getFormData().avatar;
    let data_promise = api_helper
      .patch(`/users/profiles/flat/${data.id}/`, true, data, false)
      .then(function (data: any) {
        return data;
      });
    return data_promise;
  }
  static setTeam(data: any) {
    const api_helper = new Api_Helper();
    let data_promise = api_helper
      .post(`/users/teams/`, true, data, false)
      .then(function (data: any) {
        return data;
      });
    return data_promise;
  }
  static setSeniority(data: any) {
    const api_helper = new Api_Helper();
    let data_promise = api_helper
      .post(`/users/seniorities/`, true, data, false)
      .then(function (data: any) {
        return data;
      });
    return data_promise;
  }
  static setPosition(data: any) {
    const api_helper = new Api_Helper();
    let data_promise = api_helper
      .post(`/users/positions/`, true, data, false)
      .then(function (data: any) {
        return data;
      });
    return data_promise;
  }
  static setMainClient(data: any) {
    const api_helper = new Api_Helper();
    let data_promise = api_helper
      .post(`/users/clients/`, true, data, false)
      .then(function (data: any) {
        return data;
      });
    return data_promise;
  }
}
