import { styleStore } from "../../Helpers/MobX/StyleStore";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import ClearIcon from "@mui/icons-material/Clear";

const ImageInputText = observer(
  ({ css, onChange, name, inputProps, value, onClick }: any) => {
    return (
      <TextField
        name={name}
        onChange={onChange}
        variant="outlined"
        value={value}
        InputProps={{
          inputProps,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={onClick}
                sx={{
                  width: "24px",
                  height: "24px",
                  color: styleStore.getActiveText(),
                }}
              >
                <ClearIcon sx={{ width: "20px" }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          ...css,
          // cursor: "auto",
          color: "red",
          input: {
            color: styleStore.getActiveText(),
          },
          "& label.Mui-focused": {
            color: "blue",
          },
          "& .MuiOutlinedInput-root": {
            bgcolor: styleStore.getInputBackgroundPrimary(),
            height: "40px",
            borderRadius: "15px",
            "& fieldset": {
              border: styleStore.getInputBorder(),
              borderRadius: "15px",
            },
            "&:hover fieldset": {
              border: styleStore.getInputBorder(),
              borderRadius: "15px",
            },
            "&.Mui-focused fieldset": {
              border: styleStore.getInputBorder(),
              borderRadius: "15px",
            },
          },
        }}
      />
    );
  }
);

export default ImageInputText;
