import { Box } from "@mui/material";

type PillType = 'green' | 'yellow' | 'red'

interface IPillParams{
    pillType:PillType,
    label:string;
}
const SmallPill = ({pillType, label}:IPillParams) => {
return(
<Box sx={{
    width: label === 'Completed'? '112px': label ==='Pending'? '84px' : label === 'Declined'? '86px': '60px',
    height:'20px',
    display:'flex',
    borderRadius:'5px',
    justifyContent:'center',
    alignItems:'center',
    backgroundColor: pillType=== 'yellow'?'#F6C61C33': pillType==='green'? '#70C77333': pillType === 'red'? '#EB6C7833': '#3A90C333',
    color:pillType=== 'yellow'?'#F6C61C': pillType==='green'? '#70C773': pillType === 'red' ? '#EB6C78': "#3A90C3",
    fontSize:'14px',
    paddingTop:'2px',
    fontWeight: 'bold'
    
}}>
{
label
}

</Box>
)
}

export default SmallPill;