import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useStores } from "../Helpers/MobX/hooks";
import { observer } from "mobx-react-lite";
import { styleStore } from "../Helpers/MobX/StyleStore";

type CheckboxProps = {
  label: string;
  onClick: any;
};
const CheckboxStyled = observer((props: CheckboxProps) => {
  return (
    <FormGroup>
      <FormControlLabel
        sx={{
          fontSize: "16px",
          ml: "10px",
          color: styleStore.getNameTitleColor(),
        }}
        control={
          <Checkbox
          onClick={props.onClick}
            sx={{
              color: styleStore.getCardBackgroundColor(),
              transform: "scale(0.7)",
              "& .MuiSvgIcon-root": {
                backgroundColor: styleStore.getCardBackgroundColor(),
                path: {
                  transform: "translate(-3px, -3px) scale(1.3)",
                },
              },
            }}
          />
        }
        label={props.label}
      />
    </FormGroup>
  );
});

export default CheckboxStyled;
