import { IconButton } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import AvatarWrapper from "../../Avatar/AvatarWrapper";
import Notifications from "../../Notifications";
import { useStores } from "../../../Helpers/MobX/hooks";
import { observer } from "mobx-react-lite";
import Title from "../../Title";

const Desktop = observer(() => {
  const { tittleStore } = useStores();
  const { sidebarStore } = useStores();
  const { styleStore } = useStores();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        style={{
          width: sidebarStore.open ? `calc(99% - 240px)` : `calc(99% - 90px)`,
          marginLeft: sidebarStore.open ? `240px` : "90px",
          boxShadow: "none",
          backgroundColor: styleStore.getBackgroundPrimary(),
          color: styleStore.getForegroundPrimary(),
          height: "0px",
        }}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, flexGrow: 1 }}
      >
        <Toolbar sx={{ backgroundColor: styleStore.getBackgroundPrimary() }}>
          <Box sx={{ display: "flex", alignItems: "center", width: "100vw" }}>
            <IconButton
              sx={{
                ml: sidebarStore.open ? "32px" : "22px",
                position: "absolute",
                left: 0,
                translate: "-60px 10px",
              }}
              disableRipple
              onClick={() => {
                sidebarStore.toggle();
              }}
            >
              <img
                src={styleStore.getSideBarToggle()}
                alt="toggle"
                style={{ zIndex: 2 }}
              />
            </IconButton>
            <Box
              sx={{
                width: "100%",
                alignItems: "center",
                justifyContent: "left",
                ml: "-16px",
                display: "flex",
                mt: "20px",
              }}
            >
              <Title label={tittleStore.tittle} />
            </Box>
          </Box>
          <Box
            sx={{
              alignItems: "center",
              justifyContent: "right",
              display: "flex",
            }}
          >
            <Notifications right={20} />
            <AvatarWrapper />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
});

export default Desktop;
