import Accordion from "@mui/material/Accordion";
import { useStores } from "../../Helpers/MobX/hooks";
import { observer } from "mobx-react-lite";

const AccordionSidebar: React.FC<{ children: any; permission?: string }> =
  observer(({ children, permission = undefined }) => {
    const { userStore } = useStores();
    const { styleStore } = useStores();
    return (
      <>
        {permission !== "" && permission !== undefined ? (
          userStore.getPermissionByName(permission) ? (
            <Accordion
              style={{ boxShadow: "none" }}
              disableGutters
              elevation={0}
              sx={{
                backgroundColor: styleStore.getBackgroundColor(),
                color: styleStore.getIconSidebarButtonColor(),
                "&.MuiAccordion-root:before": {
                  backgroundColor: styleStore.getBackgroundColor(),
                },
              }}
            >
              {children}
            </Accordion>
          ) : (
            ""
          )
        ) : (
          <Accordion
            style={{ boxShadow: "none" }}
            disableGutters
            elevation={0}
            sx={{
              width: "250px",
              backgroundColor: styleStore.getBackgroundColor(),
              color: styleStore.getIconSidebarButtonColor(),
              "&.MuiAccordion-root:before": {
                backgroundColor: styleStore.getBackgroundColor(),
              },
            }}
          >
            {children}
          </Accordion>
        )}
      </>
    );
  });
export default AccordionSidebar;
