import { Apis } from "../../../Helpers/ApiHelper/ApiFactory";
import { useStores } from "../../../Helpers/MobX/hooks";
import Loader from "../../../Components/Loader/Loader";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import Desktop from "./Desktop";
import { toJS } from "mobx";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";

const Profile = observer(() => {
  const { createUserStore } = useStores();
  const { tittleStore } = useStores();
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<any>();
  const params = useParams<{ employeeId: string }>();
  tittleStore.setTittle("Employee");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (params.employeeId !== undefined) {
          const profileResp: any = await Apis.getProfile(params.employeeId);
          setUser(profileResp);
        }

        const [positionsResp, seniorityResp, teamsResp, clientsResp, usersResp] = await Promise.all([
          Apis.getPosition(),
          Apis.getSeniority(),
          Apis.getTeams(),
          Apis.getClients(),
          Apis.getUsers()
        ]);

        createUserStore.setPositions(positionsResp?.map((item: any) => item) || []);
        createUserStore.setSeniority(seniorityResp?.map((item: any) => item) || []);
        createUserStore.setTeams(teamsResp?.map((item: any) => item) || []);
        createUserStore.setClients(clientsResp?.map((item: any) => item) || []);

        const supervisors = usersResp.data
          .filter((e: any) => e.is_supervisor)
          .map((item: any) => item.first_name + " " + item.last_name);
        const teamleads = usersResp.data
          .filter((e: any) => e.is_team_lead)
          .map((item: any) => item.first_name + " " + item.last_name);

        createUserStore.setSupervisors(supervisors);
        createUserStore.setTeamleads(teamleads);
      } catch (error) {
        console.error('Failed to fetch data', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [createUserStore, params.employeeId]);

  

  return loading ? <Loader /> : <Desktop user={user} />;
});

export default Profile;

// static getUserProfile() {
//   const api_helper = new Api_Helper();
//   let data_promise = api_helper
//     .get(`/users/profiles/8/`, true)
//     .then(function (data: any) {
//       return data;
//     });
//   return data_promise;
// }
