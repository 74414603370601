import ListItemButton from "@mui/material/ListItemButton";
import { useNavigate } from "react-router-dom";
import { useStores } from "../../Helpers/MobX/hooks";
import { observer } from "mobx-react-lite";
import { Typography } from "@mui/material";
import { sidebarStore } from "../../Helpers/MobX/SidebarStore";

const ListItemSidebarSubButton = observer((params: any) => {
  const navigate = useNavigate();
  const { userStore } = useStores();
  const { styleStore } = useStores();
  return (
    <>
      {params.permission !== "" && params.permission !== undefined ? (
        userStore.getPermissionByName(params.permission) ? (
          <ListItemButton
            sx={{
              color: window.location.pathname === params.redirectURL ? styleStore.getIconSidebarButtonColor_hover() : styleStore.getIconSidebarButtonColor(),
              stroke: window.location.pathname === params.redirectURL ? styleStore.getIconSidebarButtonColor_hover() : styleStore.getIconSidebarButtonColor(),
              ":hover": {
                color: styleStore.getIconSidebarButtonColor_hover(),
                stroke: styleStore.getIconSidebarButtonColor_hover(),
                backgroundColor: styleStore.getBackgroundColor_hover(),
              },
            }}
            onClick={() => {
              navigate(params.redirectURL);
            }}
          >
            {params.icon}
            <Typography sx={{ color: styleStore.getNameTitleColor() }}>
              {sidebarStore.open ? params.text : <></>}
            </Typography>
          </ListItemButton>
        ) : (
          ""
        )
      ) : (
        <ListItemButton
        // selected={selected}
          sx={{
            color: window.location.pathname === params.redirectURL ? styleStore.getIconSidebarButtonColor_hover() : styleStore.getIconSidebarButtonColor(),
            stroke: window.location.pathname === params.redirectURL ? styleStore.getIconSidebarButtonColor_hover() : styleStore.getIconSidebarButtonColor(),
            backgroundColor: window.location.pathname === params.redirectURL ? styleStore.getBackgroundColor_hover(): styleStore.getBackgroundColor(),
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyItems: "center",
            borderRadius: "5px",
            ml: sidebarStore.open ? "15px" : "3px",
            width: sidebarStore.open ? "200px" : "50px",
            minHeight: "40px",
            ":hover": {
              color: styleStore.getIconSidebarButtonColor_hover(),
              stroke: styleStore.getIconSidebarButtonColor_hover(),
              backgroundColor: styleStore.getBackgroundColor_hover(),
              borderRadius: "5px",
            },
            
          }}
          onClick={() => {
            navigate(params.redirectURL);
            // setSelected(!selected)
          }}
        >
          {params.icon}
          {sidebarStore.open ? params.text : <></>}
        </ListItemButton>
      )}
    </>
  );
});
export default ListItemSidebarSubButton;
