import "./App.css";

import PrivateRoute from "./Helpers/Routes/PrivateRoute";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Authenticator } from "./Helpers/Routes/Authenticator";

import Login from "./Pages/Login/Login";
import Logout from "./Pages/Logout/Logout";
import Home from "./Pages/Home/Employee/Home";
import CreateUser from "./Pages/Employee/CreateNewUser/CreateUser";
import PageWrapper from "./Components/PageWrapper/PageWrapper";
import { GetStyle } from "./Helpers/CookieHelper";
import { useStores } from "./Helpers/MobX/hooks";
import OurTeam from "./Pages/Employee/OurTeam/OurTeam";
import "typeface-roboto";
import Profile from "./Pages/Employee/Profile/Profile";
import TravelRequest from "./Pages/TravelRequest/TravelRequest";
import TravelRequestList from "./Pages/TravelRequestList/TravelRequestList";
import PerformanceReviewRequest from "./Pages/PerformanceReviewRequest/PerformanceReviewRequest";
import PerformanceReview from "./Pages/PerformanceReview/PerformanceReview";
import PerformanceReviewList from "./Pages/PerformanceReviewList/PerformanceReviewList";
import PerformanceReviewEmployee from "./Pages/PerformanceReivewEmployee/PerformanceReviewEmployee";
import PerformanceReviewTemplate from "./Pages/PerformanceReviewTemplate/PerformanceReviewTemplate";
import PerformanceReviewNewForm from "./Pages/PerformanceReviewNewForm/PerformanceReviewNewForm";
import PerformanceReviewHistory from "./Pages/PerformanceReviewHistory/PerformanceReviewHistory";
import Recruitment from "./Pages/Recruitment/Recruitment";
function elementWrapper(element: any) {
  return <PageWrapper>{element}</PageWrapper>;
}

function App() {
  const { styleStore } = useStores();
  styleStore.setLightMode(GetStyle() === "true");
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/login"
            element={
              Authenticator.authenticate() ? <Navigate to="/" /> : <Login />
            }
          />
          <Route path="/logout" element={<Logout />} />
          <Route element={<PrivateRoute />}>
            <Route path="/" element={elementWrapper(<Home />)} />
            <Route
              path="/create-user"
              element={elementWrapper(<CreateUser />)}
            />
            <Route
              path="/employee-list"
              element={elementWrapper(<OurTeam />)}
            />
            <Route
              path="/employee/:employeeId"
              element={elementWrapper(<Profile />)}
            />
             <Route
              path="/travel-request"
              element={elementWrapper(<TravelRequest />)}
            />
            <Route
              path="/travel-request-list"
              element={elementWrapper(<TravelRequestList />)}
            />
             <Route
              path="/performance-review-request"
              element={elementWrapper(<PerformanceReviewRequest />)}
            />
             <Route
              path="/performance-review"
              element={elementWrapper(<PerformanceReview />)}
            />
            <Route
              path="/performance-review-list"
              element={elementWrapper(<PerformanceReviewList />)}
            />
             <Route
              path="/performance-review-employee"
              element={elementWrapper(<PerformanceReviewEmployee />)}
            />
             <Route
              path="/performance-review-template"
              element={elementWrapper(<PerformanceReviewTemplate />)}
            />
            <Route
              path="/performance-review-new-form"
              element={elementWrapper(<PerformanceReviewNewForm />)}
            />
            <Route
              path="/performance-review-history"
              element={elementWrapper(<PerformanceReviewHistory />)}
            />
            <Route
              path="/recruitment-landing"
              element={elementWrapper(<Recruitment />)}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
