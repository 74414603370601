import AutoCompleteStyledFilters from "../../Components/AutoCompleteStyledFilters";
import AutocompleteStyled from "../../Components/AutoComplete/AutocompleteStyled";
import { Box, Grid, Button, Modal, Typography } from "@mui/material";
import PerformanceReviewTemplateCard from "../../Components/Cards/PerformanceReviewTemplateCards";
import { observer } from "mobx-react-lite";
import Title from "../../Components/Title";
import { styleStore } from "../../Helpers/MobX/StyleStore";
import CheckboxStyled from "../../Components/CheckboxStyled";
import PaginationStyled from "../../Components/Pagination/PaginationStyled";
import P from "../../Assets/Fonts/P";
import { useNavigate } from "react-router-dom";
import { userStore } from "../../Helpers/MobX/UserStore";
import { useState } from "react";
import SecondaryFileDialogButton from "../../Components/Button/SecondaryFileDialogButton";
import PrimaryButton from "../../Components/Button/PrimaryButton";
import InputFieldOutlined from "../../Components/Textbox/InputFieldOutlined";
import  PlusCard  from "../../Components/Cards/PlusCard";
import InputFieldNotOutlined from "../../Components/Textbox/InputFieldNotOutlined";


const Desktop = observer(
  () => {
    const navigate = useNavigate();
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleDeleteModalClose = () => setOpenDeleteModal(false);

  const [newRequestInfo, setNewRequestInfo] = useState<string>('');
  const [deleteRequestInfo, setDeleteRequestInfo] = useState<string>('');
  const [cancelRequestInfo, setCancelRequestInfo] = useState<string>('');

  const [deleteRequiredCheck, setDeleteRequiredCheck] = useState<any>({boarder:0, borderColor:"red"});
  const [newPRRequiredCheck, setNewPRRequiredCheck] = useState<any>({boarder:0, borderColor:"red"});

  const [openNewModal, setOpenNewModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const handleOpenNewModal = () => setOpenNewModal(true);
  const handleOpenNewModalClose = () => setOpenNewModal(false);
  const handleOpenCancelModal = () => setOpenCancelModal(true);
  const handleCancelModalClose = () => setOpenCancelModal(false);
  
    return (
      <>
        <Box pl={"24px"} sx={{ mt: "87px" }}>
        
          <Grid sx={{ minWidth: 300 }} container>
            <Typography
             sx={{color:styleStore.getTextColorSecondary()}}
            >Current forms</Typography>
          </Grid>

          <Grid
            container
            spacing={{ xs: "24px" }}
            sx={{ pt: "16px" }}
            columns={6}
          >

              <Grid item key={1}>
                <PerformanceReviewTemplateCard
                  users={userStore.getCurrentUser()}
                  onClick={() => {
                    navigate('/performance-review-employee')
                  }}
                  status='green'
                  pillText='Completed'
                  openModal={handleOpenDeleteModal}
                  openCancelModal={handleOpenCancelModal}
                  title={'Client form'}
                  last_updated="20. 04. 22."
                  created_by="Tanja T Khodabakhshi"
                />
              </Grid>
              <Grid item key={2}>
                <PerformanceReviewTemplateCard
                  users={userStore.getCurrentUser()}
                  onClick={() => {
                    navigate('/performance-review-employee')
                  }}
                  status='green'
                  pillText='Completed'
                  openModal={handleOpenDeleteModal}
                  openCancelModal={handleOpenCancelModal}
                  title={'Self Assessment'}
                  last_updated="20. 04. 22."
                  created_by="Tanja T Khodabakhshi"
                />
              </Grid>
              <Grid item key={3}>
                <PerformanceReviewTemplateCard
                  users={userStore.getCurrentUser()}
                  onClick={() => {
                    navigate('/performance-review-employee')
                  }}
                  status='green'
                  pillText='Completed'
                  openModal={handleOpenDeleteModal}
                  openCancelModal={handleOpenCancelModal}
                  title={'Manager / Team lead'}
                  last_updated="20. 04. 22."
                  created_by="Tanja T Khodabakhshi"
                />
              </Grid>
              <Grid item key={4}>
                <PerformanceReviewTemplateCard
                  users={userStore.getCurrentUser()}
                  onClick={() => {
                    navigate('/performance-review-employee')
                  }}
                  status='green'
                  pillText='Completed'
                  openModal={handleOpenDeleteModal}
                  openCancelModal={handleOpenCancelModal}
                  title={'HR'}
                  last_updated="20. 04. 22."
                  created_by="Tanja T Khodabakhshi"
                />
              </Grid>
              <Grid item key={5}>
                <PerformanceReviewTemplateCard
                  users={userStore.getCurrentUser()}
                  onClick={() => {
                    navigate('/performance-review-employee')
                  }}
                  status='green'
                  pillText='Completed'
                  openModal={handleOpenDeleteModal}
                  openCancelModal={handleOpenCancelModal}
                  title={'Test form'}
                  last_updated="20. 04. 22."
                  created_by="Tanja T Khodabakhshi"
                />
              </Grid>
              <Grid item key={6}>
                <PlusCard
                  users={userStore.getCurrentUser()}
                  onClick={() => {
                    navigate('/performance-review-employee')
                  }}
                  status='green'
                  pillText='Completed'
                  openModal={handleOpenNewModal}
                  openCancelModal={handleOpenNewModal}
                  title={'Test form'}
                  last_updated="20. 04. 22."
                  created_by="Tanja T Khodabakhshi"
                />
              </Grid>
          </Grid>
          <Grid sx={{ minWidth: 300, mt:'24px' }} container>
            <Typography
             sx={{color:styleStore.getTextColorSecondary()}}
            >Form creation guide</Typography>
          </Grid>
          <Grid
            container
            spacing={{ xs: "24px" }}
            sx={{ pt: "24px", pl:"24px" }}
            columns={6}
          >

            <InputFieldNotOutlined
              css={{ width: "798px", marginTop:'8px', color:styleStore.getTextColorSecondary()}}
              name={"Reason of deny a performance review:"}
              value={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."}
              placeholder=''
              rows = {6}
              onChange={(e: any) => {
                setCancelRequestInfo(
                  e.target.value,
                );
              }}
            />     
          </Grid>
           <Modal
        open={openDeleteModal}
        onClose={handleDeleteModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ backgroundColor:'#313131 80%' , backdropFilter: "blur(3px)",}}
      >
        <>
        <Box sx={{backgroundColor:styleStore.getCardBackgroundPrimary(),
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '448px',
        border: '1px',
        borderRadius:'15px',
        p: 4,
        }}>
          <Typography id="modal-modal-title" sx={{color:styleStore.getNameTitleColor(), fontSize:'16px', fontWeight:'bold'}}>
          Delete Performance Review
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, color:styleStore.getQcerrisTextSecondary(), fontSize:'14px' }}>
          Are you sure you want to delete this performance review form?
          </Typography>
          <InputFieldNotOutlined
                  css={{ width: "445px", marginTop:'8px', inputBackgroundColor:styleStore.getInputBackgroundPrimary(),}}
                  name={"Reason of delete a performance review:"}
                  value={cancelRequestInfo}
                  placeholder='Write a reason for deleting this review...'
                  rows = {3}
                  onChange={(e: any) => {
                    setCancelRequestInfo(
                      e.target.value,
                    );
                  }}
                />     
        </Box>
        <Box sx={{
          display:'flex',
          flexDirection:'row',
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        mt:'180px',
        ml:'140px',
        width:'800px'
        }}>
          <SecondaryFileDialogButton
            text="Cancel"
            onClick={() => {
              handleDeleteModalClose()
            }}
          />
          <Box sx={{mr:'24px'}}/>
        <PrimaryButton
          text={"Delete"}
          onClick={() => {
            console.log("primary");
          }}
        />
        
          </Box>
          </>
      </Modal>
      <Modal
        open={openCancelModal}
        onClose={handleCancelModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ backgroundColor:'#313131 80%' , backdropFilter: "blur(3px)",}}
      >
        <>
        <Box sx={{backgroundColor:styleStore.getCardBackgroundPrimary(),
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '448px',
        border: '1px',
        borderRadius:'15px',
        p: 4,
        }}>
          <Typography id="modal-modal-title" sx={{color:styleStore.getNameTitleColor(), fontSize:'16px', fontWeight:'bold'}}>
          Decline Performance Review
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, color:styleStore.getQcerrisTextSecondary(), fontSize:'14px' }}>
          Are you sure you want to decline this performance review?
          </Typography>
          <InputFieldNotOutlined
                  css={{ width: "445px", marginTop:'8px', inputBackgroundColor:styleStore.getInputBackgroundPrimary(), ...deleteRequiredCheck}}
                  name={"Reason of deny a performance review:"}
                  value={cancelRequestInfo}
                  placeholder=''
                  rows = {3}
                  onChange={(e: any) => {
                    setCancelRequestInfo(
                      e.target.value,
                    );
                  }}
                />     
        </Box>
        <Box sx={{
          display:'flex',
          flexDirection:'row',
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        mt:'180px',
        ml:'140px',
        width:'800px'
        }}>
          <SecondaryFileDialogButton
            text="Cancel"
            onClick={() => {
              handleCancelModalClose()
            }}
          />
          <Box sx={{mr:'24px'}}/>
        <PrimaryButton
          text={"Decline"}
          onClick={() => {
            cancelRequestInfo === '' ? setDeleteRequiredCheck({border: 1, borderColor:"red", borderRadius: '15px'}) : setDeleteRequiredCheck({border: 0, borderColor:"red", borderRadius: '15px'})
          }}
        />
        
          </Box>
          </>
      </Modal>
      <Modal
        open={openNewModal}
        onClose={handleOpenNewModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ backgroundColor:'#313131 80%' , backdropFilter: "blur(3px)",}}
      >
        <>
        <Box sx={{backgroundColor:styleStore.getCardBackgroundPrimary(),
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '448px',
        border: '1px',
        borderRadius:'15px',
        p: 4,
        }}>
          <Typography id="modal-modal-title" sx={{color:styleStore.getNameTitleColor(), fontSize:'16px', fontWeight:'bold'}}>
          New Performance Review Form
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, color:styleStore.getQcerrisTextSecondary(), fontSize:'14px' }}>
          To create a PR Form Template, please enter it's name first. You will be redirected to a PR Form Template creation page.
          </Typography>
          <InputFieldNotOutlined
                  css={{ width: "445px", marginTop:'8px',inputBackgroundColor:styleStore.getInputBackgroundPrimary(), ...newPRRequiredCheck}}
                  name={"New performance review name"}
                  value={newRequestInfo}
                  placeholder=''
                  rows = {3}
                  onChange={(e: any) => {
                    setNewRequestInfo(
                      e.target.value,
                    );
                  }}
                />     
        </Box>
        <Box sx={{
          display:'flex',
          flexDirection:'row',
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        mt:'180px',
        ml:'140px',
        width:'800px'
        }}>
          <SecondaryFileDialogButton
            text="Cancel"
            onClick={() => {
              handleOpenNewModalClose()
            }}
          />
          <Box sx={{mr:'24px'}}/>
        <PrimaryButton
          text={"Create"}
          onClick={() => {
            newRequestInfo === '' ? setNewPRRequiredCheck({border: 1, borderColor:"red", borderRadius: '15px'}) : setNewPRRequiredCheck({border: 0, borderColor:"red", borderRadius: '15px'})
            navigate('/performance-review-new-form')
          }}
        />
        
          </Box>
          </>
      </Modal>
        </Box>
      </>
    );
  }
);

export default Desktop;
