import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Tooltip} from "@mui/material";
import P from "../../Assets/Fonts/P";
import BoxStyled from "../../Components/BoxStyled";
import { useState } from "react";
import Link from "../../Assets/Fonts/Link";
import { useStores } from "../../Helpers/MobX/hooks";
import { observer } from "mobx-react-lite";
import AutocompleteStyled from "../../Components/AutoComplete/AutocompleteStyled";
import PrimaryButton from "../../Components/Button/PrimaryButton";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import InputFieldOutlined from "../../Components/Textbox/InputFieldOutlined";

const useStyles = makeStyles(theme =>
  createStyles({
    smallRadioButton: {
      "& svg": {
        width: "10px",
        height: "10px"
      }
    }
  })
);


const Desktop = observer(() => {

      const { userStore } = useStores();
      const { styleStore } = useStores();
      const [searchVal, setSearchVal] = useState<string>("");
      const[additionalInfo, setAdditionalInfo] = useState<string>("");
      const classes = useStyles();
      
    return (
      <Box display={'flex'} flexDirection={'column'}>
      <Box display='flex' flexDirection={'row'}>
        <BoxStyled css={{ width: "430px", marginLeft: "24px", backgroundColor:styleStore.getCardBackgroundColor() }}>
          <Box display={"flex"} flexDirection={"row"} sx={{}}>
            <Box sx={{ ml: "12px", mt:'24px', mb:'24px', mr:'18px' }}>
              <>
                {userStore.currentUser?.avatar ? (
                  <img
                    src={userStore.currentUser?.avatar}
                    alt="avatar"
                    style={{
                      width: "96px",
                      height: "96px",
                      borderRadius: "64px",
                    }}
                  />
                ) : (
                  <img src={userStore.currentUser.getBlankProfile()} alt="avatar" />
                )}
              </>
            </Box>
            <Box display={"flex"} flexDirection={"column"}>
              <Box style={{display:'flex', flexDirection:'row', alignItems:'center', marginTop:'23px'}}>
                <Link
                  text={"Name:"}
                  css={{
                    color: styleStore.getTextColorSecondary(),
                    marginRight: '10px'
                  }}
                />
                {`${userStore.currentUser?.user?.first_name} ${userStore.currentUser?.user?.last_name}`
                      .length < 29
                      ?(
                        <P
                          text={
                            `${userStore.currentUser?.user?.first_name} ${userStore.currentUser?.user?.last_name}`
                              .length < 29
                              ? `${userStore.currentUser?.user?.first_name} ${userStore.currentUser?.user?.last_name}`
                              : `${userStore.currentUser?.user?.first_name} ${userStore.currentUser?.user?.last_name}`.substring(
                                  0,
                                  26
                                ) + "..."
                          }
                          css={{
                            color: styleStore.getSecondaryButtonTextColor(),
                          }}
                        />
                        ):(
                          <Tooltip title={`${userStore.currentUser?.user?.first_name} ${userStore.currentUser?.user?.last_name}`}
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: styleStore.getInputBackgroundPrimary(),
                                color: styleStore.getTextColorPrimary()
                              }
                            }
                          }}
                          >
                            <Box>
                            <P
                              text={
                                `${userStore.currentUser?.user?.first_name} ${userStore.currentUser?.user?.last_name}`
                                  .length < 29
                                  ? `${userStore.currentUser?.user?.first_name} ${userStore.currentUser?.user?.last_name}`
                                  : `${userStore.currentUser?.user?.first_name} ${userStore.currentUser?.user?.last_name}`.substring(
                                      0,
                                      26
                                    ) + "..."
                              }
                              css={{
                                color: styleStore.getSecondaryButtonTextColor(),
                              }}
                          />
                          </Box>
                      </Tooltip>
                        )}
                
              </Box>
              <Box style={{display:'flex', flexDirection:'row', alignItems:'center', marginTop:'20px'}}>
                <Link
                  text={"Role:"}
                  css={{
                    color: styleStore.getTextColorSecondary(),
                    marginRight: '19px'
                  }}
                />
                <P
                  text={
                      userStore.currentUser?.position?.name.length < 29
                      ? userStore.currentUser?.position?.name
                      : userStore.currentUser?.position?.name.substring(0, 26) + "..."
                  }
                  css={{
                    color: styleStore.getSecondaryButtonTextColor(),
                  }}
                />
              </Box>
              <Box style={{display:'flex', flexDirection:'row', alignItems:'center', marginTop:'20px'}}>
                <Link
                  text={"Client:"}
                  css={{
                    color: styleStore.getTextColorSecondary(),
                    marginRight: '10px'
                  }}
                />
                <P
                  text={
                      userStore.currentUser?.client?.name.length < 29
                      ? userStore.currentUser?.client?.name
                      : userStore.currentUser?.client?.name.substring(0, 26) + "..."
                  }
                  css={{
                    color: styleStore.getSecondaryButtonTextColor(),
                  }}
                />
              </Box>
            </Box>
          </Box>
        </BoxStyled>
        <Box sx={{mt:'62px', ml:'63px', color:styleStore.getMainButtonColor()}}>
          Requesting for
        </Box>
        <BoxStyled css={{ width: "430px", marginLeft: "65px", backgroundColor:styleStore.getCardBackgroundColor() }}>
          <Box display={"flex"} flexDirection={"row"} sx={{alignItems:'center'}}>
            <Box sx={{ ml: "12px", mt:'24px', mb:'24px', mr:'18px' }}>
              <>
                {userStore.currentUser?.avatar ? (
                  <img
                    src={userStore.currentUser?.avatar}
                    alt="avatar"
                    style={{
                      width: "96px",
                      height: "96px",
                      borderRadius: "64px",
                    }}
                  />
                ) : (
                  <img src={userStore.currentUser.getBlankProfile()} alt="avatar" />
                )}
              </>
            </Box>
            <Box display={"flex"} flexDirection={"column"}>
            <AutocompleteStyled
              label="Search"
              onChange={(event: any, value: any) => {
                setSearchVal(value);
              }}
              options={[]}
              onClick={() => {}}
              width="280px"
              backgroundColor={styleStore.getInputBackgroundPrimary()}
              css={{border:styleStore.getInputBorder()}}
            />
            </Box>
          </Box>
        </BoxStyled>

      </Box>
      <BoxStyled css={{ width: "1093px", marginLeft: "24px", marginTop:'28px', backgroundColor:styleStore.getCardBackgroundColor() }}>
        <Box sx={{marginLeft:'24px', marginTop:'24px'}}>
          <Box display={"flex"} flexDirection={"column"} sx={{alignItems:'start', color:styleStore.getQcerrisTextSecondary()}}>
           Reason for requesting performance review
       
          <Box sx={{mt:'16px'}}/>
          <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="Good performance"
            name="radio-buttons-group"
          >
            <FormControlLabel
          value="Good performance"
          className={classes.smallRadioButton}
          control={<Radio sx={{color:styleStore.getTextColorPrimary()}}/>}
          label="Good performance"
        />
        <FormControlLabel
          value="Under performance"
          className={classes.smallRadioButton}
          control={<Radio sx={{color:styleStore.getTextColorPrimary()}}/>}
          label="Under performance"
        />
        <FormControlLabel
          value="Bump up on client"
         className={classes.smallRadioButton}
          control={<Radio sx={{color:styleStore.getTextColorPrimary(), 
            }}/>}
          label="Bump up on client"
        />
         <FormControlLabel
          value="Anual performance review"
          className={classes.smallRadioButton}
          control={<Radio sx={{color:styleStore.getTextColorPrimary()}}/>}
          label="Anual performance review"
        />
         <FormControlLabel
          value="Other"
          className={classes.smallRadioButton}
          control={<Radio sx={{color:styleStore.getTextColorPrimary()}}/>}
          label="Other"
        />
          </RadioGroup>
          </FormControl>
          <InputFieldOutlined
                  css={{ width: "1045px", mb: "24px", mt:'24px', backgroundColor:styleStore.getCardBackgroundColor()}}
                  name={"additional_info"}
                  value={additionalInfo}
                  placeholder='Additional info...'
                  onChange={(e: any) => {
                    setAdditionalInfo(
                      e.target.value,
                    );
                  }}
                />            
          </Box>
          </Box>
        </BoxStyled>

        <PrimaryButton
                    width={"150px"}
                    sx={{ mt: "20px", ml:'24px'}}
                    onClick={() => {
                     
                    }}
                    text={"SEND REQUEST"}
                  />
      </Box>
    )
 })
 
 export default Desktop;