import { Box, IconButton, TableCell, TableRow, styled, tableCellClasses } from "@mui/material";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../Helpers/MobX/hooks";
import { createUserStore } from "../Helpers/MobX/PageStores.tsx/CreateUserStore";
import Pill from "../Components/Pill";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { ReactComponent as FormIcon } from '../Assets/img/Icons/FormIcon.svg';
import { ReactComponent as EyeIcon } from '../Assets/img/Icons/eye.svg';
import { ReactComponent as DownloadIcon } from '../Assets/img/Icons/Download.svg';


interface ITravelRequestElement {
  reason: string;
  requesting:string;
  requestDate:string;
  endDate:string,
  status:string,
}

const PerformanceReviewEntry = observer(({ reason,
    requesting,
    requestDate,
    endDate,
    status}:ITravelRequestElement ) => {

    const {styleStore} = useStores();

      const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
          backgroundColor: styleStore.getCardBackgroundColor(),
          color: styleStore.getTextColorSecondary(),
          fontSize: 14,
        },
      }));

    return (
        <TableRow>
            
            <StyledTableCell >
              <Box sx={{display:'flex', justifyContent:'start', alignItems:'center'}}>
              {createUserStore.getProfileImgUrl() ? (
                <>
                  <img
                    src={createUserStore.getProfileImgUrl()}
                    style={{
                      width: "32px",
                      height: "32px",
                      borderRadius: "64px",
                    }}
                    alt=""
                  />
                </>
              ) : (
                <>
                  <img src={styleStore.getBlankProfile()} style={{
                      width: "32px",
                      height: "32px",
                      borderRadius: "64px",
                    }} alt="blank" />
                </>
              )}  
              
              <Box sx={{ml:'8px'}}>Requester Name</Box>
              </Box>
              
            </StyledTableCell>
            <StyledTableCell >{reason}</StyledTableCell>
            <StyledTableCell style={{paddingLeft:'40px'}}>{requestDate}</StyledTableCell>
            
            <StyledTableCell style={{paddingLeft:status==='Completed'? '15px' : status==='Pending'? '30px' : '30px'}}><Pill label={status==="Completed"?'Completed': status==='Pending'? 'Pending' : 'Declined'} pillType={status==="Completed"?'green':status==="Pending"?'yellow': 'red'}/></StyledTableCell>
            <StyledTableCell style={{paddingLeft:'40px'}}>{endDate}</StyledTableCell>
            <StyledTableCell ><IconButton
               
               >
                 <EyeIcon />
               </IconButton></StyledTableCell>
               <StyledTableCell >
            <IconButton
              >
                <DownloadIcon/>
              </IconButton>
            </StyledTableCell>
            <StyledTableCell ><IconButton
               
               >
                 <FormIcon />
               </IconButton></StyledTableCell>
               
          </TableRow>
    )
 })
 
 export default PerformanceReviewEntry;