import { makeAutoObservable, action } from "mobx";
import { makePersistable, hydrateStore } from "mobx-persist-store";

export class TittleStore {
  tittle: string;
  constructor() {
    this.tittle = "This is where a dev will enter a tittle";
    makeAutoObservable(this);
    makePersistable(this, {
      name: "tittleStore",
      properties: [
        {
          key: "tittle",
          serialize: (value) => {
            return value;
          },
          deserialize: (value) => {
            return value;
          },
        },
      ],
      storage: window.localStorage,
    });
  }
  @action setTittle(tittle: string) {
    this.tittle = tittle;
  }
  @action getTittle() {
    return this.tittle;
  }
  rehydrate() {
    hydrateStore(this);
  }
}

export const tittleStore = new TittleStore();
