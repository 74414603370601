import Desktop from "./Desktop";

const Input = (params: any) => {
  return (
    <Desktop
      disabled={params.disabled}
      name={params.name}
      setUser={params.setUser}
      user={params.user}
    />
  );
};

export default Input;
