import { styleStore } from "../../Helpers/MobX/StyleStore";
import { observer } from "mobx-react-lite";
import { TextField } from "@mui/material";
import internal from "stream";

interface IInputField {
  css: any;
  name: string;
  defaultValue?: string;
  onChange?: any;
  disabled?: boolean;
  value?: string;
  multiline?:boolean;
  lines?:number;
  placeholder?:string;
}

const InputFieldStyled = observer(
  ({
    css,
    name,
    defaultValue = "",
    onChange,
    disabled,
    value,
    lines = 1,
    multiline = false,
    placeholder = ""
  }: IInputField) => {
    return onChange ? (
      <TextField
        onChange={onChange}
        disabled={disabled}
        value={value}
        variant="outlined"
        multiline={multiline}
        rows = {lines}
        placeholder={placeholder}
        InputLabelProps={{ shrink: false }}
        inputProps={{ style: { color: styleStore.getQcerrisTextInputs(),} }}
        sx={{
          ...css,
          color: "red",
          input: {
            color: styleStore.getQcerrisTextInputs(),
          },
          "& label.Mui-focused": {
            color: "blue",
          },
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: styleStore.getTextColorPrimary(),
          },
          "& .MuiOutlinedInput-root": {
            bgcolor: styleStore.getInputBackgroundPrimary(),
            height: `calc(40px * ${lines})`,
            borderRadius: "15px",
            "& fieldset": {
              border: styleStore.getInputBorder(),
              borderRadius: "15px",
            },
            "&:hover fieldset": {
              border: styleStore.getInputBorder(),
              borderRadius: "15px",
            },
            "&.Mui-focused fieldset": {
              border: styleStore.getInputBorder(),
              borderRadius: "15px",
            },
          },
        }}
        defaultValue={defaultValue}
      />
    ) : (
      <TextField
        disabled={disabled}
        variant="outlined"
        value={value}
        multiline={multiline}
        rows = {lines}
        inputProps={{ style: { color: styleStore.getQcerrisTextInputs() } }}        
        onChange={onChange}
        defaultValue={defaultValue}
        label={placeholder}
        sx={{
          ...css,
          color: "red",
          input: {
            color: styleStore.getQcerrisTextInputs(),
          },
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: styleStore.getTextColorPrimary(),
          },
          "& label.Mui-focused": {
            color: "blue",
          },
          "& .MuiOutlinedInput-root": {
            bgcolor: styleStore.getInputBackgroundPrimary(),
            height: "40px",
            borderRadius: "15px",
            "& fieldset": {
              border: styleStore.getInputBorder(),
              borderRadius: "15px",
            },
            "&:hover fieldset": {
              border: styleStore.getInputBorder(),
              borderRadius: "15px",
            },
            "&.Mui-focused fieldset": {
              border: styleStore.getInputBorder(),
              borderRadius: "15px",
            },
          },
        }}
      />
    );
  }
);

export default InputFieldStyled;
