import * as React from "react";
import { useEffect, useState } from "react";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import LogoutIcon from "@mui/icons-material/Logout";
import { SetStyle } from "../../Helpers/CookieHelper";
import { Avatar, ListItemIcon, MenuItem } from "@mui/material";
import { AvatarIcon } from "./AvatarIcon";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

import { useStores } from "../../Helpers/MobX/hooks";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

let AvatarChange: any;

const AvatarWrapper = observer(() => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [iconView, setIconView] = useState<any>();
  //TODO: Avatar change takes avatar from userStore, not like this
  AvatarChange = setIconView;
  const navigate = useNavigate();
  const { userStore } = useStores();
  const { styleStore } = useStores();
  styleStore.rehydrate();
  let is_dark = !styleStore.getLightMode();
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  let currentUser = userStore.getCurrentUser();
  var urlProfile = `/employee/${currentUser?.id}`;

  const logout = () => {
    navigate("/logout");
  };

  const redirectToProfile = () => {
    navigate(urlProfile);
  };

  useEffect(() => {
    var base64regex =
      /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

    if (currentUser?.avatar_64) {
      if (base64regex.test(currentUser?.avatar_64)) {
        setIconView("data:image/jpeg;base64," + currentUser?.avatar_64);
      } else {
        setIconView(currentUser?.avatar_64);
      }
    }
  }, [currentUser?.avatar_64]);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Tooltip title="Account settings" arrow>
          <IconButton
            onClick={(event) => setAnchorEl(event.currentTarget)}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <AvatarIcon
              src={iconView}
              firstName={currentUser?.user?.first_name}
              lastName={currentUser?.user?.last_name}
            />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        disableScrollLock={true}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: `drop-shadow(0px 2px 8px ${styleStore.getBorder()})`,
            mt: 1.5,
            backgroundColor: styleStore.getBackgroundPrimary(),
            color: styleStore.getForegroundPrimary(),
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 20,
              width: 10,
              height: 10,
              bgcolor: styleStore.getBackgroundPrimary(),
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>
          <FormControlLabel
            control={
              <Switch
                key={`switch-${is_dark}`}
                checked={is_dark}
                onClick={() => {
                  styleStore.toggleLightMode();
                  SetStyle(styleStore.light_mode);
                }}
              />
            }
            label="DarkMode"
          />
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            redirectToProfile();
          }}
        >
          <Avatar /> Profile
        </MenuItem>

        <Divider sx={{ borderColor: styleStore.getBorder() }} />
        <MenuItem
          onClick={() => {
            handleClose();
            logout();
          }}
        >
          <ListItemIcon>
            <LogoutIcon sx={{ color: styleStore.getForegroundPrimary() }} />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
});

export default AvatarWrapper;
