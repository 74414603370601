import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import Section from '../PerformanceReviewNewForm/components/Section';
import Subsection from '../PerformanceReviewNewForm/components/Subsection';
import { observer } from 'mobx-react-lite';
import TerciaryButton from '../../Components/Button/TerciaryButton';
import { Box } from '@mui/material';
import SecondaryButton from "../../Components/Button/SecondaryButton";
import PrimaryButton from "../../Components/Button/PrimaryButton";
import { useNavigate } from 'react-router-dom';
import { styleStore } from '../../Helpers/MobX/StyleStore';

const columns = 
  [
  {
    id: 'column1',
    title: 'Column 1',
    cards: ['Item 1', 'Item 2'],
  },
  {
    id: 'column2',
    title: 'Column 2',
    cards: ['Item 3', 'Item 4'],
  },
 ]
;
interface ISubsection {
  questions: Array<string>
}
interface ISection {
  subsection: Array<ISubsection>
}

const Desktop = observer(() => {
  const [sections, setSections] = useState<Array<ISection>>([])
  const [subsectionsColapse, setSubsectionsColapse] = useState<boolean>(false);
  const [sectionsColapse, setSectionsColapse] = useState<boolean>(false);
  const navigate = useNavigate();
  function handleOnDragEnd(result:any, columns:any) {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.cards];
      const destItems = [...destColumn.cards];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      columns[source.droppableId].cards = sourceItems;
      columns[destination.droppableId].cards = destItems;
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.cards];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      columns[source.droppableId].cards = copiedItems;
    }
  }

  const removeSection = (index:number) => {
    return function () {
    const temp = [...sections];
    temp.pop();
    setSections(temp);
    }
  }
  const removeSubsection = (index:number) => {
    return function () {
    const temp = [...sections];
    temp[index].subsection.pop();
    setSections(temp);
    }
  };

  return (
    <div style={{marginTop:'88px'}}>
    <DragDropContext onDragEnd={(result) => {
      if(result.draggableId.substring(0, 10) === 'subsection')
      {
        setSubsectionsColapse(false);
      }
      else 
      if(result.draggableId.substring(0, 7) === 'section')
        {
          setSectionsColapse(false);
        }
    
    }} onDragStart={(result)=>{
      if(result.draggableId.substring(0, 10) === 'subsection')
      {
        setSubsectionsColapse(true);
      }
      else 
      if(result.draggableId.substring(0, 7) === 'section')
        {
          setSectionsColapse(true);
        }
    }}>
      <Droppable droppableId={'board'} key={'board'}>
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
        {Object.values(sections).map((column, index) => (
            <Draggable key={`section-${index}`} draggableId={`section-${index}`} index={index}>
            {(sectionProvided, snapshot) => (
              <div
                ref={sectionProvided.innerRef}
                {...sectionProvided.draggableProps}
                
              >
          <Droppable droppableId={`section-${index}`} key={`section-${index}`}>
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <Section provided={sectionProvided} removeSection={removeSection(index)}/>
                {!sectionsColapse ? (
                !snapshot.isDragging && column.subsection.map((item:any, subIndex:number) => (
                  <Draggable key={`subsection=${index}-${subIndex}`} draggableId={`subsection=${index}-${subIndex}`} index={subIndex}>
                    {(subsectionProvided, subsectionSnapshot) => (
                      <div
                        ref={subsectionProvided.innerRef}
                        {...subsectionProvided.draggableProps}
                      >
                        <Subsection provided={subsectionProvided} removeSubsection={removeSubsection(index)} id={`subsection=${index}-${subIndex}`} subsectionsColapse={subsectionsColapse}/>
                        
                      </div>
                    )}
                  </Draggable>
                ))):<></>}
                <Box sx={{display:'flex', justifyContent:'left'}}>
                        <TerciaryButton onClick={() => {
                          let temp = [...sections];
                          temp[index].subsection.push({
                            questions:[]
                          },);
                          setSections(temp);
                        }} css={{color:styleStore.getActiveText(), fontSize:'14px', width:'150px', fontWeight:'regular', marginLeft:'20px', marginTop:'15px'}}>          
                        + add subsection
                        </TerciaryButton>
                        </Box>
                {provided.placeholder}
              </div>
            )}
          </Droppable> 
          </div>)}
          </Draggable>
        ))}
        {provided.placeholder}
      </div>)}
      </Droppable>
      
    </DragDropContext>
    <Box sx={{display:'flex', flexDirection:'row', mt:'48px', ml:'24px', mb:'24px'}}>
    <TerciaryButton onClick={() => {navigate('/performance-review-template')}} css={{marginRight:'24px'}}>
    CANCEL
    </TerciaryButton>
      <SecondaryButton
                  width={"152px"}
                  onClick={() => {
                    let temp = [...sections]
                    temp.push({subsection:[]})
                    setSections(temp);
                  }}
                  text={"ADD SECTION"}
                />

      <PrimaryButton
                  width={"186px"}
                  sx={{ml:'24px'}}
                  onClick={() => {
                  
                  }}
                  text={"FINISH TEMPLATE"}
                />
    </Box>
    </div>
  );
})

export default Desktop;