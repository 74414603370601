import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Tooltip} from "@mui/material";
import P from "../../Assets/Fonts/P";
import BoxStyled from "../../Components/BoxStyled";
import { useState } from "react";
import Link from "../../Assets/Fonts/Link";
import { useStores } from "../../Helpers/MobX/hooks";
import { observer } from "mobx-react-lite";
import AutocompleteStyled from "../../Components/AutoComplete/AutocompleteStyled";
import PrimaryButton from "../../Components/Button/PrimaryButton";
import SecondaryButton from "../../Components/Button/SecondaryButton";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import InputFieldOutlined from "../../Components/Textbox/InputFieldOutlined";

const useStyles = makeStyles(theme =>
  createStyles({
    smallRadioButton: {
      "& svg": {
        width: "10px",
        height: "10px"
      }
    }
  })
);


const Desktop = observer(() => {

      const { styleStore } = useStores();
      
    return (
      <Box display={'flex'} flexDirection={'column'}>
        <BoxStyled css={{ width: "1619px", marginLeft: "24px" }}>
      
            <Box display={"flex"} flexDirection={"column"}>
                        <P
                          text={
                           'Performance review - Good performance'
                          }
                          css={{
                            color: styleStore.getSecondaryButtonTextColor(),
                            marginLeft:'24px',
                            marginTop:'11px',
                            fontSize:'24px'
                          }}
                        />
                <Link
                  text={"Everybody has areas for development. Improvement is a lifelong process. Make sure those areas can be mapped to the values and the mission. Not easy to look at yourself honestly but it is extremely useful exercise. These areas not only include technical knowledge, but also the skills you want to improve. (i.e. Communication skills, stress management, work-life balance, problem solving, learning new things, etc.) Not easy to look at yourself honestly but it is extremely useful exercise. These areas not only include technical knowledge, but also the skills you want to improve. (i.e. Communication skills, stress management, work-life balance, problem solving, learning new things, etc.)"}
                  css={{
                    color: styleStore.getTextColorSecondary(),
                    marginLeft: '24px',
                    marginTop:'27px',
                    marginRight:'30px',
                    marginBottom:'13px',
                    fontSize:'14px'
                  }}
                />

            </Box>
        </BoxStyled>
        <Box display={'flex'} flexDirection={'row'} sx={{ml:'24px', mt:'24px'}}>
        <SecondaryButton
                    width={"180px"}
                    onClick={() => {
                     
                    }}
                    text={"CONTINUE LATER"}
                  />
        <PrimaryButton
                    width={"170px"}
                    sx={{ml:'24px'}}
                    onClick={() => {
                     
                    }}
                    text={"SUBMIT REVIEW"}
                  />
                  </Box>
      </Box>
    )
 })
 
 export default Desktop;