import { styleStore } from "../../../Helpers/MobX/StyleStore";
import { Apis } from "../../../Helpers/ApiHelper/ApiFactory";
import GetScreenType from "../../../Helpers/ScreenHelper";
import { useStores } from "../../../Helpers/MobX/hooks";
import Loader from "../../../Components/Loader/Loader";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import Desktop from "./Desktop";
import Swal from "sweetalert2";
import Mobile from "./Mobile";
import Laptop from "./Laptop";
import { toJS } from "mobx";

const Documents = [
  "ID card",
  "Employment Contract",
  "Certificate of employment",
  "Degree of vocational education",
  "Highest certificate/diploma obtained",
  "Certificate of impunity",
  "Annex to the contract",
  "Notice of Annex to the contract",
  "Decision on annual leave",
  "NDA",
  "Equipment check-out form",
  "Bank documents",
  "Notice on processing employees’ personal data",
  "Protection of whistleblowers document",
  "Notice on prevention of harassment at work",
  "Benefits",
  "Decision of business trip",
  "Business trip order",
];

const CreateUser = observer(() => {
  const { tittleStore } = useStores();
  tittleStore.setTittle("Create User");
  const isDesktop = GetScreenType().isDesktop;
  const isLaptop = GetScreenType().isLaptop;
  const { createUserStore } = useStores();
  const [loading, setLoading] = useState<boolean>(true);

  const toastContainerStyle = document.createElement("style");
  toastContainerStyle.innerHTML = `
  .custom-toast-container {
    border-radius: 15px;
    padding: 24px;
    margin-right: 200px; 
  }
  .custom-toast-popup {
    border-radius: 15px;
  }
  `;
  document.head.appendChild(toastContainerStyle);

  const Toast = Swal.mixin({
    toast: true,
    showConfirmButton: false,
    timer: 2500,
    background: styleStore.getBackgroundPrimary(),
    position: "bottom-end",
    customClass: {
      container: "custom-toast-container",
      popup: "custom-toast-popup",
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [positions, seniority, teams, clients, users] = await Promise.all([
          Apis.getPosition(),
          Apis.getSeniority(),
          Apis.getTeams(),
          Apis.getClients(),
          Apis.getUsers(),
        ]);

        createUserStore.setPositions(positions?.map((item: any) => item));
        createUserStore.setSeniority(seniority?.map((item: any) => item));
        createUserStore.setTeams(teams?.map((item: any) => item));
        createUserStore.setClients(clients?.map((item: any) => item));

        const supervisors = users.data
          .filter((e: any) => e.is_supervisor === true)
          .map((item: any) => item.user.first_name + " " + item.user.last_name);

        const teamLeads = users.data
          .filter((e: any) => e.is_team_lead === true)
          .map((item: any) => item.user.first_name + " " + item.user.last_name);

        createUserStore.setSupervisors(supervisors);
        createUserStore.setTeamleads(teamLeads);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [createUserStore]);

  const Functions = {
    CreateUser: function (test: string) {
      Apis.setUser(createUserStore.getFormData()).then((res: any) => {
        if (res.status === 400 || res.status === 500) {
          Toast.fire({
            title: `Error! User not created!`,
            color: "#EB6C78",
            icon: "error",
          });
        } else if (res.status === 201) {
          Toast.fire({
            title: `User created successfully!`,
            color: "#70C773",
            icon: "success",
          });
        }
      });
    },
  };

  console.log(toJS(createUserStore.getFormData()));
  return loading ? (
    <Loader />
  ) : isDesktop ? (
    <Desktop Documents={Documents} Functions={Functions} />
  ) : isLaptop ? (
    <Laptop />
  ) : (
    <Mobile />
  );
});

export default CreateUser;
