import { Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, tableCellClasses } from "@mui/material";
import Desc from "../../Assets/Fonts/Desc";
import P from "../../Assets/Fonts/P";
import BoxStyled from "../../Components/BoxStyled";
import InputFieldStyled from "../../Components/Textbox/InputFieldStyled";
import PrimaryButton from "../../Components/Button/PrimaryButton";
import { useState } from "react";
import DatePickerStyled from "../../Components/DatePicker";
import dayjs from "dayjs";
import AutocompleteStyled from "../../Components/AutoComplete/AutocompleteStyled";
import { observer } from "mobx-react-lite";
import { useStores } from "../../Helpers/MobX/hooks";
import PerformanceReviewEntry from "./components/PerformanceReviewEntry";



interface ITravelRequest {
  tripTitle: string;
  employees:string;
  location:string;
  start_date:string,
  end_date:string,
  reason:string;
  additionalInfo:string;
}



const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Desktop = observer(() => {

    const {styleStore} = useStores();
      const [searchVal, setSearchVal] = useState<string>("");

      const StyledTableCell = observer(styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: styleStore.getCardBackgroundColor(),
          color: styleStore.getTextColorSecondary(),
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
        [`&.${tableCellClasses.root}`]: {
          borderColor: styleStore.light_mode? "#E0E0E0": '#7C7C7C',
        },
      })));

      const StyledCenteredTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: styleStore.getBackgroundColor(),
          color: styleStore.getTextColorSecondary(),
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
    return (
        <Box sx={{ display: "flex", pl: "24px", flexDirection:'column' }}>
          <Box sx={{mt:'30px'}}>
            <AutocompleteStyled
              label="Search"
              onChange={(event: any, value: any) => {
                setSearchVal(value);
              }}
              options={[]}
              onClick={() => {}}
              width="360px"
            />
            </Box>
            <Box>
            <TableContainer sx ={{ mt:'26px',  width: '1622px', borderRadius:'15px 15px 0 0'}}>
      <Table sx={{ width: '1622px'}} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{pl:'40px'}}>Requested for</StyledTableCell>
            <StyledTableCell >
            </StyledTableCell>
            <StyledTableCell >
            </StyledTableCell>
            <StyledTableCell >Requested by</StyledTableCell>
            <StyledTableCell >
            </StyledTableCell>
            <StyledTableCell >
            </StyledTableCell>
            <StyledTableCell >Requested date</StyledTableCell>
            <StyledTableCell >
            </StyledTableCell>
            <StyledTableCell >
            </StyledTableCell>
            <StyledTableCell >Preview</StyledTableCell>
            <StyledTableCell >
            </StyledTableCell>
            <StyledTableCell >
            </StyledTableCell>
            <StyledTableCell >Download</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
         <PerformanceReviewEntry name='Anja Obradovic'  dateOfRequest='12/31/2023' requester='' color = {styleStore.light_mode? "#ebccd3" : '#7b3d41'}/>
         <PerformanceReviewEntry name='Anja Obradovic' dateOfRequest='12/31/2023' requester='' color = {styleStore.light_mode? "#ebccd3" : '#7b3d41'}/>
         <PerformanceReviewEntry name='Anja Obradovic'  dateOfRequest='12/31/2023' requester='' color = ''/>
         <PerformanceReviewEntry name='Anja Obradovic'dateOfRequest='12/31/2023' requester='' color = {styleStore.light_mode? "#ebccd3" : '#7b3d41'}/>
        </TableBody>
      </Table>
    </TableContainer>
            </Box>
        </Box>
    )
 })
 
 export default Desktop;