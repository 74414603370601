import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useStores } from "../../Helpers/MobX/hooks";
import LoaderImage from "../../Assets/LoaderImage.png";
import { Circle } from '@mui/icons-material';

const LoaderPC = () => {
    const {styleStore} = useStores();
  return (
    <Box sx={{display:'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center', alignContent: 'center', mt:"25%"}}>
      <img src={LoaderImage} style={{animation:'App-logo-spin infinite 0.5s linear', animationDirection:'reverse',width:'100px', height:'100px'}} />
    </Box>
  );
}

export default LoaderPC;