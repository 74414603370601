import { Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, tableCellClasses } from "@mui/material";
import Desc from "../../../Assets/Fonts/Desc";
import P from "../../../Assets/Fonts/P";
import BoxStyled from "../../../Components/BoxStyled";
import InputFieldStyled from "../../../Components/Textbox/InputFieldStyled";
import PrimaryButton from "../../../Components/Button/PrimaryButton";
import { useState } from "react";
import DatePickerStyled from "../../../Components/DatePicker";
import dayjs from "dayjs";
import AutocompleteStyled from "../../../Components/AutoComplete/AutocompleteStyled";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../Helpers/MobX/hooks";
import TravelRequestEntry from "../../../Components/TravelRequestEntry";
import PerformanceReviewEmployeeEntry from "./PerformanceReviewEmployeeEntry";
import PerformanceReviewEntryControls from "./PerformanceReviewEntryControls";



interface ITravelRequest {
  tripTitle: string;
  employees:string;
  location:string;
  start_date:string,
  end_date:string,
  reason:string;
  additionalInfo:string;
}



const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const PerformanceReviewEmployeeTable = observer(() => {

    const {styleStore} = useStores();
      const [searchVal, setSearchVal] = useState<string>("");

      const StyledTableCell = observer(styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: styleStore.getCardBackgroundColor(),
          color: styleStore.getTextColorSecondary(),
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      })))

      const StyledCenteredTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: styleStore.getBackgroundColor(),
          color: styleStore.getTextColorSecondary(),
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));

      
    return (
       
            <TableContainer sx ={{ width: '1622px', borderRadius:'15px 15px 0 0'}}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell > <Box sx={{ml:'20px'}}>Filing the form</Box></StyledTableCell>
            <StyledTableCell ><Box sx={{ml:'70px'}}>Type of form</Box></StyledTableCell>
            <StyledTableCell style={{paddingLeft:'70px'}}><Box sx={{ml:'20px'}}>Request date</Box></StyledTableCell>
            <StyledTableCell >Action</StyledTableCell>
            <StyledTableCell ></StyledTableCell>
            <StyledTableCell ></StyledTableCell>
            <StyledTableCell ></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
         <PerformanceReviewEmployeeEntry name='TestName' requesting= 'test' departing= '01/01/2024' dateOfRequest='12/31/2023' status='Completed'/>
         <PerformanceReviewEntryControls name='TestName' requesting= 'test' departing= '01/01/2024' dateOfRequest='12/31/2023' status='Completed'/>
        </TableBody>
      </Table>
    </TableContainer>
    )
 })
 
 export default PerformanceReviewEmployeeTable;