import * as React from "react";
import Navbar from "./Navbar/Navbar";
import Sidebar from "./Sidebar/Sidebar";
import Box from "@mui/material/Box";
import { observer } from "mobx-react-lite";
import { useStores } from "../../Helpers/MobX/hooks";

type MyComponentProps = React.PropsWithChildren<{}>;
const PageWrapper = observer(({ children }: MyComponentProps) => {
  const { sidebarStore } = useStores();
  const { styleStore } = useStores();
  let margin = sidebarStore.open ? "250px" : "100px";
  document.body.style.backgroundColor = styleStore.getBackgroundPrimary();
  document.body.style.color = styleStore.getForegroundPrimary();
  return (
    <Box>
      <Navbar />
      <Sidebar>
        <Box sx={{ mt: "64px", ml: margin }}>{children}</Box>
      </Sidebar>
    </Box>
  );
});
export default PageWrapper;
