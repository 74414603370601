import { Box, Grid, InputAdornment, IconButton, Button } from "@mui/material";
import AutoCompleteStyled1 from "./AutocompleteStyledSecondary";
import InputFieldStyled from "./Textbox/InputFieldStyled";
import { styleStore } from "../Helpers/MobX/StyleStore";
import ClearIcon from "@mui/icons-material/Clear";
import { useStores } from "../Helpers/MobX/hooks";
import InputEdit from "../Components/Input/InputEdit";
import DatePickerStyled from "./DatePicker";
import { observer } from "mobx-react-lite";
import Desc from "../Assets/Fonts/Desc";
import BoxStyled from "./BoxStyled";
import P from "../Assets/Fonts/P";
import CheckBox from "./CheckBox";
import { useEffect, useState } from "react";
import { toJS } from "mobx";
import dayjs from "dayjs";
import ModalStyled from "./ModalStyled";
import PrimaryButton from "./Button/PrimaryButton";
import SecondaryButton from "./Button/SecondaryButton";
import { Apis } from "../Helpers/ApiHelper/ApiFactory";

interface IUserForm {
  disabled: boolean;
  user: any;
  setUser: any;
}
const UserForm = observer(({ disabled, user, setUser }: IUserForm) => {
  const { createUserStore } = useStores();
  const [val, setVal] = useState([]);
  const [number, setNumber] = useState(3);
  const [oldNumber, setOldNumber] = useState([2]);
  const [openTeamModal, setOpenTeamModal] = useState(false);
  const [openPositionModal, setOpenPositionModal] = useState(false);
  const [openSeniorityModal, setOpenSeniorityModal] = useState(false);
  const [openClientModal, setOpenClientModal] = useState(false);
  const [team, setTeam] = useState(user?.team[0]?.name);
  const [mainClient, setMainClient] = useState(user?.client?.name);
  const [position, setPosition] = useState(user?.position?.name);
  const [seniority, setSeniority] = useState(user?.seniority);

  const handleAdd = () => {
    const abc: any = [...val, []];
    setVal(abc);
    setNumber((prev) => prev + 1);
    setOldNumber((prev) => [...prev, number]);
  };
  const handleRemove = () => {
    setVal(val.slice(0, val.length - 1));
    createUserStore.getFormData().other_clients.pop();
  };

  const roles = ["Super HR", "HR", "Client", "Regular user"];

  const padding = {
    pl: "16px",
    pb: "8px",
    pt: "16px",
  };
  const paddingTopSide = {
    pl: "16px",
    pb: "8px",
  };

  const paddingTitle = {
    pl: "16px",
    pt: "16px",
    pb: "24px",
  };

  useEffect(() => {
    let genderHolder: string = "";
    if (user.gender === "Male") genderHolder = "MA";
    else if (user.gender === "Female") genderHolder = "FE";
    else genderHolder = "OT";
    setUser({
      ...user,
      gender: genderHolder,
    });
  }, []);
  const transformGender = (): string => {
    if (user.gender === "MA" || user.gender === "Male") return "Male";
    else if (user.gender === "FE" || user.gender === "Female") return "Female";
    else return "Other";
  };
  return (
    <Box sx={{ display: "flex", pl: "24px" }}>
      <Box className="left-box">
        <Grid item container direction="column" xs={12} sm={4} spacing={"24px"}>
          <Grid item>
            <BoxStyled>
              <P text={"User information"} css={{ ...paddingTitle }} />
              <Desc text={"First Name *"} css={{ ...paddingTopSide }} />
              <InputFieldStyled
                css={{ width: "356px" }}
                name={"first_name"}
                disabled={disabled}
                value={user?.user?.first_name}
                onChange={(e: any) => {
                  let temp = { ...user };
                  temp.user.first_name = e.target.value;
                  setUser(temp);
                }}
              />
              <Desc text={"Last Name *"} css={{ ...padding }} />
              <InputFieldStyled
                css={{ width: "356px" }}
                name={"last_name"}
                disabled={disabled}
                value={user?.user?.last_name}
                onChange={(e: any) => {
                  let temp = { ...user };
                  temp.user.last_name = e.target.value;
                  setUser(temp);
                }}
              />
              <Grid container>
                <Grid item xs={6}>
                  <Desc text={"Date of Birth"} css={{ ...padding }} />
                  <DatePickerStyled
                    css={{ width: "170px", ml: "10px" }}
                    name={"date_of_birth"}
                    disabled={disabled}
                    defaultValue={dayjs(user.date_of_birth)}
                    value={dayjs(user.date_of_birth)}
                    onChange={(e: any) => {
                      let temp = { ...user };
                      temp.date_of_birth = e?.format("YYYY-MM-DD");
                      setUser(temp);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Desc
                    text={"Gender"}
                    css={{ pb: "8px", pt: "16px", pl: "8px" }}
                  />
                  {disabled ? (
                    <InputFieldStyled
                      css={{
                        width: "170px",
                        height: "40px",
                      }}
                      name={"gender"}
                      disabled={disabled}
                      value={transformGender()}
                    />
                  ) : (
                    <AutoCompleteStyled1
                      css={{ width: "170px", ml: "8px" }}
                      options={["Male", "Female", "Other"]}
                      name={"gender"}
                      defaultValue={transformGender()}
                      onChange={(e: any, value: any) => {
                        let genderHolder: string = "";
                        if (value === "Male") genderHolder = "MA";
                        else if (value === "Female") genderHolder = "FE";
                        else genderHolder = "OT";
                        setUser({
                          ...user,
                          gender: genderHolder,
                        });
                      }}
                    />
                  )}
                </Grid>
              </Grid>
              <Desc text={"User role"} css={{ ...padding }} />
              {disabled ? (
                <InputFieldStyled
                  css={{
                    width: "356px",
                    height: "40px",
                  }}
                  name={"role"}
                  disabled={disabled}
                  value={user?.role}
                />
              ) : (
                <AutoCompleteStyled1
                  css={{ width: "356px", pl: "16px" }}
                  options={roles}
                  name={"role"}
                  defaultValue={user.role}
                  onChange={(e: any, value: any) => {
                    setUser({
                      ...user,
                      role: value,
                    });
                  }}
                />
              )}
              <Desc text={"Bank Account"} css={{ ...padding }} />
              <InputFieldStyled
                css={{ width: "356px", mb: "24px" }}
                name={"bank_account"}
                disabled={disabled}
                value={user?.bank_account}
                onChange={(e: any) => {
                  setUser({
                    ...user,
                    bank_account: e.target.value,
                  });
                }}
              />
            </BoxStyled>
          </Grid>
        </Grid>
      </Box>

      <Box className="middle-left-box" sx={{ pl: "24px" }}>
        <Grid item container direction="column" xs={12} sm={4} spacing={"24px"}>
          <Grid item>
            <BoxStyled>
              <P text={"User Profile"} css={{ ...paddingTitle }} />
              <Grid container>
                <Grid item>
                  <Box
                    sx={{
                      width: "96px",
                      height: "96px",
                      pl: "14px",
                      pb: "21px",
                    }}
                  >
                    {createUserStore.getProfileImgUrl() ? (
                      <>
                        <img
                          src={createUserStore.getProfileImgUrl()}
                          style={{
                            width: "96px",
                            height: "96px",
                            borderRadius: "64px",
                          }}
                          alt=""
                        />
                      </>
                    ) : (
                      <>
                        <img src={styleStore.getBlankProfile()} alt="blank" />
                      </>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={8}>
                  <Desc text={"Upload photo"} css={{ pl: "24px", pb: "8px" }} />
                  <InputEdit
                    name={"avatar"}
                    disabled={disabled}
                    setUser={setUser}
                    user={user}
                  />
                </Grid>
              </Grid>
            </BoxStyled>
          </Grid>
          <Grid item>
            <BoxStyled>
              <P text={"Contact information"} css={{ ...paddingTitle }} />
              <Desc text={"Company Email *"} css={{ ...paddingTopSide }} />
              <InputFieldStyled
                css={{ width: "356px" }}
                name={"email"}
                disabled={disabled}
                value={user?.user?.email}
                onChange={(e: any) => {
                  let temp = { ...user };
                  temp.user.email = e.target.value;
                  setUser(temp);
                  console.log(temp);
                }}
              />
              <Desc text={"Private Email"} css={{ ...padding }} />
              <InputFieldStyled
                css={{ width: "356px" }}
                name={"private_email"}
                disabled={disabled}
                value={user?.private_email}
                onChange={(e: any) => {
                  setUser({
                    ...user,
                    private_email: e.target.value,
                  });
                }}
              />
              <Desc text={"Address"} css={{ ...padding }} />
              <InputFieldStyled
                css={{ width: "356px" }}
                name={"address"}
                disabled={disabled}
                value={user?.address}
                onChange={(e: any) => {
                  setUser({
                    ...user,
                    address: e.target.value,
                  });
                }}
              />
              <Desc text={"Phone number"} css={{ ...padding }} />
              <InputFieldStyled
                css={{ width: "356px", mb: "24px" }}
                name={"phone_number"}
                disabled={disabled}
                value={user?.phone_number}
                onChange={(e: any) => {
                  setUser({
                    ...user,
                    phone_number: e.target.value,
                  });
                }}
              />
            </BoxStyled>
          </Grid>
        </Grid>
      </Box>

      <Box className="middle-right-box" sx={{ pl: "24px" }}>
        <Grid item container direction="column" xs={12} sm={4} spacing={"24px"}>
          <Grid item>
            <BoxStyled>
              <P text={"Emergency contact"} css={{ ...paddingTitle }} />
              <Desc
                text={"Emergency contact name and last name"}
                css={{ ...paddingTopSide }}
              />
              <InputFieldStyled
                css={{ width: "356px" }}
                name={"emergency_name"}
                disabled={disabled}
                value={user?.emergency_name}
                onChange={(e: any) => {
                  setUser({
                    ...user,
                    emergency_name: e.target.value,
                  });
                }}
              />
              <Desc text={"Emergency number"} css={{ ...padding }} />
              <InputFieldStyled
                css={{ width: "356px", mb: "24px" }}
                name={"emergency_number"}
                disabled={disabled}
                value={user?.emergency_number}
                onChange={(e: any) => {
                  setUser({
                    ...user,
                    emergency_number: e.target.value,
                  });
                }}
              />
            </BoxStyled>
          </Grid>
          <Grid item>
            <BoxStyled>
              <P text={"Employment information"} css={{ ...paddingTitle }} />
              <Grid container>
                <Grid item xs={6}>
                  <Desc
                    text={"Internship start date"}
                    css={{ ...paddingTopSide }}
                  />
                  <DatePickerStyled
                    css={{ width: "170px", ml: "10px" }}
                    name={"internship_start"}
                    disabled={disabled}
                    defaultValue={user.internship_start}
                    value={user.internship_start}
                    onChange={(e: any) => {
                      setUser({
                        ...user,
                        internship_start: e?.format("YYYY-MM-DD"),
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Desc
                    text={"Internship end date"}
                    css={{ pb: "8px", pl: "8px" }}
                  />
                  <DatePickerStyled
                    css={{ width: "170px", mr: "10px" }}
                    name={"internship_end"}
                    disabled={disabled}
                    defaultValue={user.internship_end}
                    value={dayjs(user.internship_end)}
                    onChange={(e: any) => {
                      setUser({
                        ...user,
                        internship_end: e?.format("YYYY-MM-DD"),
                      });
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6}>
                  <Desc text={"Contract start date"} css={{ ...padding }} />
                  <DatePickerStyled
                    css={{ width: "170px", ml: "10px" }}
                    name={"contract_start"}
                    disabled={disabled}
                    defaultValue={user.contract_start}
                    value={dayjs(user.contract_start)}
                    onChange={(e: any) => {
                      setUser({
                        ...user,
                        contract_start: e?.format("YYYY-MM-DD"),
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Desc
                    text={"Contract end date"}
                    css={{ pb: "8px", pt: "16px", pl: "8px" }}
                  />
                  <DatePickerStyled
                    css={{ width: "170px", mr: "10px" }}
                    name={"contract_date_end"}
                    disabled={disabled}
                    defaultValue={user.end_date}
                    value={dayjs(user.end_date)}
                    onChange={(e: any) => {
                      setUser({
                        ...user,
                        end_date: e?.format("YYYY-MM-DD"),
                      });
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6}>
                  <Desc text={"Anex start date"} css={{ ...padding }} />
                  <DatePickerStyled
                    css={{ width: "170px", ml: "10px", mb: "24px" }}
                    name={"anex_date_start"}
                    disabled={disabled}
                    defaultValue={user.anex_date_start}
                    value={dayjs(user.anex_date_start)}
                    onChange={(e: any) => {
                      setUser({
                        ...user,
                        anex_date_start: e?.format("YYYY-MM-DD"),
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </BoxStyled>
          </Grid>
        </Grid>
      </Box>
      <Box className="right-box" sx={{ pl: "24px" }}>
        <Grid item container direction="column" xs={12} sm={4} spacing={"24px"}>
          <Grid item>
            <BoxStyled>
              <P text={"Role information"} css={{ ...paddingTitle }} />
              <Desc text={"Supervisor"} css={{ ...paddingTopSide }} />
              {disabled ? (
                <InputFieldStyled
                  css={{
                    width: "356px",
                    height: "40px",
                  }}
                  name={"supervisor"}
                  disabled={disabled}
                  value={user?.supervisor || "None"}
                />
              ) : (
                <AutoCompleteStyled1
                  css={{ width: "356px", pl: "16px" }}
                  options={toJS(createUserStore.getSupervisors())}
                  name={"supervisor"}
                  defaultValue={user?.supervisor || "None"}
                  onChange={(e: any, value: any) => {
                    setUser({
                      ...user,
                      supervisor: value,
                    });
                  }}
                />
              )}
              <Desc text={"Team lead"} css={{ ...padding }} />
              {disabled ? (
                <InputFieldStyled
                  css={{
                    width: "356px",
                    height: "40px",
                  }}
                  name={"team_lead"}
                  disabled={disabled}
                  value={
                    user.team_lead
                      ? user?.team_lead?.first_name +
                      " " +
                      user?.team_lead?.last_name
                      : "None"
                  }
                />
              ) : (
                <AutoCompleteStyled1
                  css={{ width: "356px", pl: "16px" }}
                  options={toJS(createUserStore.getTeamleads())}
                  name={"team_lead"}
                  defaultValue={user?.team_lead || "None"}
                  onChange={(e: any, value: any) => {
                    setUser({
                      ...user,
                      team_lead: value,
                    });
                  }}
                />
              )}
              <Desc text={"Team"} css={{ ...padding }} />
              {disabled ? (
                <InputFieldStyled
                  css={{
                    width: "356px",
                    height: "40px",
                  }}
                  name={"team"}
                  disabled={disabled}
                  value={
                    user?.team[0]?.name ||
                    "None"
                  }
                />
              ) : (
                <AutoCompleteStyled1
                  css={{ width: "356px", pl: "16px" }}
                  options={createUserStore.getTeams().map((option: any) => {
                    return option.name;
                  })}
                  name={"team"}
                  onChange={(e: any, value: any) => {
                    setUser({
                      ...user,
                      team: [createUserStore.getTeams().find((team: any) => team.name === value)]
                    });
                  }}
                  defaultValue={
                    user?.team[0]?.name ||
                    "None"
                  }
                  button={
                    <Button
                      sx={{ mt: "10px", textTransform: "none" }}
                      onMouseDown={() => {
                        setOpenTeamModal(true);
                      }}
                    >
                      + add new team
                    </Button>
                  }
                />
              )}
              <Desc text={"Position"} css={{ ...padding }} />
              {disabled ? (
                <InputFieldStyled
                  css={{
                    width: "356px",
                    height: "40px",
                  }}
                  name={"position"}
                  disabled={disabled}
                  value={
                    user?.position?.name ||
                    "None"
                  }
                />
              ) : (
                <AutoCompleteStyled1
                  css={{ width: "356px", pl: "16px" }}
                  options={createUserStore.getPositions().map((option: any) => {
                    return option.name;
                  })}
                  name={"position"}
                  defaultValue={
                    user?.position?.name ||
                    "None"
                  }
                  onChange={(e: any, value: any) => {
                    setUser({
                      ...user,
                      position: createUserStore.getPositions().find((position: any) => position.name === value)
                    });
                  }}
                  button={
                    <Button
                      sx={{ mt: "10px", textTransform: "none" }}
                      onMouseDown={() => {
                        setOpenPositionModal(true);
                      }}
                    >
                      + add new position
                    </Button>
                  }
                />
              )}
              <Desc text={"Seniority "} css={{ ...padding }} />
              {disabled ? (
                <InputFieldStyled
                  css={{
                    width: "356px",
                    height: "40px",
                  }}
                  name={"seniority"}
                  disabled={disabled}
                  value={
                    user?.seniority?.name ||
                    "None"
                  }
                />
              ) : (
                <AutoCompleteStyled1
                  css={{ width: "356px", pl: "16px" }}
                  options={createUserStore.getSeniority().map((option: any) => {
                    return option.name;
                  })}
                  name={"seniority"}
                  defaultValue={
                    user?.seniority?.name ||
                    "None"
                  }
                  onChange={(e: any, value: any) => {
                    setUser({
                      ...user,
                      seniority: createUserStore.getSeniority().find((seniority: any) => seniority.name === value)
                    });
                  }}
                  button={
                    <Button
                      sx={{ mt: "10px", textTransform: "none" }}
                      onMouseDown={() => {
                        setOpenSeniorityModal(true);
                      }}
                    >
                      + add new seniority
                    </Button>
                  }
                />
              )}
              <ModalStyled
                open={openTeamModal}
                onClose={() => {
                  setOpenTeamModal(false);
                }}
              >
                <Box sx={{}}>
                  <BoxStyled css={{ width: "448px", height: "99px" }}>
                    <Desc text={"Team Name"} css={{ ...padding }} />
                    <InputFieldStyled
                      css={{
                        width: "416px",
                        height: "40px",
                        marginLeft: 2,
                        marginBottom: 1,
                      }}
                      name={"add_team"}
                      onChange={(event: any) => {
                        setTeam(event.target.value);
                      }}
                    />
                  </BoxStyled>
                  <Box
                    sx={{ mt: "24px", display: "flex", flexDirection: "row" }}
                  >
                    <Box sx={{ mr: "24px" }}>
                      <SecondaryButton
                        text="Cancel"
                        onClick={() => {
                          setOpenTeamModal(false);
                        }}
                      />
                    </Box>
                    <PrimaryButton
                      text={"Add"}
                      onClick={() => {
                        Apis.setTeam({
                          name: team,
                        }).then(() => {
                          setOpenTeamModal(false);
                          createUserStore.teams.push(team);
                        });
                      }}
                    />
                  </Box>
                </Box>
              </ModalStyled>
              <ModalStyled
                open={openPositionModal}
                onClose={() => {
                  setOpenPositionModal(false);
                }}
              >
                <Box sx={{}}>
                  <BoxStyled css={{ width: "448px", height: "99px" }}>
                    <Desc text={"Position Name"} css={{ ...padding }} />
                    <InputFieldStyled
                      css={{
                        width: "416px",
                        height: "40px",
                        marginLeft: 2,
                        marginBottom: 1,
                      }}
                      name={"add_position"}
                      onChange={(event: any) => {
                        setPosition(event.target.value);
                      }}
                    />
                  </BoxStyled>
                  <Box
                    sx={{ mt: "24px", display: "flex", flexDirection: "row" }}
                  >
                    <Box sx={{ mr: "24px" }}>
                      <SecondaryButton
                        text="Cancel"
                        onClick={() => {
                          setOpenPositionModal(false);
                        }}
                      />
                    </Box>
                    <PrimaryButton
                      text={"Add"}
                      onClick={() => {
                        Apis.setPosition({
                          name: position,
                        }).then(() => {
                          setOpenPositionModal(false);
                          createUserStore.positions.push(position);
                        });
                      }}
                    />
                  </Box>
                </Box>
              </ModalStyled>
              <ModalStyled
                open={openSeniorityModal}
                onClose={() => {
                  setOpenSeniorityModal(false);
                }}
              >
                <Box sx={{}}>
                  <BoxStyled css={{ width: "448px", height: "99px" }}>
                    <Desc text={"Seniority Name"} css={{ ...padding }} />
                    <InputFieldStyled
                      css={{
                        width: "416px",
                        height: "40px",
                        marginLeft: 2,
                        marginBottom: 1,
                      }}
                      name={"add_seniority"}
                      onChange={(event: any) => {
                        setSeniority(event.target.value);
                      }}
                    />
                  </BoxStyled>
                  <Box
                    sx={{ mt: "24px", display: "flex", flexDirection: "row" }}
                  >
                    <Box sx={{ mr: "24px" }}>
                      <SecondaryButton
                        text="Cancel"
                        onClick={() => {
                          setOpenSeniorityModal(false);
                        }}
                      />
                    </Box>
                    <PrimaryButton
                      text={"Add"}
                      onClick={() => {
                        Apis.setSeniority({
                          name: seniority,
                        }).then(() => {
                          setOpenSeniorityModal(false);
                          createUserStore.seniority.push(seniority);
                        });
                      }}
                    />
                  </Box>
                </Box>
              </ModalStyled>
              <ModalStyled
                open={openClientModal}
                onClose={() => {
                  setOpenClientModal(false);
                }}
              >
                <Box sx={{}}>
                  <BoxStyled css={{ width: "448px", height: "99px" }}>
                    <Desc text={"Client Name"} css={{ ...padding }} />
                    <InputFieldStyled
                      css={{
                        width: "416px",
                        height: "40px",
                        marginLeft: 2,
                        marginBottom: 1,
                      }}
                      name={"add_client"}
                      value={mainClient}
                      onChange={(event: any) => {
                        setMainClient(event.target.value);
                      }}
                    />
                  </BoxStyled>
                  <Box
                    sx={{ mt: "24px", display: "flex", flexDirection: "row" }}
                  >
                    <Box sx={{ mr: "24px" }}>
                      <SecondaryButton
                        text="Cancel"
                        onClick={() => {
                          setOpenClientModal(false);
                        }}
                      />
                    </Box>
                    <PrimaryButton
                      text={"Add"}
                      onClick={() => {
                        Apis.setMainClient({ name: mainClient }).then(() => {
                          setOpenClientModal(false);
                          createUserStore.clients.push(mainClient);
                        });
                      }}
                    />
                  </Box>
                </Box>
              </ModalStyled>
              <Desc text={"Main client"} css={{ ...padding }} />
              {disabled ? (
                <InputFieldStyled
                  css={{
                    width: "356px",
                    height: "40px",
                  }}
                  name={"client"}
                  disabled={disabled}
                  value={user?.client?.name || "None"}
                  defaultValue={user?.client?.name || "None"}
                />
              ) : (
                <AutoCompleteStyled1
                  css={{ width: "356px", ml: "16px" }}
                  options={createUserStore.getClients().map((option: any) => {
                    return option.name;
                  })}
                  name={"client"}
                  defaultValue={
                    user?.client?.name ||
                    "None"
                  }
                  onChange={(e: any, value: any) => {
                    setUser({
                      ...user,
                      client: createUserStore.getClients().find((client: any) => client.name === value)
                    });
                  }}
                  button={
                    <Button
                      sx={{ mt: "10px", textTransform: "none" }}
                      onMouseDown={() => {
                        setOpenClientModal(true);
                      }}
                    >
                      + add new client
                    </Button>
                  }
                />
              )}

              <Desc text={"Other client 1"} css={{ ...padding }} />
              {disabled ? (
                <InputFieldStyled
                  css={{
                    width: "356px",
                    height: "40px",
                  }}
                  name={"other_clients"}
                  disabled={disabled}
                  value={user?.other_clients[0]?.name || user?.other_clients[0] || "None"}
                />
              ) : (
                <AutoCompleteStyled1
                  css={{ width: "356px", ml: "16px" }}
                  options={createUserStore.getClients().map((option: any) => {
                    return option.name;
                  })}
                  name={"other_clients"}
                  defaultValue={user?.other_clients[0]?.name || user?.other_clients[0] || "None"}
                  onChange={(e: any, value: any) => {
                    user.other_clients[0] = createUserStore.getClients().find((client: any) => client.name === value);
                  }}
                />
              )}

              {val.map((data: any, i) => {
                return (
                  <>
                    <Desc
                      text={`Other client ${oldNumber[i]}`}
                      css={{ ...padding }}
                    />
                    <Box display={"flex"}>
                      {disabled ? (
                        <InputFieldStyled
                          css={{
                            width: "356px",
                            height: "40px",
                          }}
                          name={"other_clients"}
                          disabled={disabled}
                          value={
                            user?.other_clients[i]?.name || user?.other_clients[i] || "None"
                          }
                        />
                      ) : (
                        <AutoCompleteStyled1
                          key={i}
                          css={{ width: "306px", ml: "16px" }}
                          options={createUserStore
                            .getClients()
                            .map((option: any) => {
                              return option.name;
                            })}
                          name={"other_clients"}
                          onChange={(e: any, value: any) => {
                            user.other_clients[i] =
                              createUserStore.getClients().find((client: any) => client.name === value);
                            setUser(
                              user
                            );
                          }}
                        />
                      )}
                      <InputAdornment position="end">
                        <IconButton
                          sx={{
                            color: styleStore.getDropdownIcons(),
                            mt: "40px",
                            ml: "-4px",
                          }}
                          onClick={() => handleRemove()}
                        >
                          <ClearIcon sx={{ width: "20px" }} />
                        </IconButton>
                      </InputAdornment>
                    </Box>
                  </>
                );
              })}
              <Box
                display={"flex"}
                flexDirection={"row"}
                alignItems={"flex-start"}
              >
                {disabled ? (
                  ""
                ) : (
                  <Button
                    variant="text"
                    sx={{
                      ml: "16px",
                      p: "0px",
                      mt: "8px",
                      textTransform: "lowercase",
                      fontWeight: 400,
                      fontSize: "14px",
                      color: styleStore.getActiveText(),
                    }}
                    onClick={() => handleAdd()}
                  >
                    + add client
                  </Button>
                )}
              </Box>
              <Box display={"flex"}>
                <CheckBox
                  checked={user?.is_coach}
                  onChange={(e: any, value: any) => {
                    setUser(
                      { ...user, is_coach: e.target.checked }
                    );
                  }}
                  disabled={disabled}
                  css={{
                    pl: "11px",
                    pb: "21px",
                    pt: "24px",
                    color: styleStore.getFontDescription(),
                  }}
                  label={"Coach"}
                />
              </Box>
            </BoxStyled>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
});

export default UserForm;
