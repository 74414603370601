import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import { useStores } from "../../Helpers/MobX/hooks";
import { observer } from "mobx-react-lite";
import { Box, IconButton, InputAdornment, Paper } from "@mui/material";
import { useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";

const AutocompleteStyled: React.FC<{
  label: string;
  onChange: any;
  options: Array<any>;
  permission?: string;
  keyName?: string;
  onClick?: any;
  width?:string;
  backgroundColor?:any;
  css?:any;
}> = observer(
  ({
    label,
    onChange,
    options,
    permission = undefined,
    keyName = undefined,
    onClick,
    width = "250px",
    backgroundColor=undefined,
    css
  }) => {
    const { styleStore } = useStores();
    const [value, setValue] = useState<string | undefined | null>(null);
    return (
      <Autocomplete
        freeSolo
        disablePortal
        id="combo-box-demo"
        options={options}
        value={value}
        onChange={(event, value) => {
          onChange(event, value);
          setValue(value);
        }}
        PaperComponent={({ children }) => (
          <Paper
            sx={{
              color: styleStore.getBackgroundColor(),
              borderColor: styleStore.getInputBorder(),
              backgroundColor: backgroundColor? backgroundColor : styleStore.getCardBackgroundColor(),
              textAlign: "left",
            }}
          >
            {children}
          </Paper>
        )}
        sx={{
          width: {width},
          height: "40px",
          borderRadius: "15px",
          borderColor:styleStore.getInputBorder(),
          backgroundColor: backgroundColor? backgroundColor : styleStore.getCardBackgroundColor(),
          "& .MuiInputBase-root": {
            //height: "40px",//
          },
          '& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected="true"]':
            {
              backgroundColor: `${styleStore.getBackgroundColor_hover()} !important`,
              color: "white !important",
              "&:hover": {
                backgroundColor: `${styleStore.getBackgroundColor_hover()} !important`,
              },
            },
          "& + .MuiAutocomplete-popper .MuiAutocomplete-option": {
            color: styleStore.getNameTitleColor(),
            "&:hover": {
              color: styleStore.getNameTitleColor(),
              backgroundColor: styleStore.getBackgroundColor_hover(),
            },
          },
          svg: { color: styleStore.getNameTitleColor() },
          label: { color: styleStore.getNameTitleColor() },
          ...css
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            size={"small"}
            value={value}
            onChange={(event) => {
              setValue(event.target.value);
            }}
            sx={{
              color: styleStore.getNameTitleColor(),
              input: {
                color: styleStore.getNameTitleColor(),
                "&:-webkit-autofill": {
                  WebkitBoxShadow: `0 0 0 1000px ${styleStore.getBackgroundPrimary()} inset`,
                  WebkitTextFillColor: styleStore.getNameTitleColor(),
                },
              },
              "& label.Mui-focused": {
                color: styleStore.getNameTitleColor(),
              },
              "& .MuiOutlinedInput-root": {
                height: "40px",
                "& fieldset": {
                  border: "none",
                  borderRadius: "15px",
                },
                "&:hover fieldset": {
                  borderColor: styleStore.getNameTitleColor(),
                },
                "&.Mui-focused fieldset": {
                  borderColor: styleStore.getNameTitleColor(),
                },
              },
              "& .MuiInputBase-root": {
                //height: "40px",//
              },
            }}
            InputProps={{
              ...params.InputProps,
              style: { textAlign: "left" },
              endAdornment: (
                <InputAdornment position="end">
                  {value !== null && value !== undefined && value !== "" ? (
                    <InputAdornment position="end">
                      <IconButton
                        sx={{
                          position: "absolute",
                          right: "10px",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "24px",
                          height: "24px",
                        }}
                        onClick={() => {
                          onClick();
                          setValue(undefined);
                        }}
                      >
                        <ClearIcon sx={{ width: "20px" }} />
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    <Box
                      pr="5px"
                      component="img"
                      alt="Qcerris Logo"
                      sx={{
                        width: "16px",
                        position: "absolute",
                        right: "10px",
                      }}
                      src={styleStore.getSearch()}
                      onClick={() => {
                        console.log(value);
                      }}
                    />
                  )}
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
          />
        )}
        getOptionLabel={(option) => {
          return keyName ? option[keyName] : option;
        }}
      />
    );
  }
);

export default AutocompleteStyled;
