import { UserStore } from "./UserStore";
import { userStore } from "./UserStore";
import { SidebarStore } from "./SidebarStore";
import { sidebarStore } from "./SidebarStore";
import { StyleStore } from "./StyleStore";
import { styleStore } from "./StyleStore";
import { CreateUserStore } from "./PageStores.tsx/CreateUserStore";
import { createUserStore } from "./PageStores.tsx/CreateUserStore";
import { TittleStore } from "./TittleStore";
import { tittleStore } from "./TittleStore";

export class RootStore {
  userStore: UserStore;
  sidebarStore: SidebarStore;
  styleStore: StyleStore;
  createUserStore: CreateUserStore;
  tittleStore: TittleStore;

  constructor() {
    this.userStore = userStore; // Pass `this` into stores for easy communication
    this.sidebarStore = sidebarStore;
    this.styleStore = styleStore;
    this.createUserStore = createUserStore;
    this.tittleStore = tittleStore;
  }
}

export const rootStore = new RootStore();
