import { styleStore } from "../../Helpers/MobX/StyleStore";
import { observer } from "mobx-react-lite";
import { TextField } from "@mui/material";
import internal from "stream";

interface IInputField {
  css: any;
  name: string;
  defaultValue?: string;
  onChange?: any;
  disabled?: boolean;
  value?: string;
  placeholder?:string;
  rows?:number
}

const InputFieldOutlined = observer(
  ({
    css,
    defaultValue = "",
    onChange,
    disabled,
    value,
    placeholder = "",
    rows= 8
  }: IInputField) => {

    return( <TextField
        onChange={onChange}
        disabled={disabled}
        value={value}
        variant="outlined"
        multiline
        rows = {rows}
        placeholder={placeholder}
        InputLabelProps={{ shrink: false }}
        inputProps={{ style: { color:styleStore.getTextColorSecondary()} }}
        sx={{
          color: "red",
          input: {
            color: styleStore.getQcerrisTextInputs(),
          },
          
          "& label.Mui-focused": {
            color: "blue",
          },
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: styleStore.getTextColorPrimary(),
            borderColor:'#7c7c7c',
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#7c7c7c !important",

          },
          "& .MuiOutlinedInput-root": {
            bgcolor: styleStore.getCardBackgroundColor(),
            borderRadius: "15px",
            borderColor:'#7c7c7c',
            "& fieldset": {
              border: styleStore.getInputBorderSecond(),
              borderRadius: "15px",
              borderColor:'#7c7c7c',
            },
            "&:hover fieldset": {
              border: styleStore.getInputBorderSecond(),
              borderRadius: "15px",
              borderColor:'#7c7c7c',
            },
            "&.Mui-focused fieldset": {
              border: styleStore.getInputBorderSecond(),
              borderRadius: "15px",
              borderColor:'#7c7c7c',
            },
          },
          ...css,
        }}
        defaultValue={defaultValue}
      />)
  }
);

export default InputFieldOutlined;
