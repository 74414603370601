import { Box, Input } from "@mui/material";
import { Button, Stack } from "@mui/material";
import { styleStore } from "../../Helpers/MobX/StyleStore";
import { useState } from "react";
import ImageInputText from "../Textbox/ImageInputText";
import { createUserStore } from "../../Helpers/MobX/PageStores.tsx/CreateUserStore";
const Desktop = (params: any) => {
  const [imageUrl, setImageUrl] = useState<any | null>(null);
  const [imageName, setImageName] = useState<string>("");

  const handleFileUpload = (event: any) => {
    const file = event.target.files[0];
    const name = event.target.files[0].name;
    const reader = new FileReader();

    if (
      name === undefined ||
      name === null ||
      file === undefined ||
      file === null
    )
      return;
    reader.onloadend = () => {
      setImageUrl(reader.result);
      createUserStore.setProfileImgUrl(reader.result);
      createUserStore.setFormData(params.name, reader.result);
    };

    reader.readAsDataURL(file);
  };

  const handleCloseClick = () => {
    delete createUserStore.getFormData().avatar;
    setImageUrl(null);
    createUserStore.setProfileImgUrl(null);
  };

  return (
    <>
      {imageUrl ? (
        <>
          <ImageInputText
            css={{ width: "238px", pl: "24px" }}
            inputProps={{ readOnly: true }}
            value={imageName}
            onClick={handleCloseClick}
          ></ImageInputText>
        </>
      ) : (
        <>
          <Stack sx={{ paddingLeft: "24px" }}>
            <label htmlFor="document-button-file">
              <Input
                disabled={params.disabled}
                id="document-button-file"
                type="file"
                name="document-button-file"
                // value={params.value}
                onChange={handleFileUpload}
                sx={{ display: "none" }}
              />
              <Button
                color="primary"
                component="span"
                disabled={params.disabled}
                sx={{
                  textTransform: "capitalize",
                  fontWeight: 400,
                  fontSize: "14px",
                  width: "238px",
                  borderRadius: "15px",
                  backgroundColor: styleStore.getInputBackgroundPrimary(),
                  color: styleStore.getActiveText(),
                  "&:hover": {
                    backgroundColor: styleStore.getActiveText(),
                    color: styleStore.getForegroundSecondary(),
                  },
                }}
              >
                click to upload
                <img
                  src={styleStore.getUploadPng()}
                  style={{ paddingLeft: "84px" }}
                  alt="upload"
                />
              </Button>
            </label>
          </Stack>
        </>
      )}
    </>
  );
};
export default Desktop;
