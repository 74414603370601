import { Box, Card, CardContent, IconButton, Typography } from "@mui/material";
import React from "react";
import { AvatarIcon } from "../Avatar/AvatarIcon";
import { useStores } from "../../Helpers/MobX/hooks";
import { observer } from "mobx-react-lite";
import SmallPill from "../SmallPill";
import { ReactComponent as DeleteDocIcon } from '../../Assets/img/Icons/DeleteBlue.svg';
import { ReactComponent as Pen } from '../../Assets/img/Icons/Pen.svg';
import { ReactComponent as EyeIcon } from '../../Assets/img/Icons/eye.svg';
import { ReactComponent as FormTemplateIcon} from '../../Assets/img/Icons/FormTemplate.svg'

const CardStyled: React.FC<{
  users: any;
  onClick: any;
  status:string;
  pillText:string;
  openModal:any;
  openCancelModal:any;
  title:string;
  last_updated:string;
  created_by:string;
}> = observer(({ users, css, onClick, status, pillText, openModal, openCancelModal, title, last_updated, created_by }: any) => {
  const { styleStore } = useStores();
  console.log(status)
  return (
    <Card
      variant="outlined"
      
      sx={{
        width: "250px !important",
        height: "125px",
        backgroundColor: styleStore.getCardBackgroundColor(),
        border: 0,
        ":hover": {
          boxShadow: "0 0 12px 3px rgba(0, 0, 0, 0.10)",
          cursor: "pointer",
        },
        borderRadius: "15px",
        ...css,
      }}
    >
      <Box display="flex" sx={{ flexDirection: "row" }}>
        
        <CardContent sx={{mr:0}}>
          <Typography
            align="left"
            fontSize={16}
            fontWeight={500}
            sx={{ color: styleStore.getNameTitleColor() }}
            onClick={onClick}
          >
            {title}
            <IconButton sx={{paddingTop:0, paddingRight:0}}
            onClick={openModal}
              >
                <FormTemplateIcon style={{fill:styleStore.getNameTitleColor()}}/>
            </IconButton>
          </Typography>
          <Typography
            align="left"
            fontSize={14}
            fontWeight={400}
            sx={{ color: styleStore.getTextColorSecondary() }}
            onClick={onClick}
          >
            Last updated: {last_updated}
          </Typography>
          <Typography
            align="left"
            fontSize={14}
            fontWeight={400}
            sx={{ color: styleStore.getTextColorSecondary() }}
            onClick={onClick}
          >
            Created by: {created_by}
          </Typography>
         
          <Box display='flex' flexDirection={'row'}>
          <Box sx={{ml:'auto', mt:'9px'}}>
          <IconButton sx={{paddingTop:0, paddingRight:0}} onClick={openCancelModal}>
                <Pen style={{fill:styleStore.getIconPRColor()}}/>
              </IconButton>
          <IconButton sx={{paddingTop:0, paddingRight:0, ml:'16px'}} onClick={openCancelModal}>
                <EyeIcon style={{fill:styleStore.getIconPRColor()}}/>
              </IconButton>
            <IconButton sx={{paddingTop:0, paddingRight:0,  ml:'16px'}}
            onClick={openModal}
              >
                <DeleteDocIcon style={{fill:styleStore.getIconPRColor()}}/>
            </IconButton>
            </Box>
        </Box>
        </CardContent>
        
      </Box>
    </Card>
  );
});

export default CardStyled;
