import Button from "@mui/material/Button";
import { useStores } from "../../Helpers/MobX/hooks";
import { PanoramaSharp } from "@mui/icons-material";

const SecondaryButton = (params: any) => {
  const { styleStore } = useStores();
  return (
    <Button
      variant="contained"
      sx={{
        width: params.width,
        height: "40px",
        borderRadius: "15px",
        backgroundColor: styleStore.getSecondaryButtonColor(),
        color: styleStore.getSecondaryButtonTextColor(),
        "&:hover": {
          backgroundColor: styleStore.getSecondaryButtonColor_hover(),
          color: styleStore.getSecondaryButtonTextColor(),
        },
        "&:active": {
          backgroundColor: styleStore.getSecondaryButtonColor_click(),
          color: styleStore.getSecondaryButtonTextColor_click(),
        },
        ...params.css
      }}
      
      onClick={params.onClick}
    >
      {params.text}
    </Button>
  );
};
export default SecondaryButton;
