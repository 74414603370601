import {
  Autocomplete,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
} from "@mui/material";
import { useStores } from "../../Helpers/MobX/hooks";
import { observer } from "mobx-react-lite";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useState } from "react";

const AutoCompleteStyledFilters = observer(
  ({ item, name, setUrlParams, urlParams, width }: any) => {
    const { styleStore } = useStores();
    const [value, setValue] = useState<string | undefined | null>(null);

    return (
      <Autocomplete
        onChange={(event: any, value: any) => {
          switch (name) {
            case "Client":
              setUrlParams({ ...urlParams, page: 1, client: value });
              break;
            case "Seniority":
              setUrlParams({ ...urlParams, page: 1, seniority: value });
              break;
            case "Position":
              setUrlParams({ ...urlParams, page: 1, position: value });
              break;
            case "Role":
              setUrlParams({ ...urlParams, page: 1, role: value });
              break;
            case "Team":
              setUrlParams({ ...urlParams, page: 1, team: value });
              break;
          }
          setValue(value);
        }}
        value={value}
        PaperComponent={({ children }) => (
          <Paper
            sx={{
              color: styleStore.getBackgroundColor(),
              border: 0,
              borderColor: styleStore.getBorder(),
              backgroundColor: styleStore.getCardBackgroundColor(),
              textAlign: "left",
            }}
          >
            {children}
          </Paper>
        )}
        disablePortal
        id="combo-box-demo"
        options={item?.sort()}
        sx={{
          fontsize: "14px",
          textAlign: "left",
          height: "40px",
          textDecorationColor: styleStore.getNameTitleColor(),
          backgroundColor: styleStore.getCardBackgroundColor(),
          ":hover": {
            boxShadow: "0 0 12px 3px rgba(0, 0, 0, 0.10)",
          },
          borderRadius: "15px",
          ml: "24px",
          width: width,
          "& .MuiAutocomplete-inputRoot": {
            paddingTop: "0px",
            paddingBottom: "16px",
          },
          '& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected="true"]':
            {
              backgroundColor: `${styleStore.getBackgroundColor()} !important`,
              color: `${styleStore.getNameTitleColor()} !important`,
              "&:hover": {
                backgroundColor: `${styleStore.getBackgroundColor_hover()} !important`,
              },
            },
          "& + .MuiAutocomplete-popper .MuiAutocomplete-option": {
            color: styleStore.getNameTitleColor(),
            "&:hover": {
              color: `${styleStore.getNameTitleColor()}`,
              backgroundColor: styleStore.getBackgroundColor_hover(),
            },
          },
          svg: { color: styleStore.getNameTitleColor() },
          label: { color: styleStore.getNameTitleColor() },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={name}
            size={"small"}
            sx={{
              color: styleStore.getNameTitleColor(),
              input: {
                color: styleStore.getNameTitleColor(),
                "&:-webkit-autofill": {
                  WebkitBoxShadow: `0 0 0 1000px ${styleStore.getBackgroundPrimary()} inset`,
                  WebkitTextFillColor: styleStore.getNameTitleColor(),
                },
              },
              "& label.Mui-focused": {
                color: styleStore.getNameTitleColor(),
              },
              "& .MuiOutlinedInput-root": {
                height: "40px",
                "& fieldset": {
                  border: "none",
                  borderColor: styleStore.getBorder(),
                  borderRadius: "15px",
                },
                "&:hover fieldset": {
                  border: "none",
                  borderColor: styleStore.getNameTitleColor(),
                },
                "&.Mui-focused fieldset": {
                  border: "none",
                  borderColor: styleStore.getNameTitleColor(),
                },
              },
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  {value !== null && value !== undefined ? (
                    <InputAdornment position="end">
                      <IconButton
                        sx={{
                          position: "absolute",
                          right: "8px",
                          bottom: "0px",
                        }}
                        onClick={() => {
                          setValue(undefined);
                          switch (name) {
                            case "Client":
                              setUrlParams({
                                ...urlParams,
                                page: 1,
                                client: null,
                              });
                              break;
                            case "Seniority":
                              setUrlParams({
                                ...urlParams,
                                page: 1,
                                seniority: null,
                              });
                              break;
                            case "Position":
                              setUrlParams({
                                ...urlParams,
                                page: 1,
                                position: null,
                              });
                              break;
                            case "Role":
                              setUrlParams({
                                ...urlParams,
                                page: 1,
                                role: null,
                              });
                              break;
                            case "Team":
                              setUrlParams({
                                ...urlParams,
                                page: 1,
                                team: null,
                              });
                              break;
                          }
                        }}
                      >
                        <ClearIcon sx={{ width: "20px" }} />
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    <InputAdornment position="end">
                      <ArrowDropDownIcon
                        sx={{
                          position: "absolute",
                          right: "8px",
                          bottom: "7px",
                        }}
                      />
                    </InputAdornment>
                  )}
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    );
  }
);

export default AutoCompleteStyledFilters;