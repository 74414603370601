import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import AvatarWrapper from "../../Avatar/AvatarWrapper";
import Notifications from "../../Notifications";
import { useStores } from "../../../Helpers/MobX/hooks";
import { observer } from "mobx-react-lite";

const Mobile = observer(() => {
  const { sidebarStore } = useStores();
  const { styleStore } = useStores();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        style={{
          boxShadow: "none",
          borderBottom: "1px solid lightgray",
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: styleStore.getBackgroundPrimary(),
        }}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, flexGrow: 1 }}
      >
        <Toolbar>
          <Box sx={{ display: "flex", alignItems: "center", width: "100vw" }}>
            <IconButton
              onClick={() => {
                sidebarStore.toggle();
              }}
            >
              <MenuIcon
                sx={{ mr: 2, color: styleStore.getForegroundPrimary() }}
              />
            </IconButton>
            <Box
              sx={{
                width: "100%",
                alignItems: "left",
                justifyContent: "left",
                display: "flex",
              }}
            >
              <Box sx={{ width: "100px", mt: 1 }}>
                <Link to="/">
                  <Box
                    sx={{ width: "100%" }}
                    component="img"
                    src={styleStore.getLogo(true)}
                  ></Box>
                </Link>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              alignItems: "center",
              justifyContent: "right",
              display: "flex",
            }}
          >
            <Notifications right={90} />
            <AvatarWrapper />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
});
export default Mobile;
