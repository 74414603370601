import { Box, Grid, InputAdornment, IconButton, Button, Typography } from "@mui/material";
import AutoCompleteStyled1 from "./AutoComplete/AutocompleteStyledSecondary";
import InputFieldStyled from "./Textbox/InputFieldStyled";
import { styleStore } from "../Helpers/MobX/StyleStore";
import ClearIcon from "@mui/icons-material/Clear";
import { useStores } from "../Helpers/MobX/hooks";
import Input from "../Components/Input/Input";
import DatePickerStyled from "./DatePicker";
import { observer } from "mobx-react-lite";
import BoxStyled from "./BoxStyled";
import CheckBox from "./CheckBox";
import { useState } from "react";
import { toJS } from "mobx";
import dayjs from "dayjs";
import ModalStyled from "./ModalStyled";
import PrimaryButton from "./Button/PrimaryButton";
import SecondaryFileDialogButton from "./Button/SecondaryFileDialogButton";
import { Apis } from "../Helpers/ApiHelper/ApiFactory";
import AutocompleteStyled from "./AutoComplete/AutocompleteStyled";
import AutoCompleteStyledFilters from "./AutoComplete/AutoCompleteStyledFilters";
import WordFileCard from "./Cards/WordFileCard";
import JPGFileCard from "./Cards/JPGFileCard";
import PDFFileCard from "./Cards/PDFFileCard";
import WordXFileCard from "./Cards/WordXFileCard";
import Modal from '@mui/material/Modal';

interface IUserForm {
  disabled: boolean;
  user: any;
  setUser: any;
}
const Documents = observer(() => {
  const { createUserStore } = useStores();
  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const roles = ["Super HR", "HR", "Client", "Regular user"];

  const padding = {
    pl: "16px",
    pb: "8px",
    pt: "16px",
  };
  const paddingTopSide = {
    pl: "16px",
    pb: "8px",
  };

  const paddingTitle = {
    pl: "16px",
    pt: "16px",
    pb: "24px",
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleDeleteModalClose = () => setOpenDeleteModal(false);


  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  
  return (
    <Box sx={{width:"1900px"}}>
      <Box display='flex' flexDirection='row' sx={{ ml: "24px" }}>
        <WordFileCard onClick={handleOpen} openDeleteModal={handleOpenDeleteModal}/>
        <Box sx={{marginLeft:'32px'}}/>
        <WordXFileCard onClick={handleOpen}/>
        <Box sx={{marginLeft:'32px'}}/>
        <JPGFileCard onClick={handleOpen}/>
        <Box sx={{marginLeft:'32px'}}/>
        <PDFFileCard onClick={handleOpen}/>

      </Box>
      <Box sx={{ mt: "128px", display: "flex" }}>
        <Box sx={{ mr: "24px" }}>
          <AutoCompleteStyledFilters
            name={"Type of document"}
            item={["PDF", "Word", "Image"]}
            width={202}
          />
        </Box>
        <Box sx={{ mr: "24px" }}>
          <SecondaryFileDialogButton
            text="Add Document"
            onClick={() => {
              console.log("secondary");
            }}
          />
        </Box>

        <PrimaryButton
          text={"Upload"}
          onClick={() => {
            console.log("primary");
          }}
        />
      </Box>
      <Modal
        open={openDeleteModal}
        onClose={handleDeleteModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ backgroundColor:'#313131 80%' , backdropFilter: "blur(3px)",}}
      >
        <>
        <Box sx={{backgroundColor:styleStore.getCardBackgroundPrimary(),
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '341px',
        border: '1px',
        borderRadius:'15px',
        p: 4,
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Removing Document
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Are you sure you want to delete this file?
          </Typography>
          
        </Box>
        <Box sx={{
          display:'flex',
          flexDirection:'row',
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        mt:'120px',
        ml:'200px',
        width:'800px'
        }}>
          <SecondaryFileDialogButton
            text="Cancel"
            onClick={() => {
              console.log("secondary");
            }}
          />
          <Box sx={{mr:'24px'}}/>
        <PrimaryButton
          text={"Delete"}
          onClick={() => {
            console.log("primary");
          }}
        />
        
          </Box>
          </>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Removing Document
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to delete this file?
          </Typography>
        </Box>
      </Modal>
    </Box>

  );
});

export default Documents;
