import Box from '@mui/material/Box';
import { observer } from "mobx-react-lite";
import { useStores } from "../../../Helpers/MobX/hooks";
import Tree from "../../../Assets/img/dashboard.svg"
import Wiki from "../../../Assets/img/Wiki.svg"
import BoxStyled from '../../../Components/BoxStyled';
import Slack from "../../../Assets/img/Slack.svg";
import QcerrisLogo from "../../../Assets/img/QcerrisLogoDash.svg";
import { Typography } from '@mui/material';

const Desktop = observer((params:any) => {
    const {styleStore} = useStores();
    return(
    <Box sx={{display:'flex', flexDirection:'row'}}>
       <Box id='left' sx={{width:'50%', ml:'14px', mt:'10px', display:'flex', flexDirection:'row'}}>
        <Box sx={{display:'flex', flexDirection:'column', mr:'31px'}}>
       <BoxStyled css={{ width:'344px', marginBottom:'31px', backgroundColor:styleStore.getCardDarkerColor()}}> 
        <Typography id="modal-modal-title" textAlign='center' sx={{color:"#FFFFFF", fontSize:'24px', fontWeight:'bold', marginBottom:'21px', marginTop:'21px'}}>
        QCaas Platform
          </Typography>
          <Typography id="modal-modal-title" textAlign='justify' sx={{color:"#DDDDDD", fontSize:'16px', marginLeft:'18px', marginRight:'18px', marginBottom:'21px'}}>
          Qcaas platform unites all the procceses beetwen a contractor IT company and it’s clients, sorting them into an organised system giving everyone the best user experience. If you want to learn all the perks, tools, tips and tricks, click on the Get Started button.
          </Typography>
        </BoxStyled>
        <BoxStyled css={{ width:'344px', backgroundColor:styleStore.getCardLighterColor() }}> 
        <Box sx={{display:'flex', flexDirection:'row',  marginBottom:'21px', marginTop:'21px', justifyContent:'center', alignItems:'center'}}>
        <Box id='right' sx={{width:'55px', height:'55px'}}>
       <img src={Wiki} />
       </Box>
        <Typography id="modal-modal-title" textAlign='center' sx={{color:"#FFFFFF", fontSize:'24px', fontWeight:'bold'}}>
        QCerris Wiki
          </Typography>
          </Box>
          <Typography id="modal-modal-title" textAlign='justify' sx={{color:"#DDDDDD",fontSize:'16px', marginLeft:'18px', marginRight:'18px', marginBottom:'21px'}}>
          Want to find all our company info, HR procedures and our knowledge sharing base?
<br/>
<br/>
Check out our Wiki page.
          </Typography>
        </BoxStyled>
        </Box>
        <Box sx={{display:'flex', flexDirection:'column'}}>
        <BoxStyled css={{ width:'344px', backgroundColor:styleStore.getCardLighterColor(), marginBottom:'31px' }}> 
        <Box sx={{display:'flex', flexDirection:'row',  marginBottom:'21px', marginTop:'21px', justifyContent:'center', alignItems:'center'}}>
       <img src={Slack} />
          </Box>
          <Typography id="modal-modal-title" textAlign='justify' sx={{color:"#DDDDDD", fontSize:'16px', marginLeft:'18px', marginRight:'18px', marginBottom:'21px'}}>
          Join QCerris workspace on Slack and start chatting with your colleagues.
          </Typography>
        </BoxStyled>
        <BoxStyled css={{ width:'344px', backgroundColor:styleStore.getCardDarkerColor() }}> 
        <Box sx={{display:'flex', flexDirection:'row',  marginBottom:'21px', marginTop:'21px', justifyContent:'center', alignItems:'center'}}>
        <Box id='right' sx={{width:'55px', height:'55px'}}>
       <img src={QcerrisLogo} />
       </Box>
        <Typography id="modal-modal-title" textAlign='center' sx={{color:"#FFFFFF", fontSize:'24px', fontWeight:'bold'}}>
        QCerris Website
          </Typography>
          </Box>
          <Typography id="modal-modal-title" textAlign='justify' sx={{color:"#DDDDDD", fontSize:'16px', marginLeft:'18px', marginRight:'18px', marginBottom:'21px'}}>
          We are an end-to-end full service software design and development shop. Beautiful and useful interfaces. Reliable, responsive and secure back end. Those are the traits of the software we are delivering to our customers. We grew and evolved together with our partners and we are committed to stay your trusted guide as you refine and implement your vision.
          </Typography>
        </BoxStyled>
        </Box>
       </Box>
       <Box id='right' sx={{width:'711px', height:'710px', mt:'10px'}}>
       <img src={styleStore.getDashboardImage()} />
       </Box>
    </Box>
    )
})
export default Desktop;