import { Box, FormControl, FormControlLabel, IconButton, Radio, RadioGroup, Tooltip, Typography} from "@mui/material";
import P from "../../../Assets/Fonts/P";
import BoxStyled from "../../../Components/BoxStyled";
import { useState } from "react";
import Link from "../../../Assets/Fonts/Link";
import { useStores } from "../../../Helpers/MobX/hooks";
import { observer } from "mobx-react-lite";
import AutocompleteStyled from "../../../Components/AutoComplete/AutocompleteStyled";
import PrimaryButton from "../../../Components/Button/PrimaryButton";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import InputFieldOutlined from "../../../Components/Textbox/InputFieldOutlined";
import SecondaryButton from "../../../Components/Button/SecondaryButton";
import LinkButton from "../../../Components/Button/LinkButton";
import TerciaryButton from "../../../Components/Button/TerciaryButton";
import LineTextbox from "../../../Components/Textbox/LineTextbox";
import { ReactComponent as DeleteDocIcon } from '../../../Assets/img/Icons/DeleteBlue.svg';
import { ReactComponent as DragIcon } from '../../../Assets/img/Icons/9dot.svg';
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import CheckBox from "../../../Components/CheckBox";

interface ISubsection{
  provided:any;
  removeSubsection:any;
  subsectionsColapse:any;
  id:string;
}

const Subsection = observer(({provided, subsectionsColapse, removeSubsection, id} : ISubsection) => {
    const { userStore } = useStores();
      const { styleStore } = useStores();
      const [subsections, setSubsections] = useState<Array<number>>([]);
      const [questions, setQuestions] = useState<Array<number>>([]);
      const[additionalInfo, setAdditionalInfo] = useState<string>("");

      const removeQuestion = () => {
       
        const temp = [...questions];
        temp.pop();
        setQuestions(temp);
        
      }
    return(
       
<BoxStyled css={{ width: "1630px", marginLeft: "14px", marginTop:'24px', paddingTop:'24px', backgroundColor:styleStore.getCardBackgroundColor(), }} >

<Box sx={{ display:'flex', flexDirection:'row', justifyContent:"left", ml:'24px', alignItems:'start'}}>
<Typography id="modal-modal-title" textAlign='start' sx={{color:styleStore.getNameTitleColor(), fontSize:'24px', fontWeight:'bold'}}>
Subsection title
</Typography>
<Box sx={{ml:'auto', mr:'24px'}}>
<IconButton sx={{paddingTop:0, paddingRight:0,  ml:'16px'}}
onClick={removeSubsection}
>
<DeleteDocIcon style={{fill:styleStore.getNameTitleColor()}}/>
</IconButton>
<IconButton sx={{paddingTop:0, paddingRight:0,  ml:'16px'}}
onClick={() => {}}
 {...provided.dragHandleProps}
>
<DragIcon style={{fill:styleStore.getNameTitleColor()}}/>
</IconButton>
</Box>
</Box>
<Box sx={{ml:'24px', mt:'24px'}}>
{!subsectionsColapse ?
<Droppable droppableId={id} key={id}>
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
{questions.map((item:any, index:number) => {
return(
  <Draggable key={`question-${id}-${index}`} draggableId={`question-${id}-${index}`} index={index}>
                    {(questionProvided) => (
                      <div
                        ref={questionProvided.innerRef}
                        {...questionProvided.draggableProps}
                      >
  <Box sx={{display:'flex', flexDirection:'row', mt:'16px'}}>
  <Typography id="modal-modal-description" textAlign='start' sx={{ mr:'8px', color:styleStore.getQcerrisTextSecondary(), fontSize:'14px' }}>
  {index + 1}. 
  </Typography>
  <LineTextbox placeholder='Question...' css={{width:'1378px', marginTop:'-7px', input: {
    color: styleStore.getForegroundPrimary(),
    background: styleStore.getCardBackgroundColor(),
    
    "&:-webkit-autofill": {
        WebkitBoxShadow: `0 0 0 1000px ${styleStore.getBackgroundPrimary()} inset`,
        WebkitTextFillColor: styleStore.getForegroundPrimary()
      },
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: styleStore.getTextColorSecondary(),
      
    },
    caretColor: styleStore.getForegroundPrimary(),
  }}></LineTextbox>
  <CheckBox label='Rating' css={{marginLeft:"32px"}}/>
  <IconButton sx={{paddingTop:'5px', paddingRight:0,  ml:'21px'}}
onClick={removeQuestion}
>
<DeleteDocIcon style={{fill:styleStore.getNameTitleColor()}}/>
</IconButton>
<IconButton sx={{paddingTop:'5px', paddingRight:0,  ml:'16px'}}
onClick={() => {}}
 {...questionProvided.dragHandleProps}
>
<DragIcon style={{fill:styleStore.getNameTitleColor()}}/>
</IconButton>
  </Box>
  </div>
                    )}
                  </Draggable>
)
})}
 {provided.placeholder}
              </div>
            )}
          </Droppable> : <></>}

</Box>

<TerciaryButton onClick={() => {
  let temp = [...questions];
  temp.push(1);
  setQuestions(temp);
}} css={{color:styleStore.getActiveText(), fontSize:'14px', width:'150px', fontWeight:'regular', marginLeft:'-1480px', marginBottom:'8px'}}>          
+ add question
</TerciaryButton>

</BoxStyled>
    )
})

export default Subsection;