import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Textbox from "./Textbox/Textbox";
import PrimaryButton from "./Button/PrimaryButton";
import BasicText from "./Text/BasicText";
import { useStores } from "../Helpers/MobX/hooks";
import { observer } from "mobx-react-lite";
import Swal from "sweetalert2";

interface IModal {
  open: boolean;
  onClose: any;
  children: any;
}
const ModalStyled = observer(({ open, onClose, children }: IModal) => {
  const { styleStore } = useStores();
  return (
    <Modal
      sx={{
        backdropFilter: "blur(5px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
      }}
      open={open}
      onClose={onClose}
    >
      {children}
    </Modal>
  );
});

export default ModalStyled;
