import { Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { styleStore } from "../../Helpers/MobX/StyleStore";

const P = observer(({ text, css }: any) => {
  return (
    <Typography
      sx={{
        ...css,
        textAlign: "left",
        ...styleStore.getFontParagraph(),
      }}
    >
      {text}
    </Typography>
  );
});

export default P;
