import { Box, Card, CardContent, IconButton, Typography } from "@mui/material";
import React from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../Helpers/MobX/hooks";
import { PieChart } from "@mui/x-charts";


const CardStyled: React.FC<{
css?:any, children:any
}> = observer(({css, children}: any) => {
  const { styleStore } = useStores();
  return (
    <Card
      variant="outlined"
      
      sx={{
        width: "274px !important",
        height: "140px",
        backgroundColor: styleStore.getCardBackgroundColor(),
        border: 0,
        ":hover": {
          boxShadow: "0 0 12px 3px rgba(0, 0, 0, 0.10)",
          cursor: "pointer",
        },
        borderRadius: "15px",
        ...css,
      }}
    >
      <Box display="flex" sx={{ flexDirection: "row" }}>
        <CardContent sx={{mr:0}}>
       {children}
        </CardContent>
        
      </Box>
    </Card>
  );
});

export default CardStyled;
