import { Box, IconButton, TableCell, TableRow, styled, tableCellClasses } from "@mui/material";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../Helpers/MobX/hooks";
import { createUserStore } from "../../../Helpers/MobX/PageStores.tsx/CreateUserStore";
import Pill from "../../../Components/Pill";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { ReactComponent as FormIcon } from '../../../Assets/img/Icons/FormIcon.svg';
import { ReactComponent as EyeIcon } from '../../../Assets/img/Icons/eye.svg';
import { ReactComponent as DownloadIcon } from '../../../Assets/img/Icons/Download.svg';


interface ITravelRequestElement {
  name: string;
  requesting:string;
  departing:string;
  dateOfRequest:string,
  status:string,
}

const PerformanceReviewEmployeeEntry = observer(({ name,
    requesting,
    departing,
    dateOfRequest,
    status}:ITravelRequestElement ) => {

    const {styleStore} = useStores();

      const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
          backgroundColor: styleStore.getCardBackgroundColor(),
          color: styleStore.getTextColorSecondary(),
          fontSize: 14,
        },
      }));

      const StyledTableCellPrimary = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
          backgroundColor: styleStore.getCardBackgroundColor(),
          color: styleStore.getTextColorPrimary(),
          fontSize: 14,
        },
      }));

    return (
        <TableRow>
            <StyledTableCell >
              <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', ml:'18px'}}>
              {createUserStore.getProfileImgUrl() ? (
                <>
                  <img
                    src={createUserStore.getProfileImgUrl()}
                    style={{
                      width: "32px",
                      height: "32px",
                      borderRadius: "64px",
                    }}
                    alt=""
                  />
                </>
              ) : (
                <>
                  <img src={styleStore.getBlankProfile()} style={{
                      width: "32px",
                      height: "32px",
                      borderRadius: "64px",
                    }} alt="blank" />
                </>
              )}  
              
              <Box sx={{ml:'8px'}}>Fillers Name</Box>
              </Box>
            </StyledTableCell>
            <StyledTableCell  style={{paddingLeft:'45px'}}><Box sx={{ml:'70px'}}>HR</Box></StyledTableCell>
            <StyledTableCell style={{paddingLeft:'30px'}}><Box sx={{ml:'70px'}}>{dateOfRequest}</Box></StyledTableCell>
            <StyledTableCellPrimary >SEND | EDIT</StyledTableCellPrimary>
            <StyledTableCell ><IconButton
               
               >
                 <DownloadIcon />
               </IconButton></StyledTableCell>
            <StyledTableCell ><IconButton
               
               >
                 <EyeIcon />
               </IconButton></StyledTableCell>
            <StyledTableCell >
            <IconButton
                sx={{
                  color: styleStore.getDropdownIcons(),
                }}
                onClick={() => {}}
              >
                <DeleteOutlinedIcon sx={{color:'#7DB1FF'}} />
              </IconButton>
            </StyledTableCell>
          </TableRow>
    )
 })
 
 export default PerformanceReviewEmployeeEntry;