import * as React from 'react';
import TextField from '@mui/material/TextField';
import { useStores } from "../../Helpers/MobX/hooks";
import { observer } from "mobx-react-lite"

const LineTextbox = observer((params:any) => {
    const { styleStore } = useStores();
    return (
    <TextField id="standard-basic" 
    label={params.text}
    variant="standard"
    focused
    fullWidth required={params.required}
    onChange={params.onChange}
    onKeyDown={params.onKeyDown}
    error={params.error}
    placeholder={params.placeholder}
    sx={{input: {
        color: styleStore.getForegroundPrimary(),
        background: styleStore.getBackgroundPrimary(),
        "&:-webkit-autofill": {
            WebkitBoxShadow: `0 0 0 1000px ${styleStore.getBackgroundPrimary()} inset`,
            WebkitTextFillColor: styleStore.getForegroundPrimary()
          },
         ...params.css
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: "#7c7c7c",
        
      },
      caretColor: styleStore.getForegroundPrimary(),
      ...params.css
      
    }}
      InputLabelProps={{style : {color : styleStore.getLabel()}}}
    />)
})

export default LineTextbox;