import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import { AvatarIcon } from "./Avatar/AvatarIcon";
import { useStores } from "../Helpers/MobX/hooks";
import { observer } from "mobx-react-lite";

const CardStyled: React.FC<{
  users: any;
  onClick: any;
}> = observer(({ users, css, onClick }: any) => {
  const { styleStore } = useStores();
  return (
    <Card
      variant="outlined"
      onClick={onClick}
      sx={{
        width: "250px !important",
        height: "120px",
        backgroundColor: styleStore.getCardBackgroundColor(),
        border: 0,
        ":hover": {
          boxShadow: "0 0 12px 3px rgba(0, 0, 0, 0.10)",
          cursor: "pointer",
        },
        borderRadius: "15px",
        ...css,
      }}
    >
      <Box display="flex" sx={{ flexDirection: "row" }}>
        <Box
          display="flex"
          sx={{ ml: "12px", mt: "16px", height: "64px", width: "64px" }}
        >
          <AvatarIcon
            src={"data:image/jpeg;base64," + users?.avatar_64}
            firstName={users?.user?.first_name}
            lastName={users?.user?.last_name}
            style={{
              height: "64px",
              width: "64px",
              fontSize: "33px",
            }}
          />
        </Box>
        <CardContent>
          <Typography
            gutterBottom
            align="left"
            fontSize={16}
            fontWeight={500}
            sx={{ color: styleStore.getNameTitleColor() }}
          >
            {users?.user?.first_name + " " + users?.user?.last_name}
          </Typography>
          <Typography
            gutterBottom
            align="left"
            fontSize={14}
            fontWeight={400}
            sx={{ color: styleStore.getRoleColor(users?.position?.name) }}
          >
            {users?.position?.name}
          </Typography>
          <Typography
            gutterBottom
            align="left"
            fontSize={14}
            fontWeight={400}
            sx={{ color: styleStore.getTextColorSecondary() }}
          >
            {users?.client?.name}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  );
});

export default CardStyled;
