import { ReactComponent as RecruitmentLandingIcon } from "../../../Assets/img/Icons/RecruitmentLanding.svg";
import { ReactComponent as TravelRequestFormIcon } from "../../../Assets/img/Icons/TravelRequestForm.svg";
import { ReactComponent as TravelRequestListIcon } from "../../../Assets/img/Icons/TravelRequestList.svg";
import { ReactComponent as PerformanceReviewIcon } from "../../../Assets/img/Icons/PerformanceReview.svg";
import { ReactComponent as FilesAndDocumentsIcon } from "../../../Assets/img/Icons/FilesandDocuments.svg";
import { ReactComponent as NewFormTemplateIcon } from "../../../Assets/img/Icons/NewFormTemplate.svg";
import { ReactComponent as DashboardIcon } from "../../../Assets/img/Icons/Dashboard w hover.svg";
import { ReactComponent as InactiveUsersIcon } from "../../../Assets/img/Icons/InactiveUsers.svg";
import { ReactComponent as TravelRequestIcon } from "../../../Assets/img/Icons/TravelRequest.svg";
import { ReactComponent as CandidateListIcon } from "../../../Assets/img/Icons/CandidateList.svg";
import { ReactComponent as PathToSuccessIcon } from "../../../Assets/img/Icons/PathtoSuccess.svg";
import { ReactComponent as CreateNewUser } from "../../../Assets/img/Icons/CreateNewUser.svg";
import { ReactComponent as RecruitmentIcon } from "../../../Assets/img/Icons/Recruitment.svg";
import { ReactComponent as PRRequestIcon } from "../../../Assets/img/Icons/PRRequest.svg";
import { ReactComponent as PRHistoryIcon } from "../../../Assets/img/Icons/PRHistory.svg";
import { ReactComponent as StructureIcon } from "../../../Assets/img/Icons/Structure.svg";
import { ReactComponent as OurTeamIcon } from "../../../Assets/img/Icons/OurTeam.svg";
import { ReactComponent as PRListIcon } from "../../../Assets/img/Icons/PRList.svg";
import { ReactComponent as SettingsIcon } from "../../../Assets/img/Icons/settings.svg";
import { ReactComponent as SettingsLightIcon } from "../../../Assets/img/Icons/settingsLight.svg";
import AccordionSummarySidebar from "../../SidebarElements/AccordionSummarySidebar";
import { ReactComponent as UsersIcon } from "../../../Assets/img/Icons/Users.svg";
import ListItemSidebarSubButton from "../../SidebarElements/ListItemSibarSubButton";
import ListItemSidebarButton from "../../SidebarElements/ListItemSidebarButton";
import AccordionSidebar from "../../SidebarElements/AccordionSidebar";
import SidebarSection from "../../SidebarElements/SidebarSection";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useStores } from "../../../Helpers/MobX/hooks";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";
import Drawer from "@mui/material/Drawer";
import { Divider } from "@mui/material";
import { Link } from "react-router-dom";
import { SvgIcon } from "@mui/material";
import Box from "@mui/material/Box";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import GroupRemoveOutlinedIcon from "@mui/icons-material/GroupRemoveOutlined";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import InsightsOutlinedIcon from "@mui/icons-material/InsightsOutlined";
import SchemaOutlinedIcon from "@mui/icons-material/SchemaOutlined";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import EditLocationOutlinedIcon from "@mui/icons-material/EditLocationOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import CasesOutlinedIcon from "@mui/icons-material/CasesOutlined";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import NextWeekOutlinedIcon from "@mui/icons-material/NextWeekOutlined";
import AddLocationAltOutlinedIcon from "@mui/icons-material/AddLocationAltOutlined";


const drawerWidthOpen = 290;

const Desktop = observer(() => {
  const { sidebarStore } = useStores();
  const { styleStore } = useStores();
  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        variant="permanent"
        anchor="left"
        PaperProps={{
          sx: {
            overflowX:'hidden',
            backgroundColor: styleStore.getBackgroundColor(),
            width: sidebarStore.open ? "250px" : "90px",
            boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.15)",
            "& .MuiDrawer-paper": {
              width: sidebarStore.open ? "250px" : "100px",
              boxSizing: "order-box",
            },
          },
        }}
        sx={{
          width: sidebarStore.open ? "250px" : "100px",
          flexShrink: 0,
        }}
      >
        <Box
          sx={{
            mt: "19px",
            ml: sidebarStore.open ? "50px" : "29px",
            display: "flex",
          }}
        >
          <Link to="/">
            <Box
              component="img"
              src={styleStore.getLogo(sidebarStore.open)}
            ></Box>
          </Link>
        </Box>

        <Box sx={{ mt: "16px" }}>
          <ListItemSidebarButton
            text={"Dashboard"}
            icon={<DashboardIcon />}
            redirectURL={"/"}
          />
          <AccordionSidebar permission={"canView_EmployeeListOptions"}>
            <AccordionSummarySidebar>
              <SvgIcon
                sx={{
                  mr: "8px",
                  width: "17px",
                  pl: sidebarStore.open ? "" : "7px",
                }}
                viewBox="0 0 17 14"
                stroke="none"
              >
                <UsersIcon />
              </SvgIcon>
              <Typography>{sidebarStore.open ? `Users` : ``}</Typography>
            </AccordionSummarySidebar>
            <AccordionDetails>
              <ListItemSidebarSubButton
                text={"Our team"}
                icon={
                  <SvgIcon
                    sx={{
                      mr: "8px",
                      width: "21px",
                      ml: sidebarStore.open ? "" : "-2px",
                    }}
                    viewBox="0 0 21 12"
                    stroke="none"
                  >
                    <OurTeamIcon />
                  </SvgIcon>
                }
                redirectURL={"/employee-list"}
              />
              <ListItemSidebarSubButton
                text={"Inactive users list"}
                icon={
                  <SvgIcon
                    sx={{
                      ml: sidebarStore.open ? "4px" : "0px",
                      mr: "10px",
                      width: "16px",
                    }}
                    viewBox="0 0 16 14"
                    stroke="none"
                  >
                    <InactiveUsersIcon />
                  </SvgIcon>
                }
                redirectURL={"/inactive-employee-list"}
              />
              <ListItemSidebarSubButton
                text={"Create user"}
                icon={
                  <SvgIcon
                    sx={{
                      ml: sidebarStore.open ? "4px" : "0px",
                      mr: "10px",
                      width: "15px",
                    }}
                    viewBox="0 0 15 15"
                    stroke="none"
                  >
                    <CreateNewUser />
                  </SvgIcon>
                }
                redirectURL={"/create-user"}
              />
            </AccordionDetails>
          </AccordionSidebar>
          <AccordionSidebar permission={"canView_TravelRequestOptions"}>
            <AccordionSummarySidebar>
              <SvgIcon
                sx={{
                  ml: "2px",
                  mr: "10px",
                  width: "12px",
                  pl: sidebarStore.open ? "" : "6px",
                }}
                viewBox="0 2 12 16"
                stroke="none"
              >
                <TravelRequestIcon />
              </SvgIcon>
              <Typography>
                {sidebarStore.open ? `Travel Request` : ``}
              </Typography>
            </AccordionSummarySidebar>
            <AccordionDetails>
              <ListItemSidebarSubButton
                text={"Travel request form"}
                icon={
                  <SvgIcon
                    sx={{
                      mr: "8px",
                      width: "16px",
                      height: "16px",
                    }}
                  >
                    <TravelRequestFormIcon />
                  </SvgIcon>
                }
                redirectURL={"/travel-request"}
              />
              <ListItemSidebarSubButton
                text={"Travel request list"}
                icon={
                  <SvgIcon
                    sx={{
                      mr: "8px",
                      width: "16px",
                      height: "16px",
                    }}
                  >
                    <TravelRequestListIcon />
                  </SvgIcon>
                }
                redirectURL={"/travel-request-list"}
              />
            </AccordionDetails>
          </AccordionSidebar>
          <AccordionSidebar>
            <AccordionSummarySidebar>
              <SvgIcon
                sx={{
                  mr: "8px",
                  width: "16px",
                  pl: sidebarStore.open ? "" : "6px",
                }}
                viewBox="0 2 18 16"
                stroke="none"
              >
                <PerformanceReviewIcon />
              </SvgIcon>
              <Typography>
                {sidebarStore.open ? `Performance review` : ``}
              </Typography>
            </AccordionSummarySidebar>
            <AccordionDetails>
              <ListItemSidebarSubButton
                text={"P.R. request"}
                icon={
                  <SvgIcon sx={{ mr: "4px" }}>
                    <PRRequestIcon />
                  </SvgIcon>
                }
                redirectURL={"/performance-review-request"}
              />
              <ListItemSidebarSubButton
                text={"P.R. list"}
                icon={
                  <SvgIcon sx={{ mr: "4px" }}>
                    <PRListIcon />
                  </SvgIcon>
                }
                redirectURL={"/performance-review-list"}
              />
              <ListItemSidebarSubButton
                text={"P.R. history"}
                icon={
                  <SvgIcon sx={{ mr: "4px" }}>
                    <PRHistoryIcon />
                  </SvgIcon>
                }
                redirectURL={"/performance-review-history"}
              />
              <ListItemSidebarSubButton
                text={"New form template"}
                icon={
                  <SvgIcon sx={{ mr: "4px" }}>
                    <NewFormTemplateIcon />
                  </SvgIcon>
                }
                redirectURL={"/performance-review-template"}
                // permission={"canView_PerformanceReviewCreateTemplate"}
              />
            </AccordionDetails>
          </AccordionSidebar>
          <AccordionSidebar permission={"canView_Recruitment"}>
            <AccordionSummarySidebar>
              <SvgIcon
                sx={{
                  mr: "8px",
                  width: "16px",
                  pl: sidebarStore.open ? "" : "6px",
                }}
                viewBox="0 1 18 16"
              >
                <RecruitmentIcon />
              </SvgIcon>
              <Typography>{sidebarStore.open ? `Recruitment` : ``}</Typography>
            </AccordionSummarySidebar>
            <AccordionDetails>
              <ListItemSidebarSubButton
                text={"Recruitment landing"}
                icon={
                  <SvgIcon
                    sx={{ mr: "8px", width: "16px" }}
                    viewBox="0 1 17 16"
                  >
                    <RecruitmentLandingIcon />
                  </SvgIcon>
                }
                redirectURL={"/recruitment-landing"}
              />
              <ListItemSidebarSubButton
                text={"Candidate list"}
                icon={
                  <SvgIcon
                    sx={{ mr: "8px", width: "16px" }}
                    viewBox="0 1 16 15"
                  >
                    <CandidateListIcon />
                  </SvgIcon>
                }
                redirectURL={"/candidates"}
              />
            </AccordionDetails>
          </AccordionSidebar>
          <Divider
            sx={{
              width: sidebarStore.open ? "210px" : "39px",
              marginLeft: sidebarStore.open ? "24px" : "24px",
              border: styleStore.getSideBarDivider(),
            }}
          />
          <SidebarSection title={""} permission={"canView_CompanySection"}>
            <ListItemSidebarButton
              css={{ mt: "-20px" }}
              text={"Files & Documents"}
              icon={
                <SvgIcon
                  sx={{ ml: "2px", width: "14px", stroke: "none" }}
                  viewBox="0 0 14 16"
                >
                  <FilesAndDocumentsIcon />
                </SvgIcon>
              }
              redirectURL={"/processes-and-procedures"}
            />
            <ListItemSidebarButton
              text={"Structure"}
              icon={
                <SvgIcon sx={{ mt: "2px", width: "16px" }} viewBox="0 0 16 16">
                  <StructureIcon />
                </SvgIcon>
              }
              redirectURL={"/structure"}
            />
            <ListItemSidebarButton
              text={"Path to success"}
              icon={
                <SvgIcon
                  sx={{ width: "16px", stroke: "none" }}
                  viewBox="0 -2 16 8"
                >
                  <PathToSuccessIcon />
                </SvgIcon>
              }
              redirectURL={"/path-to-success"}
            />
          </SidebarSection>
         
        </Box>
        <Box sx={{marginTop:'auto', marginBottom:'14px'}}>
          <ListItemSidebarButton
              text={"Settings"}
              white={true}
              icon={
                <SvgIcon
                  sx={{ width: "18px", stroke: "none" }}
                  viewBox="0 0 18 18"
                >
                  {styleStore.light_mode ? <SettingsLightIcon /> : <SettingsIcon />}
                </SvgIcon>
              }
              redirectURL={"/settings"}
            />
          </Box>
      </Drawer>
    </Box>
  );
});

export default Desktop;
