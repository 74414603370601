import React from "react";
import Desktop from "./Desktop";

type PaginationProps = {
  count: number;
  page: number;
  onChange: any;
};
const PaginationStyled = ({ count, page, onChange }: PaginationProps) => {
  return <Desktop count={count} page={page} onChange={onChange} />;
};

export default PaginationStyled;
