import { Box } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { styleStore } from "../Helpers/MobX/StyleStore";

const BoxStyled = observer(({ css, children }: any) => {
  return (
    <Box
      style={{
        backgroundColor: styleStore.getCardBackgroundPrimary(),
        borderRadius: "15px",
        width: "388px",
        padding: "0px",
        ...css,
      }}
    >
      {children}
    </Box>
  );
});

export default BoxStyled;

//E0E0E0 pozadina kartice
