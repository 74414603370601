import ListItemButton from "@mui/material/ListItemButton";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { useStores } from "../../Helpers/MobX/hooks";
import { observer } from "mobx-react-lite";
import { Box } from "@mui/material";
import { sidebarStore } from "../../Helpers/MobX/SidebarStore";

const ListItemSidebarButton = observer((params: any) => {
  const navigate = useNavigate();
  const { userStore } = useStores();
  const { styleStore } = useStores();
  return (
    <>
      {params.permission !== "" && params.permission !== undefined ? (
        userStore.getPermissionByName(params.permission) ? (
          <ListItemButton
            sx={{
              color: styleStore.getIconSidebarButtonColor_hover(),
            }}
            onClick={() => {
              navigate(params.redirectURL);
            }}
          >
            {params.icon}
            <Typography
              sx={{
                color: styleStore.getNameTitleColor(),
              }}
            >
              {params.text}
            </Typography>
          </ListItemButton>
        ) : (
          ""
        )
      ) : (
        <ListItemButton
          sx={{
            ...params.css,
            color: window.location.pathname == params.redirectURL ? styleStore.getIconSidebarButtonColor_hover() : params.white? styleStore.getIconSidebarButtonColor_hover() : styleStore.getIconSidebarButtonColor(),
            stroke: window.location.pathname == params.redirectURL ? styleStore.getIconSidebarButtonColor_hover() : styleStore.getIconSidebarButtonColor(),
            backgroundColor: window.location.pathname === params.redirectURL ? styleStore.getBackgroundColor_hover(): styleStore.getBackgroundColor(),
            ":hover": {
              color: styleStore.getIconSidebarButtonColor_hover(),
              stroke: styleStore.getIconSidebarButtonColor_hover(),
              backgroundColor: styleStore.getBackgroundColor_hover(),
              borderRadius: "5px"
            },
            ml: "19px",
            width: sidebarStore.open ? "220px" : "50px"
          }}
          onClick={() => {
            navigate(params.redirectURL);
          }}
        >
          <Box sx={{ mr: "8px" }}>{params.icon}</Box>
          <Typography>{sidebarStore.open ? params.text : <></>}</Typography>
        </ListItemButton>
      )}
    </>
  );
});
export default ListItemSidebarButton;
