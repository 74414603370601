import { Box } from "@mui/material";
import { useStores } from "../../Helpers/MobX/hooks";

const LineTextbox = (props: any) => {
  const { styleStore } = useStores();
  return (
    <>
      <Box
        component="img"
        alt="Qcerris Logo"
        sx={{ width: 1 }}
        src={styleStore.getLogo(true)}
      />
    </>
  );
};

export default LineTextbox;
