import AccordionSummary from "@mui/material/AccordionSummary";
import { useStores } from "../../Helpers/MobX/hooks";
import { observer } from "mobx-react-lite";
import { sidebarStore } from "../../Helpers/MobX/SidebarStore";
const AccordionSummarySidebar: React.FC<{ children: any }> = observer(
  ({ children }) => {
    const { styleStore } = useStores();
    return (
      <>
        {
          <AccordionSummary
            sx={{
              color: styleStore.getIconSidebarButtonColor(),
              stroke: styleStore.getIconSidebarButtonColor(),
              height: "40px",
              ml: "19px",
              width: sidebarStore.open ? "220px" : "50px",
              borderRadius: "5px",
              ":hover": {
                color: styleStore.getIconSidebarButtonColor_hover(),
                stroke: styleStore.getIconSidebarButtonColor_hover(),
                backgroundColor: styleStore.getBackgroundColor_hover(),
              },
              "& .MuiAccordionSummary-expandIconWrapper": {
                // marginRight: "7px",
              },
            }}
            expandIcon={
              sidebarStore.open ? (
                <img src={styleStore.getSideBarIcon()} alt="sideBarIcon" />
              ) : (
                <></>
              )
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            {children}
          </AccordionSummary>
        }
      </>
    );
  }
);
export default AccordionSummarySidebar;
