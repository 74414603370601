import { Typography } from "@mui/material";
import { useStores } from "../Helpers/MobX/hooks";
import { observer } from "mobx-react-lite";

const Title = observer((params: any) => {
  const { styleStore } = useStores();
  return (
    <Typography
      variant="h1"
      gutterBottom
      align="left"
      fontWeight={500}
      fontSize={24}
      sx={{ color: styleStore.getLinkTextColor(), ml: "18px" }}
    >
      {params.label}
    </Typography>
  );
});

export default Title;
