import Button from "@mui/material/Button";
import { useStores } from "../../Helpers/MobX/hooks";

const SecondaryFileDialogButton = (params: any) => {
  const { styleStore } = useStores();

  function onReaderLoad(event: any) {
    console.log("props obj:", params);
    // var obj = JSON.parse(event.target.result);
    // Apis.postSheet(obj);
  }

  return (
    <Button
      variant="contained"
      component="label"
      sx={{
        width: params.width,

        height: "40px",
        borderRadius: "15px",
        backgroundColor: styleStore.getSecondaryButtonColor(),
        color: styleStore.getSecondaryButtonTextColor(),
        "&:hover": {
          backgroundColor: styleStore.getSecondaryButtonColor_hover(),
          color: styleStore.getSecondaryButtonTextColor(),
        },
        "&:active": {
          backgroundColor: styleStore.getSecondaryButtonColor_click(),
          color: styleStore.getSecondaryButtonTextColor_click(),
        },
      }}
      onClick={params.onClick}
    >
      {params.text}
      {params.onClick ? <></> : 
      <input
        type="file"
        hidden
        accept={"image/jpg, application/pdf"}
        onChange={(e) => {
          if (e?.target?.files) {
            var reader = new FileReader();
            reader.onload = onReaderLoad;
            reader.readAsText(e.target.files[0]);
          }
        }}
      />}
    </Button>
  );
};
export default SecondaryFileDialogButton;
