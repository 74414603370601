import AutoCompleteStyledFilters from "../../Components/AutoCompleteStyledFilters";
import AutocompleteStyled from "../../Components/AutoComplete/AutocompleteStyled";
import { Box, Grid, Button, Modal, Typography } from "@mui/material";
import PerformanceReviewCard from "../../Components/Cards/PerformanceReviewCard";
import { observer } from "mobx-react-lite";
import Title from "../../Components/Title";
import { styleStore } from "../../Helpers/MobX/StyleStore";
import CheckboxStyled from "../../Components/CheckboxStyled";
import PaginationStyled from "../../Components/Pagination/PaginationStyled";
import P from "../../Assets/Fonts/P";
import { useNavigate } from "react-router-dom";
import { userStore } from "../../Helpers/MobX/UserStore";
import { useState } from "react";
import SecondaryFileDialogButton from "../../Components/Button/SecondaryFileDialogButton";
import PrimaryButton from "../../Components/Button/PrimaryButton";
import InputFieldOutlined from "../../Components/Textbox/InputFieldOutlined";
import InputFieldNotOutlined from "../../Components/Textbox/InputFieldNotOutlined";

const Desktop = observer(
  () => {
    const navigate = useNavigate();
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleDeleteModalClose = () => setOpenDeleteModal(false);

  const [deleteRequestInfo, setDeleteRequestInfo] = useState<string>('');
  const [cancelRequestInfo, setCancelRequestInfo] = useState<string>('');

  const [requiredCheck, setRequiredCheck] = useState<any>({boarder:0, borderColor:"red"});
  const [requiredCheck1, setRequiredCheck1] = useState<any>({boarder:0, borderColor:"red"});


  const [openCancelModal, setOpenCancelModal] = useState(false);
  const handleOpenCancelModal = () => setOpenCancelModal(true);
  const handleCancelModalClose = () => setOpenCancelModal(false);
    return (
      <>
        <Box pl={"25px"} sx={{ mt: "70px" }}>
          <Grid sx={{ minWidth: 300 }} container>
            <AutocompleteStyled
              label="Search"
              onChange={(event: any, value: any) => {
                // setUrlParams({ ...urlParams, page: 1, search: value });
              }}
              options={[]}
              onClick={() => {}}
              width='360px'
            />
          </Grid>

          <Grid
            container
            spacing={{ xs: "24px" }}
            sx={{ pt: "24px" }}
            columns={6}
          >

              <Grid item key={1}>
                <PerformanceReviewCard
                  users={userStore.getCurrentUser()}
                  onClick={() => {
                    navigate('/performance-review-employee')
                  }}
                  status='green'
                  pillText='Completed'
                  openModal={handleOpenDeleteModal}
                  openCancelModal={handleOpenCancelModal}
                />
              </Grid>
              <Grid item key={2}>
                <PerformanceReviewCard
                  users={userStore.getCurrentUser()}
                  onClick={() => {
                    navigate('/performance-review-employee')
                  }}
                  status='yellow'
                  pillText='Pending'
                  openModal={handleOpenDeleteModal}
                  openCancelModal={handleOpenCancelModal}
                />
              </Grid>
             
              <Grid item key={2}>
                <PerformanceReviewCard
                  users={userStore.getCurrentUser()}
                  onClick={() => {
                    navigate('/performance-review-employee')
                  }}
                  status='red'
                  pillText='Declined'
                  openModal={handleOpenDeleteModal}
                  openCancelModal={handleOpenCancelModal}
                />
              </Grid>
              <Grid item key={2}>
                <PerformanceReviewCard
                  users={userStore.getCurrentUser()}
                  onClick={() => {
                    navigate('/performance-review-employee')
                  }}
                  status='blue'
                  pillText='New'
                  openModal={handleOpenDeleteModal}
                  openCancelModal={handleOpenCancelModal}
                />
              </Grid>
          </Grid>
          <PaginationStyled
            count={Math.ceil(300 / 30)}
            page={1}
            onChange={() => {}}
          />
           <Modal
        open={openDeleteModal}
        onClose={handleDeleteModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ backgroundColor:'#313131 80%' , backdropFilter: "blur(3px)",}}
      >
        <>
        <Box sx={{backgroundColor:styleStore.getCardBackgroundPrimary(),
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '448px',
        border: '1px',
        borderRadius:'15px',
        p: 4,
        }}>
          <Typography id="modal-modal-title" sx={{color:styleStore.getNameTitleColor(), fontSize:'16px', fontWeight:'bold'}}>
          Delete Performance Review
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, color:styleStore.getQcerrisTextSecondary(), fontSize:'14px' }}>
          Are you sure you want to delete this performance review?
          </Typography>
          <InputFieldNotOutlined
                  css={{ width: "445px", marginTop:'8px', inputBackgroundColor:styleStore.getInputBackgroundPrimary(), ...requiredCheck1}}
                  name={"Reason of delete a performance review:"}
                  value={deleteRequestInfo}
                  placeholder='Write a reason for deleting this review...'
                  rows = {3}
                  onChange={(e: any) => {
                    setDeleteRequestInfo(
                      e.target.value,
                    );
                  }}
                />     
        </Box>
        <Box sx={{
          display:'flex',
          flexDirection:'row',
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        mt:'180px',
        ml:'140px',
        width:'800px'
        }}>
          <SecondaryFileDialogButton
            text="Cancel"
            onClick={() => {
              handleDeleteModalClose()
            }}
          />
          <Box sx={{mr:'24px'}}/>
        <PrimaryButton
          text={"Delete"}
          onClick={() => {
            deleteRequestInfo === '' ? setRequiredCheck1({border:1, borderColor:"red", borderRadius: "15px"}) : setRequiredCheck1({border:0})
          }}
        />
        
          </Box>
          </>
      </Modal>
      <Modal
        open={openCancelModal}
        onClose={handleCancelModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ backgroundColor:'#313131 80%' , backdropFilter: "blur(3px)",}}
      >
        <>
        <Box sx={{backgroundColor:styleStore.getCardBackgroundPrimary(),
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '448px',
        border: '1px',
        borderRadius:'15px',
        p: 4,
        }}>
          <Typography id="modal-modal-title" sx={{color:styleStore.getNameTitleColor(), fontSize:'16px', fontWeight:'bold'}}>
          Decline Performance Review
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, color:styleStore.getQcerrisTextSecondary(), fontSize:'14px' }}>
          Are you sure you want to decline this performance review?
          </Typography>
          <InputFieldNotOutlined
                  css={{ width: "445px", marginTop:'8px', inputBackgroundColor:styleStore.getInputBackgroundPrimary(), ...requiredCheck}}
                  name={"Reason of deny a performance review:"}
                  value={cancelRequestInfo}
                  placeholder='Write a reason for declining this review...'
                  rows = {3}
                  onChange={(e: any) => {
                    setCancelRequestInfo(
                      e.target.value,
                    );
                  }}
                />     
        </Box>
        <Box sx={{
          display:'flex',
          flexDirection:'row',
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        mt:'180px',
        ml:'140px',
        width:'800px'
        }}>
          <SecondaryFileDialogButton
            text="Cancel"
            onClick={() => {
              handleCancelModalClose()
            }}
          />
          <Box sx={{mr:'24px'}}/>
        <PrimaryButton
          text={"Decline"}
          onClick={() => {
            cancelRequestInfo === '' ? setRequiredCheck({border:1, borderColor:"red", borderRadius: "15px"}) : setRequiredCheck({border:0, borderColor:"red", borderRadius: "15px"})
          }}
        />
        
          </Box>
          </>
      </Modal>
        </Box>
      </>
    );
  }
);

export default Desktop;
