import { makePersistable, hydrateStore } from "mobx-persist-store";
import SearchLight from "../../Assets/img/Icons/Search blue.svg";
import SearchDark from "../../Assets/img/Icons/Search white.svg";
import closeIconLight from "../../Assets/img/closeIconLight.png";
import LogoLight from "../../Assets/img/Icons/QCERRIS blue.svg";
import LogoDark from "../../Assets/img/Icons/QCERRIS white.svg";
import LogoLightLetter from "../../Assets/img/Icons/Q blue.svg";
import LogoDarkLetter from "../../Assets/img/Icons/Q white.svg";
import closeIconDark from "../../Assets/img/closeIconDark.png";
import uploadDark from "../../Assets/img/uploadDark.png";
import { makeAutoObservable, action } from "mobx";
import upload from "../../Assets/img/upload.png";
import light from "../../Assets/img/light.png";
import dark from "../../Assets/img/dark.png";
import sidebarLight from "../../Assets/img/sidebarLight.png";
import sidebarDark from "../../Assets/img/sidebarDark.png";
import sideBarToggleLight from "../../Assets/img/sideBarToggleLight.png";
import sideBarToggleDark from "../../Assets/img/sideBarToggleDark.png";
import sideBarToggleOpenDark from "../../Assets/img/sideBarToggleOpenDark.png";
import sideBarToggleOpenLight from "../../Assets/img/sideBarToggleOpenLight.png";
import TreeDark from "../../Assets/img/dashboard.svg"
import TreeLight from "../../Assets/img/dashboardLight.svg"
import { sidebarStore } from "./SidebarStore";
import dashboardImgDark from "../../Assets/img/dashboardImgDark.png"
import dashboardImgLight from "../../Assets/img/dashboardImgDark.png"

export class StyleStore {
  light_mode: boolean = true;

  constructor() {
    this.light_mode = true;
    makeAutoObservable(this);
    makePersistable(this, {
      name: "styleStore",
      properties: [
        {
          key: "light_mode",
          serialize: (value) => {
            return value;
          },
          deserialize: (value) => {
            return value;
          },
        },
      ],
      storage: window.localStorage,
    });
  }
  @action setLightMode(param: boolean) {
    this.light_mode = param;
  }
  @action getLightMode() {
    return this.light_mode;
  }
  @action toggleLightMode() {
    this.light_mode = !this.light_mode;
  }
  @action getQcerrisRed() {
    return "#EB505B";
  }
  @action getBackgroundColor() {
    return this.light_mode ? "#EBEEF1" : "#212121";
  }
  @action getInputBackgroundPrimary() {
    return this.light_mode ? "#FBFBFB" : "#313131";
  }
  @action getInputBorder() {
    return this.light_mode ? "1px solid #E0E0E0 !important" : "none";
  }
  @action getInputBorderSecond() {
    return this.light_mode ? "1px solid #7C7C7C" : "1px solid #7C7C7C";
  }
  @action getAdditionalInfoBorder() {
    return this.light_mode ? "1px solid #C9C9C9" : "1px solid #464646";
  }
  @action getCardBackgroundPrimary() {
    return this.light_mode ? "#E4E9EE" : "#212121";
  }
  @action getUploadPng() {
    return this.light_mode ? upload : uploadDark;
  }

  @action getBlankProfile() {
    return this.light_mode ? light : dark;
  }
  @action getCloseIcon() {
    return this.light_mode ? closeIconLight : closeIconDark;
  }
 @action getCardLighterColor(){
  return this.light_mode ? "#0058DD": "#272727"
 }
 @action getCardDarkerColor(){
  return this.light_mode ? "#0044A9": "#212121"
 }
  @action getBackgroundColor_hover() {
    return this.light_mode ? "#D4DEEA" : "#313131";
  }
  @action getMainButtonColor() {
    return this.light_mode ? "#0044A9" : "#EB505B";
  }
  @action getMainButtonColor_hover() {
    return this.light_mode ? "#3369BA" : "#EF737C";
  }
  @action getMainButtonColor_click() {
    return this.light_mode ? "#0044A9" : "#EB505B";
  }
  @action getMainButtonTextColor() {
    return "#FBFBFB";
  }
  @action getMainButtonTextColor_hover() {
    return "#FBFBFB";
  }
  @action getMainButtonTextColor_click() {
    return this.light_mode ? "#618AC6" : "#EF737C";
  }
  @action getSecondaryButtonColor() {
    return this.light_mode ? "#D4DDEA" : "#505050";
  }
  @action getSecondaryButtonColor_hover() {
    return this.light_mode ? "#EAEEF5" : "#969696";
  }
  @action getSecondaryButtonColor_click() {
    return this.light_mode ? "#D4DDEA" : "#969696";
  }
  @action getSecondaryButtonTextColor() {
    return this.light_mode ? "#0044A9" : "#FBFBFB";
  }
  @action getSecondaryButtonTextColor_hover() {
    return this.light_mode ? "#2F66B8" : "#FBFBFB";
  }
  @action getSecondaryButtonTextColor_click() {
    return this.light_mode ? "#618AC6" : "#BDBDBD";
  }
  @action getLinkTextColor() {
    return this.light_mode ? "#0044A9" : "#EB505B";
  }
  @action getLinkTextColor_hover() {
    return this.light_mode ? "#0044A9" : "#EB505B";
  }
  @action getNameTitleColor() {
    return this.light_mode ? "#0044A9" : "#FAFAFA";
  }
  @action getCardBackgroundColor() {
    return this.light_mode ? "#FBFBFB" : "#212121";
  }
  @action getIconSidebarColor() {
    return this.light_mode ? "#0044A9" : "#424242";
  }
  @action getIconSidebarColor_hover() {
    return this.light_mode ? "#0044A9" : "#FAFAFA";
  }
  @action getIconSidebarButtonColor() {
    return this.light_mode ? "#0044A9" : "#7C7C7C";
  }
  @action getIconPRColor() {
    return this.light_mode ? "#0044A9" : "#7DB1FF";
  }
  @action getIconSidebarButtonColor_hover() {
    return this.light_mode ? "#0044A9" : "#FBFBFB";
  }
  @action getQcerrisRedShadow() {
    return "#C61D2E";
  }
  @action getQcerrisBlue() {
    return this.light_mode ? "#ffffff" : "#1D2733";
  }
  @action getQcerrisSecondaryBlue() {
    return "#1981C6";
  }
  @action getQcerrisSecondaryBlueShadow() {
    return "#146B9E";
  }
  @action getBorder() {
    return this.light_mode ? "#618AC6" : "#EB505B";
  }
  @action getBackgroundShadow() {
    return this.light_mode ? "#ffffff" : "#3D5165";
  }
  @action getLabel() {
    return this.light_mode ? "#666666" : "#C0C0C0";
  }
  @action getUnderline() {
    return this.light_mode ? "#C0C0C0" : "#3D5165";
  }
  @action getForegroundPrimary() {
    return this.light_mode ? "#000000" : "#ffffff";
  }
  @action getForegroundSecondary() {
    return this.light_mode ? "#ffffff" : "#000000";
  }
  @action getTextColorPrimary() {
    return this.light_mode ? "#212121" : "#fafafa";
  }
  @action getTextColorSecondary() {
    return "#7c7c7c";
  }
  @action getBackgroundPrimary() {
    return this.light_mode ? "#EBEEF1" : "#313131";
  }
  @action getCheckboxBorderColor()
  {
    return this.light_mode ? "#7C7C7C" : "#ffffff";
  }
  @action getDashboardImage(){
    return this.light_mode ? TreeLight : TreeDark;
  }
  @action getLogo(open: boolean) {
    // return this.light_mode ? LogoLight : LogoDark;
    return open
      ? this.light_mode
        ? LogoLight
        : LogoDark
      : this.light_mode
      ? LogoLightLetter
      : LogoDarkLetter;
  }
  @action getLogoLetter() {
    return this.light_mode ? LogoLightLetter : LogoDarkLetter;
  }
  @action getSearch() {
    return this.light_mode ? SearchLight : SearchDark;
  }
  @action getRoleColor(role: string) {
    if (
      role === "Marketing" ||
      role === "HR" ||
      role === "BizOps" ||
      role === "Biz Analyst"
    )
      return "#EB505B";
    if (role === "UX/UI" || role === "Designer") return "#CF59CA";
    if (
      role === "React" ||
      role === "React Native" ||
      role === "Flutter" ||
      role === "IOS" ||
      role === "Android" ||
      role === "FE Dev"
    )
      return "#FF8A00";
    if (
      role === ".Net" ||
      role === "Java" ||
      role === "Python" ||
      role === "Data Engineer" ||
      role === "Data Scientist" ||
      role === "Data Analyst"
    )
      return "#3A90C3";
    if (role === "PM" || role === "PO" || role === "Scrum master")
      return "#F6C61C";
    if (role === "DevOps" || role === "QA") return "#70C773";
  }
  @action rehydrate() {
    hydrateStore(this);
  }
  @action getFontH1() {
    return this.light_mode
      ? {
          // fontFamily: "Roboto",
          fontSize: "24px",
          fontWeight: 700,
          lineHeight: "28px",
          letterSpacing: "0em",
          // color: "#25416B",
        }
      : {
          // fontFamily: "Roboto",
          fontSize: "24px",
          fontWeight: 700,
          lineHeight: "28px",
          letterSpacing: "0em",
        };
  }
  @action getFontH3() {
    return this.light_mode
      ? {
          // fontFamily: "Roboto",
          fontSize: "20px",
          fontWeight: 400,
          lineHeight: "28px",
          letterSpacing: "0em",
          // color: "#25416B",
        }
      : {
          // fontFamily: "Roboto",
          fontSize: "20px",
          fontWeight: 400,
          lineHeight: "28px",
          letterSpacing: "0em",
        };
  }
  @action getFontH2() {
    return this.light_mode
      ? {
          // fontFamily: "Roboto",
          fontSize: "20px",
          fontWeight: 700,
          lineHeight: "23px",
          letterSpacing: "0em",
          // color: "#25416B",
        }
      : {
          // fontFamily: "Roboto",
          fontSize: "20px",
          fontWeight: 700,
          lineHeight: "23px",
          letterSpacing: "0em",
        };
  }
  @action getFontH4() {
    return this.light_mode
      ? {
          // fontFamily: "Roboto",
          fontSize: "16px",
          fontWeight: 400,
          lineHeight: "18.75px",
          letterSpacing: "0em",
        }
      : {
          // fontFamily: "Roboto",
          fontSize: "16px",
          fontWeight: 400,
          lineHeight: "18.75px",
          letterSpacing: "0em",
        };
  }
  @action getFontParagraph() {
    return this.light_mode
      ? {
          // fontFamily: "Roboto",
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "19px",
          letterSpacing: "0em",
          // color: this.getQcerrisTextInputs(),
        }
      : {
          // fontFamily: "Roboto",
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "19px",
          letterSpacing: "0em",
        };
  }
  @action getFontLink() {
    return this.light_mode
      ? {
          // fontFamily: "Roboto",
          fontSize: "14px",
          fontWeight: 700,
          lineHeight: "16px",
          letterSpacing: "0em",
          color: "#25416B",
        }
      : {
          // fontFamily: "Roboto",
          fontSize: "14px",
          fontWeight: 700,
          lineHeight: "16px",
          letterSpacing: "0em",
        };
  }
  @action getFontDescription() {
    return this.light_mode
      ? {
          // fontFamily: "Roboto",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "16px",
          letterSpacing: "0em",
          color: this.getQcerrisTextSecondary(),
        }
      : {
          // fontFamily: "Roboto",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "16px",
          letterSpacing: "0em",
          color: this.getQcerrisTextSecondary(),
        };
  }
  @action getQcerrisTextInputs() {
    return this.light_mode ? "#212121" : "#FBFBFB";
  }
  @action getQcerrisTextSecondary() {
    return this.light_mode ? "#7C7C7C" : "#7C7C7C";
  }
  @action getDatePicker() {
    return this.light_mode ? "#618AC6" : "#7C7C7C";
  }
  @action getDatePickerInput() {
    return this.light_mode ? "#618AC6" : "#FBFBFB";
  }
  @action getDropdownIcons() {
    return this.light_mode ? "#618AC6" : "#FBFBFB";
  }
  @action getActiveText() {
    return this.light_mode ? "#618AC6" : "#7DB1FF";
  }
  @action getTabColor() {
    return this.light_mode ? "#D4DDEA" : "#212121";
  }
  @action getSideBarIcon() {
    return this.light_mode ? sidebarLight : sidebarDark;
  }
  @action getSideBarDivider() {
    return this.light_mode ? "1px solid #8DAAD5" : "1px solid #787878";
  }
  @action getSideBarToggle() {
    if (sidebarStore.open === true) {
      return this.light_mode ? sideBarToggleLight : sideBarToggleDark;
    } else {
      return this.light_mode ? sideBarToggleOpenLight : sideBarToggleOpenDark;
    }
  }
  @action getDashboardImg() {
    return this.light_mode ? dashboardImgLight : dashboardImgDark
  }
}

export const styleStore = new StyleStore();
