import Button from "@mui/material/Button";
import { useStores } from "../../Helpers/MobX/hooks";

const PrimaryButton = (params: any) => {
  const { styleStore } = useStores();
  return (
    <Button
      disabled={params.disabled}
      variant="contained"
      sx={{
        width: params.width,
        height: "40px",
        borderRadius: "15px",
        backgroundColor: styleStore.getMainButtonColor(),
        color: styleStore.getMainButtonTextColor(),
        "&:hover": {
          backgroundColor: styleStore.getMainButtonColor_hover(),
          color: styleStore.getMainButtonTextColor(),
        },
        "&:active": {
          backgroundColor: styleStore.getMainButtonColor_click(),
          color: styleStore.getMainButtonTextColor_click(),
        },
        ...params.sx,
      }}
      onClick={params.onClick}
    >
      {params.text}
    </Button>
  );
};
export default PrimaryButton;
