import TabsStyled from "../../../Components/TabsStyled";
import UserForm from "../../../Components/UserForm";
import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import Title from "../../../Components/Title";
import { Avatar } from "@mui/material";
import { Box } from "@mui/system";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import TitleCard from "../../../Components/Cards/TitleCard";
import Textbox from "../../../Components/Textbox/Textbox";
import PrimaryButton from "../../../Components/Button/PrimaryButton";
import DatePickerDesktop from "../../../Components/DatePicker/DatePickerDesktop";
import AutocompleteStyled from "../../../Components/AutocompleteStyled";
import TableStyled from "../../../Components/Table/TableStyled";
import TableCellHead from "../../../Components/Table/TableCellHead";
import TableCellBody from "../../../Components/Table/TableCellBody";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import DownloadButton from "../../../Components/Button/DownloadButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import SecondaryIconButton from "../../../Components/Button/SecondaryIconButton";
import LabelButton from "../../../Components/Button/LinkButton";
import ModalStyled from "../../../Components/ModalStyled";
import moment from "moment";
import { useStores } from "../../../Helpers/MobX/hooks";
import { observer } from "mobx-react-lite";
import { Tab } from "@mui/material";
import { useState } from "react";
import Documents from "./PageComponents/Documents";

const Desktop = observer((params: any) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const handleTabChange = (e: any, tabIndex: number) => {
    console.log(tabIndex);
    setCurrentTabIndex(tabIndex);
  };

  return (
    <>
      <Box display={"flex"} sx={{ pb: "24px", pl: "24px" }}>
        <TabsStyled value={currentTabIndex} onChange={handleTabChange}>
          <Tab label="General info" value={0} sx={{ mr: "24px" }} />
          <Tab label="Documents" value={1} />
        </TabsStyled>
      </Box>
      {currentTabIndex === 0 && (
        <Box sx={{}}>
          <UserForm />
          <Box sx={{ mr: "1468px", mt: "-42px" }}>
            <PrimaryButton
              width={"152px"}
              sx={{ ml: "20px" }}
              onClick={() => {
                params.Functions.CreateUser();
              }}
              text={"Create user"}
            />
          </Box>
        </Box>
      )}
      {currentTabIndex === 1 && <Documents />}
    </>
  );
});
export default Desktop;
