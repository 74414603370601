import { Box, Card, CardContent, IconButton, Typography } from "@mui/material";
import React from "react";
import { AvatarIcon } from "../Avatar/AvatarIcon";
import { useStores } from "../../Helpers/MobX/hooks";
import { observer } from "mobx-react-lite";
import SmallPill from "../SmallPill";
import { ReactComponent as DeleteDocIcon } from '../../Assets/img/Icons/DeleteBlue.svg';
import { ReactComponent as Pen } from '../../Assets/img/Icons/Pen.svg';
import { ReactComponent as EyeIcon } from '../../Assets/img/Icons/eye.svg';
import { ReactComponent as PlusIcon} from '../../Assets/img/Icons/+.svg'

const CardStyled: React.FC<{
  users: any;
  onClick: any;
  status:string;
  pillText:string;
  openModal:any;
  openCancelModal:any;
  title:string;
  last_updated:string;
  created_by:string;
}> = observer(({ users, css, onClick, status, pillText, openModal, openCancelModal, title, last_updated, created_by }: any) => {
  const { styleStore } = useStores();
  console.log(status)
  return (
    <Card
    variant="outlined"
    
    sx={{
      width: "250px !important",
      height: "120px",
      backgroundColor: styleStore.getCardBackgroundColor(),
      border: 0,
      ":hover": {
        boxShadow: "0 0 12px 3px rgba(0, 0, 0, 0.10)",
        cursor: "pointer",
      },
      borderRadius: "15px",
      ...css,
    }}
  >
    <Box display="flex" sx={{width:'100%', height:'100%', justifyContent:'ceter', alignItems:'center' }}>
      
      <CardContent sx={{width:'100%',height:'100%', justifyContent:'ceter', alignItems:'center' }}>

        <IconButton sx={{width:'100%',height:'100%', justifyContent:'ceter', alignItems:'center' }} onClick={openCancelModal}>
              <PlusIcon style={{fill:styleStore.getNameTitleColor()}}/>
            </IconButton>

      </CardContent>
      
    </Box>
  </Card>
  );
});

export default CardStyled;
