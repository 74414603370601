import { Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, tableCellClasses } from "@mui/material";
import Desc from "../Assets/Fonts/Desc";
import P from "../Assets/Fonts/P";
import BoxStyled from "../Components/BoxStyled";
import InputFieldStyled from "../Components/Textbox/InputFieldStyled";
import PrimaryButton from "../Components/Button/PrimaryButton";
import { useState } from "react";
import DatePickerStyled from "../Components/DatePicker";
import dayjs from "dayjs";
import AutocompleteStyled from "../Components/AutoComplete/AutocompleteStyled";
import { observer } from "mobx-react-lite";
import { useStores } from "../Helpers/MobX/hooks";
import TravelRequestEntry from "../Components/TravelRequestEntry";
import PerformanceReviewEntry from "./PerformanceReviewEntry";



interface ITravelRequest {
  tripTitle: string;
  employees:string;
  location:string;
  start_date:string,
  end_date:string,
  reason:string;
  additionalInfo:string;
}



const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const PerformanceReviewProfile = observer(() => {

    const {styleStore} = useStores();
      const [searchVal, setSearchVal] = useState<string>("");

      const StyledTableCell = observer(styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: styleStore.getCardBackgroundColor(),
          color: styleStore.getTextColorSecondary(),
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      })));

      const StyledCenteredTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: styleStore.getBackgroundColor(),
          color: styleStore.getTextColorSecondary(),
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));

      
    return (
       
            <TableContainer sx ={{ width: '1622px', ml:'24px', borderRadius:'15px 15px 0 0'}}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Requested by</StyledTableCell>
            <StyledTableCell style={{paddingLeft:'11px'}}>Reason</StyledTableCell>
            <StyledTableCell style={{paddingLeft:'30px'}}>Request date</StyledTableCell>
            <StyledTableCell style={{paddingLeft:'45px'}}>Status</StyledTableCell>
            <StyledTableCell style={{paddingLeft:'60px'}}><Box>End date</Box></StyledTableCell>
            <StyledTableCell ></StyledTableCell>
            <StyledTableCell ></StyledTableCell>
            <StyledTableCell ></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{width:'1622px'}}>
         <PerformanceReviewEntry reason='Other' requesting= 'test' requestDate= '01/01/2024' endDate='31/12/2023' status='Completed'/>
         <PerformanceReviewEntry reason='Other' requesting= 'test' requestDate= '01/01/2024' endDate='31/12/2023' status='Pending'/>
         <PerformanceReviewEntry reason='Other' requesting= 'test' requestDate= '01/01/2024' endDate='31/12/2023' status='Declined'/>
        </TableBody>
      </Table>
    </TableContainer>
    )
 })
 
 export default PerformanceReviewProfile;