import { Box } from "@mui/material";

type PillType = 'green' | 'yellow' | 'red'

interface IPillParams{
    pillType:PillType,
    label:string;
}
const Pill = ({pillType, label}:IPillParams) => {
return(
<Box sx={{
    width: label === 'Completed'? '112px': label ==='Pending'? '84px' : '86px',
    height:'30px',
    display:'flex',
    borderRadius:'5px',
    justifyContent:'center',
    alignItems:'center',
    backgroundColor: pillType=== 'yellow'?'#F6C61C33': pillType==='green'? '#70C77333': '#EB6C7833',
    color:pillType=== 'yellow'?'#F6C61C': pillType==='green'? '#70C773': '#EB6C78',
    
}}>
{
label
}

</Box>
)
}

export default Pill;