import { Box, Grid, InputAdornment, IconButton, Button } from "@mui/material";
import AutoCompleteStyled1 from "../Components/AutoComplete/AutocompleteStyledSecondary";
import InputFieldStyled from "./Textbox/InputFieldStyled";
import { styleStore } from "../Helpers/MobX/StyleStore";
import ClearIcon from "@mui/icons-material/Clear";
import { useStores } from "../Helpers/MobX/hooks";
import Input from "../Components/Input/Input";
import DatePickerStyled from "./DatePicker";
import { observer } from "mobx-react-lite";
import Desc from "../Assets/Fonts/Desc";
import BoxStyled from "./BoxStyled";
import P from "../Assets/Fonts/P";
import CheckBox from "./CheckBox";
import { useState } from "react";
import { toJS } from "mobx";

const UserForm = observer(() => {
  const { createUserStore } = useStores();
  const [val, setVal] = useState([]);
  const [number, setNumber] = useState(3);
  const [oldNumber, setOldNumber] = useState([2]);

  const handleAdd = () => {
    const abc: any = [...val, []];
    setVal(abc);
    setNumber((prev) => prev + 1);
    setOldNumber((prev) => [...prev, number]);
  };
  const handleRemove = () => {
    setVal(val.slice(0, val.length - 1));
    createUserStore.getFormData().other_clients.pop();
  };

  const roles = ["Super HR", "HR", "Client", "Regular user"];

  const padding = {
    pl: "16px",
    pb: "8px",
    pt: "16px",
  };
  const paddingTopSide = {
    pl: "16px",
    pb: "8px",
  };

  const paddingTitle = {
    pl: "16px",
    pt: "16px",
    pb: "24px",
  };

  function setOpenPositionModal(arg0: boolean) {
    throw new Error("Function not implemented.");
  }

  return (
    <Box sx={{ display: "flex", pl: "24px" }}>
      <Box className="left-box">
        <Grid item container direction="column" xs={12} sm={4} spacing={"24px"}>
          <Grid item>
            <BoxStyled>
              <P text={"User information"} css={{ ...paddingTitle }} />
              <Desc text={"First Name *"} css={{ ...paddingTopSide }} />
              <InputFieldStyled
                css={{ width: "356px" }}
                name={"first_name"}
                value={createUserStore.formData.first_name}
                onChange={(event: any) => {
                  createUserStore.formData.first_name = event.target.value;
                }}
              />
              <Desc text={"Last Name *"} css={{ ...padding }} />
              <InputFieldStyled
                css={{ width: "356px" }}
                name={"last_name"}
                value={createUserStore.formData.last_name}
                onChange={(event: any) => {
                  createUserStore.formData.last_name = event.target.value;
                }}
              />
              <Grid container>
                <Grid item xs={6}>
                  <Desc text={"Date of Birth"} css={{ ...padding }} />
                  <DatePickerStyled
                    css={{ width: "170px", ml: "10px" }}
                    name={"date_of_birth"}
                    defaultValue={null}
                    value={createUserStore.formData.date_of_birth}
                    onChange={(e: any) => {
                      createUserStore.setFormData(
                        "date_of_birth",
                        e?.format("YYYY-MM-DD")
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Desc
                    text={"Gender"}
                    css={{ pb: "8px", pt: "16px", pl: "8px" }}
                  />
                  <AutoCompleteStyled1
                    css={{ width: "170px", ml: "8px" }}
                    options={["Male", "Female", "Other"]}
                    name={"gender"}
                  />
                </Grid>
              </Grid>
              <Desc text={"User role"} css={{ ...padding }} />
              <AutoCompleteStyled1
                css={{ width: "356px", pl: "16px" }}
                options={roles}
                name={"role"}
                defaultValue={roles[3]}
              />
              <Desc text={"Bank Account"} css={{ ...padding }} />
              <InputFieldStyled
                css={{ width: "356px", mb: "24px" }}
                name={"bank_account"}
                value={createUserStore.formData.bank_account}
                onChange={(event: any) => {
                  createUserStore.formData.bank_account = event.target.value;
                }}
              />
            </BoxStyled>
          </Grid>
        </Grid>
      </Box>

      <Box className="middle-left-box" sx={{ pl: "24px" }}>
        <Grid item container direction="column" xs={12} sm={4} spacing={"24px"}>
          <Grid item>
            <BoxStyled>
              <P text={"User Profile"} css={{ ...paddingTitle }} />
              <Grid container>
                <Grid item>
                  <Box
                    sx={{
                      width: "96px",
                      height: "96px",
                      pl: "14px",
                      pb: "21px",
                    }}
                  >
                    {createUserStore.getProfileImgUrl() ? (
                      <>
                        <img
                          src={createUserStore.getProfileImgUrl()}
                          style={{
                            width: "96px",
                            height: "96px",
                            borderRadius: "64px",
                          }}
                          alt=""
                        />
                      </>
                    ) : (
                      <>
                        <img src={styleStore.getBlankProfile()} alt="blank" />
                      </>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={8}>
                  <Desc text={"Upload photo"} css={{ pl: "24px", pb: "8px" }} />
                  <Input name={"avatar"} />
                </Grid>
              </Grid>
            </BoxStyled>
          </Grid>
          <Grid item>
            <BoxStyled>
              <P text={"Contact information"} css={{ ...paddingTitle }} />
              <Desc text={"Company Email *"} css={{ ...paddingTopSide }} />
              <InputFieldStyled
                css={{ width: "356px" }}
                name={"email"}
                value={createUserStore.formData.email}
                onChange={(event: any) => {
                  createUserStore.formData.email = event.target.value;
                }}
              />
              <Desc text={"Private Email"} css={{ ...padding }} />
              <InputFieldStyled
                css={{ width: "356px" }}
                name={"private_email"}
                value={createUserStore.formData.private_email}
                onChange={(event: any) => {
                  createUserStore.formData.private_email = event.target.value;
                }}
              />
              <Desc text={"Address"} css={{ ...padding }} />
              <InputFieldStyled
                css={{ width: "356px" }}
                name={"address"}
                value={createUserStore.formData.address}
                onChange={(event: any) => {
                  createUserStore.formData.address = event.target.value;
                }}
              />
              <Desc text={"Phone number"} css={{ ...padding }} />
              <InputFieldStyled
                css={{ width: "356px", mb: "24px" }}
                name={"phone_number"}
                value={createUserStore.formData.phone_number}
                onChange={(event: any) => {
                  createUserStore.formData.phone_number = event.target.value;
                }}
              />
            </BoxStyled>
          </Grid>
        </Grid>
      </Box>

      <Box className="middle-right-box" sx={{ pl: "24px" }}>
        <Grid item container direction="column" xs={12} sm={4} spacing={"24px"}>
          <Grid item>
            <BoxStyled>
              <P text={"Emergency contact"} css={{ ...paddingTitle }} />
              <Desc
                text={"Emergency contact name and lastname"}
                css={{ ...paddingTopSide }}
              />
              <InputFieldStyled
                css={{ width: "356px" }}
                name={"emergency_name"}
                value={createUserStore.formData.emergency_name}
                onChange={(event: any) => {
                  createUserStore.formData.emergency_name = event.target.value;
                }}
              />
              <Desc text={"Emergency number"} css={{ ...padding }} />
              <InputFieldStyled
                css={{ width: "356px", mb: "24px" }}
                name={"emergency_number"}
                value={createUserStore.formData.emergency_number}
                onChange={(event: any) => {
                  createUserStore.formData.emergency_number =
                    event.target.value;
                }}
              />
            </BoxStyled>
          </Grid>
          <Grid item>
            <BoxStyled>
              <P text={"Employment information"} css={{ ...paddingTitle }} />
              <Grid container>
                <Grid item xs={6}>
                  <Desc
                    text={"Internship start date"}
                    css={{ ...paddingTopSide }}
                  />
                  <DatePickerStyled
                    css={{ width: "170px", ml: "10px" }}
                    name={"internship_start"}
                    defaultValue={null}
                    value={createUserStore.formData.internship_start}
                    onChange={(e: any) => {
                      createUserStore.setFormData(
                        "internship_start",
                        e?.format("YYYY-MM-DD")
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Desc
                    text={"Internship end date"}
                    css={{ pb: "8px", pl: "8px" }}
                  />
                  <DatePickerStyled
                    css={{ width: "170px", mr: "10px" }}
                    name={"internship_end"}
                    defaultValue={null}
                    value={createUserStore.formData.internship_end}
                    onChange={(e: any) => {
                      createUserStore.setFormData(
                        "internship_end",
                        e?.format("YYYY-MM-DD")
                      );
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6}>
                  <Desc text={"Contract start date"} css={{ ...padding }} />
                  <DatePickerStyled
                    css={{ width: "170px", ml: "10px" }}
                    name={"contract_start"}
                    defaultValue={null}
                    value={createUserStore.formData.contract_start}
                    onChange={(e: any) => {
                      createUserStore.setFormData(
                        "contract_start",
                        e?.format("YYYY-MM-DD")
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Desc
                    text={"Contract end date"}
                    css={{ pb: "8px", pt: "16px", pl: "8px" }}
                  />
                  <DatePickerStyled
                    css={{ width: "170px", mr: "10px" }}
                    name={"contract_date_end"}
                    defaultValue={null}
                    value={createUserStore.formData.contract_date_end}
                    onChange={(e: any) => {
                      createUserStore.setFormData(
                        "contract_date_end",
                        e?.format("YYYY-MM-DD")
                      );
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6}>
                  <Desc text={"Anex start date"} css={{ ...padding }} />
                  <DatePickerStyled
                    css={{ width: "170px", ml: "10px", mb: "24px" }}
                    name={"anex_date_start"}
                    defaultValue={null}
                    value={createUserStore.formData.anex_date_start}
                    onChange={(e: any) => {
                      createUserStore.setFormData(
                        "anex_date_start",
                        e?.format("YYYY-MM-DD")
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </BoxStyled>
          </Grid>
        </Grid>
      </Box>
      <Box className="right-box" sx={{ pl: "24px" }}>
        <Grid item container direction="column" xs={12} sm={4} spacing={"24px"}>
          <Grid item>
            <BoxStyled>
              <P text={"Role information"} css={{ ...paddingTitle }} />
              <Desc text={"Supervisor"} css={{ ...paddingTopSide }} />
              <AutoCompleteStyled1
                css={{ width: "356px", pl: "16px" }}
                options={toJS(createUserStore.getSupervisors())}
                name={"supervisor"}
              />
              <Desc text={"Team lead"} css={{ ...padding }} />
              <AutoCompleteStyled1
                css={{ width: "356px", pl: "16px" }}
                options={toJS(createUserStore.getTeamleads())}
                name={"team_lead"}
              />

              <Desc text={"Team"} css={{ ...padding }} />
              <AutoCompleteStyled1
                css={{ width: "356px", pl: "16px" }}
                options={createUserStore.getTeams().map((option: any) => {
                  return option.name;
                })}
                name={"team"}
                onChange={(e: any, value: any) => {
                  createUserStore.setFormData(
                    "team",
                    createUserStore.getTeamsIdByName(value)
                  );
                }}
              // button={
              //   <Button
              //     sx={{ mt: "10px", textTransform: "none" }}
              //     onMouseDown={() => {
              //       setOpenPositionModal(true);
              //     }}
              //   >
              //     + add new position
              //   </Button>
              // }
              />
              <Desc text={"Position"} css={{ ...padding }} />
              <AutoCompleteStyled1
                css={{ width: "356px", pl: "16px" }}
                options={createUserStore.getPositions().map((option: any) => {
                  return option.name;
                })}
                name={"position"}
                onChange={(e: any, value: any) => {
                  createUserStore.setFormData(
                    "position",
                    createUserStore.getPositionsIdByName(value)
                  );
                }}
              // button={
              //   <Button
              //     sx={{ mt: "10px", textTransform: "none" }}
              //     onMouseDown={() => {
              //       setOpenPositionModal(true);
              //     }}
              //   >
              //     + add new position
              //   </Button>
              // }
              />
              <Desc text={"Seniority "} css={{ ...padding }} />
              <AutoCompleteStyled1
                css={{ width: "356px", pl: "16px" }}
                options={createUserStore.getSeniority().map((option: any) => {
                  return option.name;
                })}
                name={"seniority"}
                onChange={(e: any, value: any) => {
                  createUserStore.setFormData(
                    "seniority",
                    createUserStore.getSeniorityIdByName(value)
                  );
                }}
              // button={
              //   <Button
              //     sx={{ mt: "10px", textTransform: "none" }}
              //     onMouseDown={() => {
              //       setOpenPositionModal(true);
              //     }}
              //   >
              //     + add new position
              //   </Button>
              // }
              />
              <Desc text={"Main client"} css={{ ...padding }} />
              <AutoCompleteStyled1
                css={{ width: "356px", pl: "16px" }}
                options={createUserStore.getClients().map((option: any) => {
                  return option.name;
                })}
                name={"client"}
                onChange={(e: any, value: any) => {
                  createUserStore.setFormData(
                    "client",
                    createUserStore.getClientsIdByName(value)
                  );
                }}
              // button={
              //   <Button
              //     sx={{ mt: "10px", textTransform: "none" }}
              //     onMouseDown={() => {
              //       setOpenPositionModal(true);
              //     }}
              //   >
              //     + add new position
              //   </Button>
              // }
              />
              <Desc text={"Other client 1"} css={{ ...padding }} />
              <AutoCompleteStyled1
                css={{ width: "356px", pl: "16px" }}
                options={createUserStore.getClients().map((option: any) => {
                  return option.name;
                })}
                name={"other_clients"}
                onChange={(e: any, value: any) => {
                  createUserStore.setFormData(
                    "other_clients",
                    createUserStore.getClientsIdByName(value)
                  );
                }}
              // button={
              //   <Button
              //     sx={{ mt: "10px", textTransform: "none" }}
              //     onMouseDown={() => {
              //       setOpenPositionModal(true);
              //     }}
              //   >
              //     + add new position
              //   </Button>
              // }
              />

              {val.map((data: any, i) => {
                return (
                  <>
                    <Desc
                      text={`Other client ${oldNumber[i]}`}
                      css={{ ...padding }}
                    />
                    <Box display={"flex"}>
                      <AutoCompleteStyled1
                        css={{ width: "356px", pl: "16px" }}
                        options={createUserStore
                          .getClients()
                          .map((option: any) => {
                            return option.name;
                          })}
                        name={"other_clients"}
                        onChange={(e: any, value: any) => {
                          createUserStore.setFormData(
                            "other_clients",
                            createUserStore.getClientsIdByName(value)
                          );
                        }}
                      // button={
                      //   <Button
                      //     sx={{ mt: "10px", textTransform: "none" }}
                      //     onMouseDown={() => {
                      //       setOpenPositionModal(true);
                      //     }}
                      //   >
                      //     + add new position
                      //   </Button>
                      // }
                      />
                      <InputAdornment position="end">
                        <IconButton
                          sx={{
                            color: styleStore.getDropdownIcons(),
                            mt: "40px",
                            ml: "-4px",
                          }}
                          onClick={() => handleRemove()}
                        >
                          <ClearIcon sx={{ width: "20px" }} />
                        </IconButton>
                      </InputAdornment>
                    </Box>
                  </>
                );
              })}
              <Box
                display={"flex"}
                flexDirection={"row"}
                alignItems={"flex-start"}
              >
                <Button
                  variant="text"
                  sx={{
                    ml: "16px",
                    p: "0px",
                    mt: "8px",
                    textTransform: "lowercase",
                    fontWeight: 400,
                    fontSize: "14px",
                    color: styleStore.getActiveText(),
                  }}
                  onClick={() => handleAdd()}
                >
                  + add client
                </Button>
              </Box>
              <Box display={"flex"}>
                <CheckBox
                  css={{
                    pl: "11px",
                    pb: "21px",
                    pt: "24px",
                    color: styleStore.getFontDescription(),
                  }}
                  label={"Coach"}
                />
              </Box>
            </BoxStyled>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
});

export default UserForm;
