import Button from "@mui/material/Button";
import { useStores } from "../../Helpers/MobX/hooks";
import { observer } from "mobx-react-lite";

const TerciaryButton: React.FC<{
  children: any;
  onClick: any;
  css?: any;
}> = observer(({ children, onClick, css}, params: any) => {
  const { styleStore } = useStores();
  return (
    <Button
      variant="text"
      onClick={onClick}
      sx={{
        width: params.width,
        height: "40px",
        borderRadius: "30px",
        color: styleStore.getNameTitleColor(),
        fontSize: '16px',
        textTransform:'none',
        "&:hover": {
          backgroundColor: "transparent",
          color: styleStore.getNameTitleColor(),
        },
        ...css
      }}
    >
      <>{children}</>
    </Button>
  );
});
export default TerciaryButton;
