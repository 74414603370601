import { Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { styleStore } from '../../Helpers/MobX/StyleStore';

const Link = observer(({ text, css }: any) => {
    return (
      <Typography
        sx={{ ...css, textAlign: "left", ...styleStore.getFontLink() }}
      >
        {text}
      </Typography>
    );
  });

export default Link