import { Box, Grid } from "@mui/material";
import Desc from "../../Assets/Fonts/Desc";
import P from "../../Assets/Fonts/P";
import BoxStyled from "../../Components/BoxStyled";
import InputFieldStyled from "../../Components/Textbox/InputFieldStyled";
import PrimaryButton from "../../Components/Button/PrimaryButton";
import { useState } from "react";
import DatePickerStyled from "../../Components/DatePicker";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";



interface ITravelRequest {
  tripTitle: string;
  employees:string;
  location:string;
  start_date:string,
  end_date:string,
  reason:string;
  additionalInfo:string;
}
const Desktop = observer(() => {

    const padding = {
        pl: "16px",
        pb: "8px",
        pt: "16px",
      };
    const paddingTopSide = {
        pl: "16px",
        pt: "16px",
        pb: "8px",
      };

      const[travelRequestData, setTravelRequestData] = useState<ITravelRequest>(
        {
          tripTitle:'',
          employees:'',
          location:'',
          start_date:'',
          end_date:'',
          reason:'',
          additionalInfo:'',
        }
      )
    return (
        <Box sx={{ display: "flex", pl: "24px", flexDirection:'column' }}>
        <Box className="left-box">
          <Grid item container direction="column" xs={12} sm={4} spacing={"24px"}>
            <Grid item>
              <BoxStyled>
                <Desc text={"Trip Title"} css={{ ...paddingTopSide }} />
                <InputFieldStyled
                  css={{ width: "356px" }}
                  name={"first_name"}
                  value={travelRequestData.tripTitle}
                  onChange={(e: any) => {
                    setTravelRequestData({
                      ...travelRequestData,
                      tripTitle: e.target.value,
                    });
                    
                  }}
                />
                <Desc text={"Employees"} css={{ ...padding }} />
                <InputFieldStyled
                  css={{ width: "356px" }}
                  name={"last_name"}
                  value={travelRequestData.employees}
                  onChange={(e: any) => {
                    setTravelRequestData({
                      ...travelRequestData,
                      employees: e.target.value,
                    });
                  }}
                />     
                <Desc text={"Location"} css={{ ...padding }} />
                <InputFieldStyled
                  css={{ width: "356px" }}
                  name={"last_name"}
                  value={travelRequestData.location}
                  onChange={(e: any) => {
                    setTravelRequestData({
                      ...travelRequestData,
                      location: e.target.value,
                    });
                  }}
                />  
                <Grid container>
                <Grid item xs={6}>
                  <Desc text={"Start Date"} css={{ ...padding }} />
                  <DatePickerStyled
                    css={{ width: "170px" }}
                    name={"date_of_birth"}
                    defaultValue={dayjs(travelRequestData.start_date)}
                    value={dayjs(travelRequestData.start_date)}
                    onChange={(e: any) => {
                      let temp = { ...travelRequestData };
                      temp.start_date = e?.format("YYYY-MM-DD");
                      setTravelRequestData(temp);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Desc
                    text={"End Date"}
                    css={{ ...padding }}
                  />
                  <DatePickerStyled
                    css={{ width: "170px" }}
                    name={"date_of_birth"}
                    defaultValue={dayjs(travelRequestData.end_date)}
                    value={dayjs(travelRequestData.end_date)}
                    onChange={(e: any) => {
                      let temp = { ...travelRequestData };
                      temp.end_date = e?.format("YYYY-MM-DD");
                      setTravelRequestData(temp);
                    }}
                  />
                </Grid>
              </Grid>   
                <Desc text={"Reason"} css={{ ...padding }} />
                <InputFieldStyled
                  css={{ width: "356px" }}
                  name={"last_name"}
                  value={travelRequestData.reason}
                  onChange={(e: any) => {
                    setTravelRequestData({
                      ...travelRequestData,
                      reason: e.target.value,
                    });
                  }}
                />       
                <Desc text={"Aditional info (eg. Hotel preferences)"} css={{ ...padding }} />
                <InputFieldStyled
                  css={{ width: "356px", mb: "24px"}}
                  name={"last_name"}
                  value={travelRequestData.additionalInfo}
                  multiline={true}
                  lines={2}
                  onChange={(e: any) => {
                    setTravelRequestData({
                      ...travelRequestData,
                      additionalInfo: e.target.value,
                    });
                  }}
                />            
              </BoxStyled>
              
            </Grid>
          </Grid>
        </Box>
        <PrimaryButton
                    width={"152px"}
                    sx={{ mt: "24px"}}
                    onClick={() => {
                     
                    }}
                    text={"SEND REQUEST"}
                  />
        </Box>
    )
 })
 
 export default Desktop;