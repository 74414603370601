import PrimaryButton from "../../../Components/Button/PrimaryButton";
import SecondaryButton from "../../../Components/Button/SecondaryButton";
import { styleStore } from "../../../Helpers/MobX/StyleStore";
import TabsStyled from "../../../Components/TabsStyled";
import BoxStyled from "../../../Components/BoxStyled";
import EmployeeForm from "../../../Components/EmployeeForm";
import Link from "../../../Assets/Fonts/Link";
import { observer } from "mobx-react-lite";
import { Box, Tab, Tooltip } from "@mui/material";
import P from "../../../Assets/Fonts/P";
import { useState } from "react";
import { userStore } from "../../../Helpers/MobX/UserStore";
import { Apis } from "../../../Helpers/ApiHelper/ApiFactory";
import { GetToken } from "../../../Helpers/CookieHelper";
import { Circle, NoiseAwareSharp } from "@mui/icons-material";
import AutoCompleteStyled1 from "../../../Components/AutocompleteStyledSecondary";
import Documents from "../../../Components/Documents";
import { StylesProvider } from "@material-ui/core";
import TravelRequestHistory from "../../../Components/TravelRequestHistory";
import PerformanceReviewProfile from "../../../Components/PerformanceReviewProfile";

const Desktop = observer((params: any) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [tempUser, setTempUser] = useState<any>({});
  const [employee, setEmployee] = useState<any>(params?.user);
  const handleTabChange = (e: any, tabIndex: number) => {
    setDisabled(true);
    setCurrentTabIndex(tabIndex);
  };
  let currentUser = userStore.getCurrentUser();
  return (
    <Box
      sx={{
        overflowY: "hidden",
        position: "fixed",
        top: "80px",
        bottom: 0,
        ml: "4px",
      }}
    >
      <Box
        sx={{
          position: "fixed",
          backgroundColor: styleStore.getBackgroundPrimary(),
          zIndex: 3000,
        }}
      >
        <Box display={"flex"} sx={{ mb: "24px" }}>
          <BoxStyled css={{ width: "400px", marginLeft: "24px" }}>
            <Box display={"flex"} flexDirection={"row"} sx={{}}>
              <Box sx={{ padding: "24px" }}>
                <>
                  {employee?.avatar ? (
                    <img
                      src={employee?.avatar}
                      alt="avatar"
                      style={{
                        width: "96px",
                        height: "96px",
                        borderRadius: "64px",
                      }}
                    />
                  ) : (
                    <img src={styleStore.getBlankProfile()} alt="avatar" />
                  )}
                </>
              </Box>
              <Box display={"flex"} flexDirection={"column"}>
                <Box>
                  <Link
                    text={"Name:"}
                    css={{
                      pt: "24px",
                      color: styleStore.getTextColorSecondary(),
                    }}
                  />
                  {`${employee?.user?.first_name} ${employee?.user?.last_name}`
                        .length < 29
                        ?(
                          <P
                            text={
                              `${employee?.user?.first_name} ${employee?.user?.last_name}`
                                .length < 29
                                ? `${employee?.user?.first_name} ${employee?.user?.last_name}`
                                : `${employee?.user?.first_name} ${employee?.user?.last_name}`.substring(
                                    0,
                                    26
                                  ) + "..."
                            }
                            css={{
                              pt: "8px",
                              color: styleStore.getSecondaryButtonTextColor(),
                            }}
                          />
                          ):(
                            <Tooltip title={`${employee?.user?.first_name} ${employee?.user?.last_name}`}
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  bgcolor: styleStore.getInputBackgroundPrimary(),
                                  color: styleStore.getTextColorPrimary()
                                }
                              }
                            }}
                             >
                              <Box>
                              <P
                                text={
                                  `${employee?.user?.first_name} ${employee?.user?.last_name}`
                                    .length < 29
                                    ? `${employee?.user?.first_name} ${employee?.user?.last_name}`
                                    : `${employee?.user?.first_name} ${employee?.user?.last_name}`.substring(
                                        0,
                                        26
                                      ) + "..."
                                }
                                css={{
                                  pt: "8px",
                                  color: styleStore.getSecondaryButtonTextColor(),
                                }}
                            />
                            </Box>
                        </Tooltip>
                          )}
                  
                </Box>
                <Box>
                  <Link
                    text={"Position:"}
                    css={{
                      pt: "16px",
                      color: styleStore.getTextColorSecondary(),
                    }}
                  />
                  <P
                    text={
                      employee?.position?.name.length < 29
                        ? employee?.position?.name
                        : employee?.position?.name.substring(0, 26) + "..."
                    }
                    css={{
                      pt: "8px",
                      pb: "28px",
                      color: styleStore.getSecondaryButtonTextColor(),
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </BoxStyled>

          <BoxStyled css={{ width: "400px", marginLeft: "24px" }}>
            <Link
              text={"Email:"}
              css={{
                pt: "24px",
                pl: "24px",
                color: styleStore.getTextColorSecondary(),
              }}
            />

            <P
              text={
                employee?.user.email.length < 29
                  ? employee?.user.email
                  : employee?.user.email.substring(0, 25) + "..."
              }
              css={{
                pt: "8px",
                pl: "24px",
                color: styleStore.getSecondaryButtonTextColor(),
                width: "250px",
              }}
            />
            <Link
              text={"Phone number:"}
              css={{
                pt: "16px",
                pl: "24px",
                color: styleStore.getTextColorSecondary(),
              }}
            />
            <P
              text={employee?.phone_number}
              css={{
                pt: "8px",
                pl: "24px",
                pb: "28px",
                color: styleStore.getSecondaryButtonTextColor(),
              }}
            />
          </BoxStyled>
          <BoxStyled css={{ width: "375px", marginLeft: "24px" }}>
            <Link
              text={"Client:"}
              css={{
                pt: "24px",
                pl: "24px",
                color: styleStore.getTextColorSecondary(),
              }}
            />
            <P
              text={
                employee?.client?.name
                  ? employee?.client?.name.length < 23
                    ? employee?.client?.name
                    : employee?.client?.name.substring(0, 20) + "..."
                  : "None"
              }
              css={{
                pt: "8px",
                pl: "24px",
                color: styleStore.getSecondaryButtonTextColor(),
              }}
            />
            <Link
              text={"Team:"}
              css={{
                pt: "16px",
                pl: "24px",
                color: styleStore.getTextColorSecondary(),
              }}
            />
            <P
              text={
                employee?.team[0]?.name
                  ? employee?.team[0]?.name.length < 23
                    ? employee?.team[0]?.name
                    : employee?.team[0]?.name.substring(0, 20) + "..."
                  : "None"
              }
              css={{
                pt: "8px",
                pl: "24px",
                pb: "28px",
                color: styleStore.getSecondaryButtonTextColor(),
              }}
            />
          </BoxStyled>
          <BoxStyled css={{ width: "375px", marginLeft: "24px" }}>
            <Link
              text={"Status:"}
              css={{
                pt: "24px",
                pl: "24px",
                color: styleStore.getTextColorSecondary(),
              }}
            />
            {disabled ? (
              <Box
                display={"flex"}
                justifyContent={"flex-start"}
                sx={{ mb: "24px" }}
              >
                <Circle
                  sx={{
                    ml: "24px",
                    mt: "5px",
                    mb: "24px",
                    color: employee.user.is_active ? "#70C773" : "#EB6C78",
                    width: "14px",
                  }}
                />
                <P
                  text={employee.user.is_active ? "Active" : "Inactive"}
                  css={{
                    pt: "8px",
                    pl: "8px",
                    color: styleStore.getSecondaryButtonTextColor(),
                  }}
                />
              </Box>
            ) : (
              <AutoCompleteStyled1
                css={{ width: "160px", ml: "16px" }}
                options={["Active", "Inactive"]}
                name={"active"}
                defaultValue={employee.user.is_active ? "Active" : "Inactive"}
                onChange={(e: any, value: any) => {
                  employee.user.is_active = value === "Active";
                }}
              />
            )}
          </BoxStyled>
        </Box>
        <Box
          display={"flex"}
          sx={{
            width: "100vw",
            pb: "24px",
            pl: "24px",
            backgroundColor: styleStore.getBackgroundPrimary(),
          }}
        >
          <TabsStyled value={currentTabIndex} onChange={handleTabChange}>
            <Tab label="General info" value={0} />
            <Tab label="Documents" value={1} />
            <Tab label="Performance Reviews" value={2} />
            <Tab label="Travel Request History" value={3} />
          </TabsStyled>
        </Box>
      </Box>
      <Box
        sx={{
          mt: "250px",
          height: "calc(100% - 260px)",
          overflowY: "scroll",
        }}
      >
        {currentTabIndex === 0 && (
          <Box>
            <EmployeeForm
              disabled={disabled}
              user={employee}
              setUser={setEmployee}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
              }}
            >
              {currentUser.is_super_hr || currentUser.is_hr ? (
                disabled ? (
                  <PrimaryButton
                    width={"152px"}
                    sx={{ ml: "20px", justifyContent: "center" }}
                    onClick={() => {
                      setTempUser(JSON.parse(JSON.stringify(employee)));
                      setDisabled(false);
                    }}
                    text={"Edit user"}
                  />
                ) : (
                  <Box
                    sx={{ display: "flex", flexDirection: "row", ml: "20px" }}
                  >
                    <Box sx={{ width: "110px", mr: "24px" }}>
                      <SecondaryButton
                        text="Cancel"
                        onClick={() => {
                          setEmployee(JSON.parse(JSON.stringify(tempUser)));
                          setDisabled(true);
                        }}
                      />
                    </Box>
                    <Box>
                      <PrimaryButton
                        width={"88px"}
                        onClick={() => {
                          Apis.updateUser(employee, GetToken()).then(() => {
                            setDisabled(true);
                          });
                        }}
                        text={"Save"}
                      />
                    </Box>
                  </Box>
                )
              ) : (
                ""
              )}
            </Box>
          </Box>
        )}
        {currentTabIndex === 1 && (
          <Box>
            <Documents
            />
          </Box>
        )}
        {currentTabIndex === 2 && (<Box>
        <PerformanceReviewProfile
            />
        </Box>)}
      {currentTabIndex === 3 && (
        <Box>
        <TravelRequestHistory
            />
        </Box>
      )}
      </Box>
      
    </Box>
  );
});

export default Desktop;
