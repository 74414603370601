import AutoCompleteStyledFilters from "../../../Components/AutoComplete/AutoCompleteStyledFilters";
import AutocompleteStyled from "../../../Components/AutoComplete/AutocompleteStyled";
import { Box, Grid, Button } from "@mui/material";
import CardStyled from "../../../Components/CardStyled";
import { observer } from "mobx-react-lite";
import Title from "../../../Components/Title";
import { styleStore } from "../../../Helpers/MobX/StyleStore";
import CheckboxStyled from "../../../Components/CheckboxStyled";
import PaginationStyled from "../../../Components/Pagination/PaginationStyled";
import P from "../../../Assets/Fonts/P";
import { useNavigate } from "react-router-dom";

const Desktop = observer(
  ({
    users,
    pagination,
    Functions,
    urlParams,
    setUrlParams,
    filterValues,
  }: any) => {
    const navigate = useNavigate();
    return (
      <>
        <Box pl={"25px"} sx={{ mt: "70px" }}>
          <Grid sx={{ minWidth: 300 }} container>
            <AutocompleteStyled
              label="Search by name"
              onChange={(event: any, value: any) => {
                setUrlParams({ ...urlParams, page: 1, search: value });
              }}
              options={filterValues?.name_list.map((item: any) => item)}
              onClick={Functions.handleSearchCancel}
            />
            <AutoCompleteStyledFilters
              name={"Client"}
              item={filterValues?.clients}
              setUrlParams={setUrlParams}
              urlParams={urlParams}
              width={125}
            />
            <AutoCompleteStyledFilters
              name={"Team"}
              item={filterValues?.teams}
              setUrlParams={setUrlParams}
              urlParams={urlParams}
              width={124}
            />
            <AutoCompleteStyledFilters
              name={"Position"}
              item={filterValues?.positions}
              setUrlParams={setUrlParams}
              urlParams={urlParams}
              width={143}
            />
            <AutoCompleteStyledFilters
              name={"Seniority"}
              item={filterValues?.seniorities}
              setUrlParams={setUrlParams}
              urlParams={urlParams}
              width={146}
            />
            <AutoCompleteStyledFilters
              name={"Role"}
              item={filterValues?.roles}
              setUrlParams={setUrlParams}
              urlParams={urlParams}
              width={116}
            />
            <CheckboxStyled
              label="Show coach"
              onClick={Functions.handleCoach}
            />
            <Button
              sx={{ color: styleStore.getMainButtonColor(), ml: "315px" }}
              onClick={Functions.handleClearFilters}
            >
              <P text={"CLEAR FILTERS"}></P>
            </Button>
          </Grid>

          <Grid
            container
            spacing={{ xs: "24px" }}
            sx={{ pt: "24px" }}
            columns={6}
          >
            {users?.map((item: any, index: any) => (
              <Grid item key={index}>
                <CardStyled
                  users={item}
                  onClick={() => {
                    navigate(`/employee/${item.id}`);
                  }}
                />
              </Grid>
            ))}
          </Grid>
          <PaginationStyled
            count={Math.ceil(pagination.count / 30)}
            page={pagination.page}
            onChange={Functions.handleChangePage}
          />
        </Box>
      </>
    );
  }
);

export default Desktop;
