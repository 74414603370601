import { Box, Typography, styled } from "@mui/material";
import { useStores } from "../../Helpers/MobX/hooks";
import { observer } from "mobx-react-lite";
import Card from "./components/Card";
import { PieChart } from '@mui/x-charts/PieChart';
import { AxisConfig, BarChart, useDrawingArea } from "@mui/x-charts";
import Brightness1Icon from '@mui/icons-material/Brightness1';
import { styleStore } from "../../Helpers/MobX/StyleStore";
import { ReactComponent as PeopleRedIcon } from '../../Assets/img/Icons/ic_baseline-people-red.svg'
import { ReactComponent as PeopleBlueIcon } from '../../Assets/img/Icons/ic_baseline-people-blue.svg'

const StyledText = styled('text')(({ theme }) => ({
  fill: styleStore.getNameTitleColor(),
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fontSize: 20,
}));

function PieCenterLabel({ children }: { children: React.ReactNode }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={top + height / 2}>
      {children}
    </StyledText>
  );
}

const data1 = [
  { label: 'Group A', value: 400, color: '#F6C61C'},

];

const data2 = [
  { label: 'A1', value: 100, color:'#7DB1FF' },

];

const data3 = [
  { label: 'A1', value: 100, color:'#EB6C78' },

];

const data4 = [
  { label: 'A1', value: 100, color:'#70C773' },

];
const series = [
  {
    innerRadius: 95,
    outerRadius: 100,
    id: 'series-2',
    data: data2,
    startAngle:270,
    endAngle:340
  },
  {
    innerRadius: 80,
    outerRadius: 85,
    id: 'series-1',
    data: data1,
    startAngle:270,
    endAngle:400
  }, 
  {
    innerRadius: 65,
    outerRadius: 70,
    id: 'series-3',
    data: data3,
    startAngle:270,
    endAngle:360
  },
  {
    innerRadius: 50,
    outerRadius: 55,
    id: 'series-4',
    data: data4,
    startAngle:270,
    endAngle:380
  },
];

const Desktop = observer(() => {

      const { styleStore } = useStores();
      
    return (
        <Box sx={{ml:'14px'}}>
          <Box sx={{display:'flex', flexDirection:'row'}}>
          <Card css={{width:'328px !important'}}>
          <Typography id="modal-modal-description" sx={{ color:styleStore.getNameTitleColor(), fontSize:'16px', ml:'-150px', mt:'-10px' }}>
         Active employees
          </Typography>
          <Box sx={{display:'flex',flexDirection:'row', ml:'-50px'}}>
          <PieChart
           width={300}
           height={96}
           sx={{
            ".MuiPieArc-root":{
              strokeWidth:0
            }
          }}
            series={[
              {
                data: [
                   {value: 15, color:'#0044A9'}, {value:85, color:'#EB505B'}
                ],
                innerRadius: 35,
                startAngle: 0,
              },
            ]}
           
          ><PieCenterLabel>85%</PieCenterLabel>
           </PieChart>
           <Box sx={{display:'flex', flexDirection:'column', ml:'-120px', mt:'15px', alignItems:'start'}}>
            <Box sx={{display:'flex', flexDirection:'row'}}>
            <Brightness1Icon sx={{color:'#EB505B', width:'10px',mt:'-2px', mr:'3px'}}/>
           <Typography id="modal-modal-description" sx={{ color:styleStore.getTextColorSecondary(), fontSize:'14px' }}>
         Active employees
          </Typography>
          </Box>
          <Box sx={{display:'flex', flexDirection:'row'}}>
            <Brightness1Icon sx={{color:'#0044A9', width:'10px',mt:'-2px', mr:'3px'}}/>
           <Typography id="modal-modal-description" sx={{ color:styleStore.getTextColorSecondary(), fontSize:'14px' }}>
         Unavalable employees
          </Typography>
          </Box>
           </Box>
           </Box>
          </Card>

          <Card css={{width:'205px !important'}}>
          <Typography id="modal-modal-description" sx={{ color:styleStore.getNameTitleColor(), fontSize:'16px', mt:'-10px', ml:'-30px'}}>
         Employee location
          </Typography>
          <Box sx={{display:'flex',flexDirection:'row', mt:'30px'}}>
          <Box sx={{display:'flex', flexDirection:'column'}}>
            <Box sx={{display:'flex', flexDirection:'row',mr:'30px'}}>
            
              <PeopleRedIcon/>
              <Typography id="modal-modal-description" sx={{ color:'#EB505B', fontSize:'24px', mt:'-5px', ml:'2px', }}>
              98
              </Typography>
            </Box>
            <Typography id="modal-modal-description" sx={{ color:styleStore.getTextColorSecondary(), fontSize:'14px', mt:'15px', ml:'-20px', }}>
              Domestic
              </Typography>
          </Box>
        <Box sx={{  display: 'inline-block',
          width: '1px',
          backgroundColor: styleStore.getTextColorSecondary(),
          margin: '-13px 0px',
          height: '61px',}}/>
          <Box sx={{display:'flex', flexDirection:'column'}}>
            <Box sx={{display:'flex', flexDirection:'row', ml:'30px'}}>
            
              <PeopleBlueIcon/>
              <Typography id="modal-modal-description" sx={{ color:'#0044A9', fontSize:'24px', mt:'-5px', ml:'2px'}}>
              23
              </Typography>
            </Box>
            <Typography id="modal-modal-description" sx={{ color:styleStore.getTextColorSecondary(), fontSize:'14px', mt:'15px', ml:'20px', }}>
              Abroad
              </Typography>
         </Box>
           </Box>
          </Card>
          <Card css={{zIndex:1, width:'343px !important'}}>
          <Typography id="modal-modal-description" sx={{ color:styleStore.getNameTitleColor(), fontSize:'16px', mt:'-10px', ml:'-90px'}}>
          Client workforce distribution
          </Typography>
            <Box sx={{display:'flex', flexDirection:'row'}}>
          <PieChart
          sx={{
            ".MuiPieArc-root":{
              strokeWidth:0
            }
          }}
          series={series}
          width={300}
          height={200}
          slotProps={{
            legend: { hidden: true },
          }}
        />
         <Box sx={{display:'flex', flexDirection:'column', ml:'-90px', mt:'10px'}}>
              <Box sx={{display:'flex', flexDirection:'row', mt:'-6px'}}>
                <Brightness1Icon sx={{color:'#7DB1FF', width:'10px',mt:'-2px', mr:'3px'}}/>
              <Typography id="modal-modal-description" sx={{ color:styleStore.getTextColorSecondary(), fontSize:'14px' }}>
              P44
              </Typography>
              </Box>
              <Box sx={{display:'flex', flexDirection:'row', mt:'-2px'}}>
                <Brightness1Icon sx={{color:'#F6C61C', width:'10px',mt:'-2px', mr:'3px'}}/>
              <Typography id="modal-modal-description" sx={{ color:styleStore.getTextColorSecondary(), fontSize:'14px' }}>
              CDL1000
              </Typography>
              </Box>
              <Box sx={{display:'flex', flexDirection:'row', mt:'-2px'}}>
                <Brightness1Icon sx={{color:'#EB6C78', width:'10px',mt:'-2px', mr:'3px'}}/>
              <Typography id="modal-modal-description" sx={{ color:styleStore.getTextColorSecondary(), fontSize:'14px' }}>
              QCerris
              </Typography>
              </Box>
              <Box sx={{display:'flex', flexDirection:'row', mt:'-2px'}}>
                <Brightness1Icon sx={{color:'#70C773', width:'10px',mt:'-2px', mr:'3px'}}/>
              <Typography id="modal-modal-description" sx={{ color:styleStore.getTextColorSecondary(), fontSize:'14px' }}>
              Other
              </Typography>
              </Box>
            </Box>
        </Box>
          </Card>
          <Card css={{zIndex:1}}>
          <Typography id="modal-modal-description" sx={{ color:styleStore.getNameTitleColor(), fontSize:'16px', mt:'-10px', ml:'-90px'}}>
          Employee roles 
          </Typography>
            <Box sx={{display:'flex', flexDirection:'row'}}>
          <BarChart 
          sx={{mt:'-100px'}}
            width={150}
            height={200}
            leftAxis={null}
            bottomAxis={null}
            margin={{
              left:0
            }}
            xAxis={[
              {
                scaleType: 'band',
                data: ['1'],
                categoryGapRatio: 0.3,
                barGapRatio: 2
              } as AxisConfig<'band'>,
            ]}
            series={[
              {
                color:'#EB505B',
                data: [30],
              },
              {
                color:'#FF8A00',
                data: [50],
              },
              {
                color:'#7DB1FF',
                data: [40],
              },
              {
                color:'#CF59CA',
                data: [10],
              },
              {
                color:'#F6C61C',
                data: [20],
              },
              {
                color:'#70C773',
                data: [15],
              },
            ]}
           
          />
            <Box sx={{display:'flex', flexDirection:'column', ml:'-30px', mt:'10px'}}>
              <Box sx={{display:'flex', flexDirection:'row', mt:'-6px'}}>
                <Brightness1Icon sx={{color:'#EB505B', width:'10px',mt:'-2px', mr:'3px'}}/>
              <Typography id="modal-modal-description" sx={{ color:styleStore.getTextColorSecondary(), fontSize:'14px' }}>
              HR/BizOps
              </Typography>
              </Box>
              <Box sx={{display:'flex', flexDirection:'row', mt:'-6px'}}>
                <Brightness1Icon sx={{color:'#FF8A00', width:'10px',mt:'-2px', mr:'3px'}}/>
              <Typography id="modal-modal-description" sx={{ color:styleStore.getTextColorSecondary(), fontSize:'14px' }}>
              Frontend
              </Typography>
              </Box>
              <Box sx={{display:'flex', flexDirection:'row', mt:'-6px'}}>
                <Brightness1Icon sx={{color:'#7DB1FF', width:'10px',mt:'-2px', mr:'3px'}}/>
              <Typography id="modal-modal-description" sx={{ color:styleStore.getTextColorSecondary(), fontSize:'14px' }}>
              Backend
              </Typography>
              </Box>
              <Box sx={{display:'flex', flexDirection:'row', mt:'-6px'}}>
                <Brightness1Icon sx={{color:'#CF59CA', width:'10px',mt:'-2px', mr:'3px'}}/>
              <Typography id="modal-modal-description" sx={{ color:styleStore.getTextColorSecondary(), fontSize:'14px' }}>
              Design
              </Typography>
              </Box>
              <Box sx={{display:'flex', flexDirection:'row', mt:'-6px'}}>
                <Brightness1Icon sx={{color:'#F6C61C', width:'10px',mt:'-2px', mr:'3px'}}/>
              <Typography id="modal-modal-description" sx={{ color:styleStore.getTextColorSecondary(), fontSize:'14px' }}>
              Project
              </Typography>
              </Box>
              <Box sx={{display:'flex', flexDirection:'row', mt:'-6px'}}>
                <Brightness1Icon sx={{color:'#70C773', width:'10px',mt:'-2px', mr:'3px'}}/>
              <Typography id="modal-modal-description" sx={{ color:styleStore.getTextColorSecondary(), fontSize:'14px' }}>
              DevOps / QA
              </Typography>
              </Box>
            </Box>
          </Box>
          </Card>
          </Box>
        <Box sx={{ ml: "875px", width: "650px", zIndex:-10, mt:'-130px' }}>
        <img
          src={styleStore.getDashboardImg()}
          alt="dash"
          style={{ width: "100%", height: "auto", zIndex:0 }}
        />
      </Box>
      </Box>
    )
 })
 
 export default Desktop;