import * as React from "react";
// import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { styleStore } from "../Helpers/MobX/StyleStore";
import { createUserStore } from "../Helpers/MobX/PageStores.tsx/CreateUserStore";
import { Typography } from "@mui/material";

const CheckBox = ({ css, label, disabled, checked, onChange }: any) => {
  return (
    <FormControlLabel
      sx={{ ...css }}
      control={
        <Checkbox
          disabled={disabled}
          checked={checked}
          onChange={onChange || (() => {
            createUserStore.setFormData(
              "is_coach",
              !createUserStore.getFormData().is_coach
            );
          })}
          sx={{
            bgcolor: styleStore.getCardBackgroundColor(),
            color: styleStore.getCheckboxBorderColor(),
            width: "16px",
            height: "16px",
            marginRight: "8px",
            marginLeft: "9px",
            "&.Mui-checked": {
              color: styleStore.getNameTitleColor(),
            },
            "&.Mui-disabled": {
              bgcolor: styleStore.getCardBackgroundColor(),
              color: styleStore.getCheckboxBorderColor(),
            }
          }}
        />
      }
      label={<Typography sx={{ fontSize: '14px', color: styleStore.getNameTitleColor(), }}>{label}</Typography>}
      labelPlacement="start"

    />
  );
};

export default CheckBox;
