import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { styleStore } from "../../Helpers/MobX/StyleStore";
import ClearIcon from "@mui/icons-material/Clear";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import {
  Autocomplete,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
} from "@mui/material";
import { createUserStore } from "../../Helpers/MobX/PageStores.tsx/CreateUserStore";

const AutoCompleteStyled1 = observer(
  ({ 
    css,
    options,
    name,
    defaultValue,
    placeholder,
  }: any) => {
    const [value, setValue] = useState<string | undefined | null>(null);

    //useEffect(() => {
    //  setValue(defaultValue);
    //}, [defaultValue]);

    useEffect(() => {
      setValue(createUserStore.getFormData()[name] || null);
    }, [name]);

    return (
      <Autocomplete
        //defaultValue={defaultValue}
        value={value}
        onChange={(e: any, newValue: any) => {
          createUserStore.setFormData(name, newValue);
          setValue(newValue);
        }}
        PaperComponent={({ children }) => (
          <Paper
            sx={{
              backgroundColor: styleStore.getInputBackgroundPrimary(),
              border: styleStore.getInputBorder(),
              borderRadius: "15px",
            }}
          >
            {children}
          </Paper>
        )}
        disablePortal
        options={options}
        getOptionLabel={(options: any) => `${options}`}
        sx={{
          ...css,

          "& .MuiAutocomplete-inputRoot": {
            paddingTop: "0px",
          },
          //   '& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected="true"]':
          //     {
          //       backgroundColor: "green",
          //       color: "green ",
          //       "&:hover": {
          //         backgroundColor: "green",
          //       },
          //     },
          "& + .MuiAutocomplete-popper .MuiAutocomplete-option": {
            // color: styleStore.getQcerrisTextInputs(),
            color: "gray",
            "&:hover": {
              //   color: "red",
              //   backgroundColor: "blue",
            },
          },
          svg: { color: styleStore.getDropdownIcons() },
          label: { color: styleStore.getMainButtonColor() },
        }}
        renderInput={(params) => (
          <TextField
            placeholder={placeholder}
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  {value !== null && value !== undefined ? (
                    <InputAdornment position="end">
                      <IconButton
                        sx={{
                          position: "absolute",
                          right: "10px",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "24px",
                          height: "24px",
                        }}
                        onClick={() => {
                          setValue(undefined);
                          delete createUserStore.getFormData()[name]
                          console.log("delete")
                        }}
                      >
                        <ClearIcon sx={{ width: "20px" }} />
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    <InputAdornment position="end">
                      <ArrowDropDownIcon
                        sx={{
                          position: "absolute",
                          right: "10px",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "24px",
                          height: "24px",
                        }}
                      />
                    </InputAdornment>
                  )}
                </InputAdornment>
              ),
            }}
            sx={{
              color: "red",
              input: {
                color: styleStore.getQcerrisTextInputs(),
              },
              "& label.Mui-focused": {
                color: "blue",
              },
              "& .MuiOutlinedInput-root": {
                bgcolor: styleStore.getInputBackgroundPrimary(),
                height: "40px",
                borderRadius: "15px",
                "& fieldset": {
                  border: styleStore.getInputBorder(),
                  borderRadius: "15px",
                },
                "&:hover fieldset": {
                  border: styleStore.getInputBorder(),
                  borderRadius: "15px",
                },
                "&.Mui-focused fieldset": {
                  border: styleStore.getInputBorder(),
                  borderRadius: "15px",
                },
              },
            }}
          />
        )}
      />
    );
  }
);

export default AutoCompleteStyled1;
