import { Typography } from "@mui/material"
import { observer } from "mobx-react-lite";
import { styleStore } from "../../Helpers/MobX/StyleStore";

const Desc = observer(({ text, css }: any) => {
    return (
      <Typography
        sx={{ ...css, textAlign: "left", ...styleStore.getFontDescription() }}
      >
        {text}
      </Typography>
    );
  });

export default Desc