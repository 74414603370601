import { useStores } from "../../Helpers/MobX/hooks";
import { observer } from "mobx-react-lite";


const Mobile = observer(() => {

      const { styleStore } = useStores();
      
    return (
     <></>
    )
 })
 
 export default Mobile;