import { Box } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { styleStore } from "../../Helpers/MobX/StyleStore";
import DocumentImage from "../../Assets/img/doc.png";
import { Button, IconButton, Typography } from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ReactComponent as DownloadDocIcon } from '../../Assets/img/Icons/DownloadDocIcon.svg';
import { ReactComponent as DeleteDocIcon } from '../../Assets/img/Icons/DeleteDocIcon.svg';

const WordFileCard = observer(({ css, imageType, fileName, fileSize, onClick,openDeleteModal }: any) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: styleStore.getCardBackgroundPrimary(),
        borderRadius: "15px",
        width: "210px",
        height: "89px",
        padding: "0px",
        ...css,
      }}
    >
      <Button 
      style={{display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: styleStore.getCardBackgroundPrimary(),
        padding: "0px",}}
        onClick={onClick}
        >
      <Box
        style={{
          width: "44px",
          height: "57px",
          marginRight: "16px",
          marginLeft: "19px",
        }}
      >
        <img src={DocumentImage} alt="doc" />
      </Box>
      <Box
        style={{
          height: "57px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Typography
          sx={{
            color: styleStore.getSecondaryButtonTextColor(),
            textTransform: 'none',
            fontSize: "16px",
            fontWeight: "bold",
            mb: "4px",
            height: "18px",
          }}
        >
          File Name
        </Typography>
        <Typography
          sx={{
            color: styleStore.getLabel(),
            textTransform: 'none',
            fontSize: "14px",
            fontWeight: "normal",
            mb: "4px",
            height: "15px",
          }}
        >
          Word document
        </Typography>
        <Typography
          sx={{
            color: styleStore.getLabel(),
            fontSize: "14px",
            fontWeight: "normal",
            mb: "4px",
            height: "15px",
          }}
        >
          113KB
        </Typography>
      </Box>
      </Button>
      <Box display='flex' flexDirection='row' style={{marginLeft:'auto', marginRight:0}}>
      <IconButton
               
              >
                <DownloadDocIcon />
              </IconButton>
              <br/>
              <br/>
              <IconButton
              onClick={openDeleteModal}
              >
                <DeleteDocIcon />
              </IconButton>
      </Box>
    </Box>
  );
});

export default WordFileCard;

//E0E0E0 pozadina kartice
