import { Box, IconButton, TableCell, TableRow, styled, tableCellClasses } from "@mui/material";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../Helpers/MobX/hooks";
import { createUserStore } from "../../../Helpers/MobX/PageStores.tsx/CreateUserStore";
import Pill from "../../../Components/Pill";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { ReactComponent as FormIcon } from '../../../Assets/img/Icons/FormIcon.svg';
import { ReactComponent as GrayEyeIcon } from '../../../Assets/img/Icons/eyegray.svg';
import { ReactComponent as GrayDownloadIcon } from '../../../Assets/img/Icons/Downloadgray.svg';
import { ReactComponent as BlueEyeIcon } from '../../../Assets/img/Icons/eye.svg';
import { ReactComponent as BlueDownloadIcon } from '../../../Assets/img/Icons/Download.svg';
import { BorderColor, Download } from "@mui/icons-material";


interface IPerformanceReviewHistoryElement {
  name: string;
  requester:string;
  dateOfRequest:string,
color:string
}

const PerformanceReviewEntry = observer(({ name,

    dateOfRequest,
    color
    }:IPerformanceReviewHistoryElement ) => {

    const {styleStore} = useStores();

      const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
          backgroundColor: color? color : styleStore.getCardBackgroundColor(),
          color: styleStore.getTextColorSecondary(),
          fontSize: 14,
        },
        [`&.${tableCellClasses.root}`]: {
          borderColor: styleStore.light_mode? "#E0E0E0": '#7C7C7C',
        },
      }));

    return (
        <TableRow >
            <StyledTableCell > <Box sx={{display:'flex', justifyContent:'start', alignItems:'center'}}>
              {createUserStore.getProfileImgUrl() ? (
                <>
                  <img
                    src={createUserStore.getProfileImgUrl()}
                    style={{
                      width: "32px",
                      height: "32px",
                      borderRadius: "64px",
                    }}
                    alt=""
                  />
                </>
              ) : (
                <>
                  <img src={styleStore.getBlankProfile()} style={{
                      width: "32px",
                      height: "32px",
                      borderRadius: "64px",
                    }} alt="blank" />
                </>
              )}  
              
              <Box sx={{ml:'8px'}}>Anna Malone</Box>
              </Box></StyledTableCell>
              <StyledTableCell >
            </StyledTableCell>
              <StyledTableCell >
            </StyledTableCell>
            <StyledTableCell >
             {name}
            </StyledTableCell>
            <StyledTableCell >
            </StyledTableCell>
            <StyledTableCell >
            </StyledTableCell>
            <StyledTableCell style={{paddingLeft:'40px'}}>{dateOfRequest}</StyledTableCell>
            <StyledTableCell >
            </StyledTableCell>
            <StyledTableCell >
            </StyledTableCell>
           <StyledTableCell ><IconButton
               sx={{color:styleStore.getQcerrisTextInputs(), marginLeft:'4px'}}
               >
                <GrayEyeIcon/>
               </IconButton></StyledTableCell>
               <StyledTableCell >
            </StyledTableCell>
            <StyledTableCell >
            </StyledTableCell>
               <StyledTableCell ><IconButton
               sx={{ marginLeft:'16px'}}
               >
                 <GrayDownloadIcon/>
               </IconButton></StyledTableCell>
          </TableRow>
    )
 })
 
 export default PerformanceReviewEntry;