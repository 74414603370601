import { Pagination } from "@mui/material";
import React from "react";
import { styleStore } from "../../Helpers/MobX/StyleStore";
import { observer } from "mobx-react-lite";

type PaginationProps = {
  count: number;
  page: number;
  onChange: any;
};
const Desktop = observer(({ count, page, onChange }: PaginationProps) => {
  return (
    <Pagination
      count={count}
      page={page}
      onChange={onChange}
      sx={{
        height: "30px",
        color: styleStore.getCardBackgroundColor(),
        display: "flex",
        justifyContent: "flex-end",
        mt: "24px",
        mr: "24px",
        "& .MuiPaginationItem-root": {
          backgroundColor: styleStore.getCardBackgroundColor(),
          color: styleStore.getNameTitleColor(),
          "&.Mui-selected": {
            fontWeight: "bold",
          },
        },
      }}
    />
  );
});

export default Desktop;
