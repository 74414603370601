import { makeAutoObservable, action } from "mobx";
import { makePersistable, hydrateStore } from "mobx-persist-store";

export class CreateUserStore {
  newUser: any;
  positions: any;
  seniority: any;
  teams: any;
  clients: any;
  supervisors: any;
  teamleads: any;
  imageUrl: any;
  formData: any;
  constructor() {
    this.newUser = {};
    this.positions = {};
    this.teams = {};
    this.clients = {};
    this.formData = {
      role: "Regular user",
      other_clients: [],
      is_coach: false,
    };
    makeAutoObservable(this);
  }
  @action addToUser(key: any, value: any) {
    this.newUser[key] = value;
    console.log(this.newUser[key]);
  }
  @action setPositions(positions: any) {
    this.positions = positions;
  }
  @action getPositions() {
    return this.positions;
  }
  @action getPositionsIdByName(name: string) {
    let id: number = 0;
    this.positions.forEach((element: any) => {
      if (element.name === name) {
        id = element.id;
        return;
      }
    });
    return id;
  }
  @action getPositionsNameById(id: number) {
    let name: string = "None";
    this.positions.forEach((element: any) => {
      if (element.id === id) {
        name = element.name;
        return;
      }
    });
    return name;
  }
  @action setSeniority(seniority: any) {
    this.seniority = seniority;
  }
  @action getSeniority() {
    return this.seniority;
  }
  @action getSeniorityIdByName(name: string) {
    let id: number = 0;
    this.seniority.forEach((element: any) => {
      if (element.name === name) {
        id = element.id;
        return;
      }
    });
    return id;
  }
  @action getSeniorityNameById(id: number) {
    let name: string = "None";
    this.seniority.forEach((element: any) => {
      if (element.id === id) {
        name = element.name;
        return;
      }
    });
    return name;
  }
  @action setClients(clients: any) {
    this.clients = clients;
  }
  @action getClients() {
    return this.clients;
  }
  @action getClientsIdByName(name: string) {
    let id: number = 0;
    this.clients.forEach((element: any) => {
      if (element.name === name) {
        id = element.id;
        return;
      }
    });
    return id;
  }
  @action getClientsNameById(id: number) {
    let name: string = "None";
    this.clients.forEach((element: any) => {
      if (element.id === id) {
        name = element.name;
        return;
      }
    });
    return name;
  }
  @action setTeams(teams: any) {
    this.teams = teams;
  }
  @action getTeams() {
    return this.teams;
  }
  @action getTeamsIdByName(name: string) {
    let id: number = 0;
    this.teams.forEach((element: any) => {
      if (element.name === name) {
        id = element.id;
        return;
      }
    });
    return id;
  }
  @action getTeamsNameById(id: number) {
    let name: string = "None";
    this.teams.forEach((element: any) => {
      if (element.id === id) {
        name = element.name;
        return;
      }
    });
    return name;
  }
  @action setSupervisors(supervisors: any) {
    this.supervisors = supervisors;
  }
  @action getSupervisors() {
    return this.supervisors;
  }
  @action setTeamleads(teamleads: any) {
    this.teamleads = teamleads;
  }
  @action getTeamleads() {
    return this.teamleads;
  }
  @action getDateOfBirth() {
    console.log(this.newUser["date_of_birth"]);
    return this.newUser["date_of_birth"];
  }
  @action setUser(user: any) {
    this.newUser = user;
  }
  @action getUser() {
    return this.newUser;
  }
  @action setProfileImgUrl(imageUrl: any) {
    this.imageUrl = imageUrl;
  }
  @action getProfileImgUrl() {
    return this.imageUrl;
  }
  @action reset() {
    this.newUser = {};
  }
  @action setFormData(name: any, value: any) {
    if (name === "other_clients") {
      this.formData.other_clients.push(value);
    } else {
      Object.assign(this.formData, { [name]: value });
    }
  }
  @action getFormData() {
    return this.formData;
  }

  rehydrate() {
    hydrateStore(this);
  }
}

export const createUserStore = new CreateUserStore();
