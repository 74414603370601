import { Tabs } from "@mui/material";
import { styleStore } from "../Helpers/MobX/StyleStore";

const TabsStyled = ({ children, value, onChange }: any) => {
  return (
    <Tabs
      value={value}
      onChange={onChange}
      TabIndicatorProps={{
        sx: { display: "none" },
      }}
      sx={{
        "& button": {
          borderRadius: "15px",
          color: styleStore.getSecondaryButtonTextColor(),
          ...styleStore.getFontH3(),
          textTransform: "capitalize",
          // width: "160px",
          height: "40px",
        },
        "& button.Mui-selected": {
          bgcolor: styleStore.getTabColor(),
          color: styleStore.getSecondaryButtonTextColor(),
          ...styleStore.getFontH2(),
        },
      }}
    >
      {children}
    </Tabs>
  );
};

export default TabsStyled;
