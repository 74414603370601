import { Box, IconButton, TableCell, TableRow, styled, tableCellClasses } from "@mui/material";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../Helpers/MobX/hooks";
import { createUserStore } from "../../../Helpers/MobX/PageStores.tsx/CreateUserStore";
import Pill from "../../../Components/Pill";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { ReactComponent as FormIcon } from '../../../Assets/img/Icons/FormIcon.svg';
import { ReactComponent as EyeIcon } from '../../../Assets/img/Icons/eye.svg';
import { ReactComponent as DownloadIcon } from '../../../Assets/img/Icons/Download.svg';
import AutoCompleteStyled1 from "../../../Components/AutocompleteStyledSecondary";
import AutocompleteStyled from "../../../Components/AutoComplete/AutocompleteStyled";


interface ITravelRequestElement {
  name: string;
  requesting:string;
  departing:string;
  dateOfRequest:string,
  status:string,
}

const PerformanceReviewEntryControls = observer(({ name,
    requesting,
    departing,
    dateOfRequest,
    status}:ITravelRequestElement ) => {

    const {styleStore} = useStores();

      const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
          backgroundColor: styleStore.getCardBackgroundColor(),
          color: styleStore.getTextColorSecondary(),
          fontSize: 14,
        },
      }));

      const StyledTableCellPrimary = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
          backgroundColor: styleStore.getCardBackgroundColor(),
          color: styleStore.getTextColorPrimary(),
          fontSize: 14,
          width:'232px'
        },
      }));

    return (
        <TableRow>
            <StyledTableCellPrimary >
              <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', width:'232px'}}>
              <AutoCompleteStyled1
                  css={{ width: "232px", pl: "16px" }}
                  options={[]}
                  name={"role"}
                  onChange={(e: any, value: any) => {
                    
                  }}
                  endAdorment={<Box
                    pr="5px"
                    component="img"
                    alt="Qcerris Logo"
                    sx={{
                      width: "16px",
                      position: "absolute",
                      right: "10px",
                    }}
                    src={styleStore.getSearch()}
                    onClick={() => {
                      
                    }}
                  />}
                />
              </Box>
            </StyledTableCellPrimary>
            <StyledTableCellPrimary>
                <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', ml:'10px'}}>
              <AutoCompleteStyled1
                  css={{ width: "232px"}}
                  options={[]}
                  name={"role"}
                  onChange={(e: any, value: any) => {
                    
                  }}
                />
              </Box></StyledTableCellPrimary>
            <StyledTableCell sx={{ml:'-50px'}}></StyledTableCell>
            <StyledTableCell ><Box sx={{fontSize:'16px', color:'#7DB1FF'}} onClick={() => {}} style={{cursor: 'pointer'}}>CLOSE FORM</Box></StyledTableCell>
            <StyledTableCell ><Box sx={{fontSize:'16px', color:'#7DB1FF'}} onClick={() => {}} style={{cursor: 'pointer'}}>ADD FORM</Box></StyledTableCell>
            <StyledTableCell ><Box sx={{fontSize:'16px', color:'#7DB1FF'}} onClick={() => {}} style={{cursor: 'pointer'}}>NEW FORM</Box></StyledTableCell>
            <StyledTableCell >
            </StyledTableCell>
          </TableRow>
    )
 })
 
 export default PerformanceReviewEntryControls;